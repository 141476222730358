import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

  constructor(private http: HttpClient, private authService: AuthenticationService) { }

  success: boolean;
  message: string;

  async create(comment: string, assignmentId: number) {
      
    await this.authService.isExpired();

    var model = {
      Text: comment,
      TaskId: assignmentId
    };

    return this.http.post<any>(environment.baseUrl +"/Task/CreateTaskNote", model)
    .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        return data;
    }));
  }
}
