import { Component, OnInit } from '@angular/core';
import { TasktypeService } from "../../_services/tasktype.service";
import { AlertService } from 'src/app/_services';
import { NotificationService } from "../../_services/notification.service";
import { MatDialogRef } from "@angular/material";
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-tasktype',
  templateUrl: './tasktype.component.html',
  styleUrls: ['./tasktype.component.css']
})
export class TasktypeComponent implements OnInit {

  constructor(
    public tasktypeService: TasktypeService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private uiService: UiService,
    public dialogRef: MatDialogRef<TasktypeComponent>) { }

  ngOnInit() {
    this.tasktypeService.getAll();
  }

  onClear() {
    this.tasktypeService.form.reset();
    this.tasktypeService.initializeFormGroup();
  }

  onClose(){
    this.tasktypeService.form.reset();
    this.tasktypeService.initializeFormGroup();
    this.dialogRef.close();
  }

  async onSubmit() {
    if(this.tasktypeService.form.valid) {
      
      var model = {
        Type: this.tasktypeService.form.value.taskType
      };

      (await this.tasktypeService.create(model)).subscribe(
        data => {
          if(this.tasktypeService.success === false){
            this.alertService.error(this.tasktypeService.message);
          } else{

            this.tasktypeService.form.reset();
            this.tasktypeService.initializeFormGroup();
            this.notificationService.success(':: Created successfully');
            this.onClose();
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
      });
    }
  }
}
