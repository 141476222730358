import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './_modules/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule, DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';

import { TaskListComponent } from './pages/task-list/task-list.component';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { TaskComponent } from './pages/task/task.component';
import { DataComponent } from './data/data.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { HomeComponent } from './pages/home/home.component'
import { AlertComponent } from "./_components";
import { CreatetaskComponent } from './pages/createtask/createtask.component';
import { TasktypeService } from './_services/tasktype.service';
import { TasktypeComponent } from './pages/tasktype/tasktype.component';
import { MatConfirmDialogComponent } from './_components/mat-confirm-dialog/mat-confirm-dialog.component';
import { CustomDateAdapter } from './_adapters/custom-date-adapter';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { ToastrModule } from "ngx-toastr";
import { AgmCoreModule } from '@agm/core';
import { PlacePredictionService, FileService, AuthenticationService } from './_services';
import { TaskeditComponent } from './pages/taskedit/taskedit.component';
import { TimeslotListComponent } from './pages/timeslot-list/timeslot-list.component';
import { TimeslotService } from './_services/timeslot.service';
import { GanttComponent } from './pages/gantt/gantt.component';

import { GoogleChartsModule } from 'angular-google-charts';
import { TestComponent } from './pages/test/test.component';
import { CommentCreateComponent } from './pages/comment-create/comment-create.component';
import { AttachmentCreateComponent } from './pages/file-create/attachment-create.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { TaskstateConfigurationComponent } from './pages/taskstate-configuration/taskstate-configuration.component';
import { TaskstateListComponent } from './pages/taskstate-list/taskstate-list.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { TaskstateCreateComponent } from './pages/taskstate-create/taskstate-create.component';
import { TaskstateUpdateComponent } from './pages/taskstate-update/taskstate-update.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TaskstatebuttonCreateComponent } from './pages/taskstatebutton-create/taskstatebutton-create.component';
import { TaskstatebuttonUpdateComponent } from './pages/taskstatebutton-update/taskstatebutton-update.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { AuthInteceptor } from './_helpers/auth.interceptor';
import { UserCreateComponent } from './pages/user-create/user-create.component';
import { UserComponent } from './pages/user/user.component';
import { TasktypeListComponent } from './pages/tasktype-list/tasktype-list.component';
import { TasktypeCreateComponent } from './pages/tasktype-create/tasktype-create.component';
import { TasktypeEditComponent } from './pages/tasktype-edit/tasktype-edit.component';
import { CustomerCreateComponent } from './pages/customer-create/customer-create.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { CustomerEditComponent } from './pages/customer-edit/customer-edit.component';
import { TableOverviewExampleComponent } from './pages/table-overview-example/table-overview-example.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TimeregistrationCreateComponent } from './pages/timeregistration-create/timeregistration-create.component';
import { TimeregistrationUpdateComponent } from './pages/timeregistration-update/timeregistration-update.component';
import { TimeregistrationListComponent } from './pages/timeregistration-list/timeregistration-list.component';
import { MoreSpaceComponent } from './pages/more-space/more-space.component';
import { PlanUpdateComponent } from './pages/plan-update/plan-update.component';
import { SupportticketCreateComponent } from './pages/supportticket-create/supportticket-create.component';
import { SupportticketListComponent } from './pages/supportticket-list/supportticket-list.component';
import { SupportticketComponent } from './pages/supportticket/supportticket.component';
import { MasterDashboardComponent } from './pages/master-dashboard/master-dashboard.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationStore } from './_helpers/applicationStore';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { TitleCaseDirective } from './directives/title-case.directive';
import { FooterComponent } from './pages/footer/footer.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { PrivacyPolicyMarketingComponent } from './pages/privacy-policy-marketing/privacy-policy-marketing.component';
import { PrivacyPolicyCustomersComponent } from './pages/privacy-policy-customers/privacy-policy-customers.component';
import { PrivacyPolicyThirdPartiesComponent } from './pages/privacy-policy-third-parties/privacy-policy-third-parties.component';
import { PrivacyPolicyProductsComponent } from './pages/privacy-policy-products/privacy-policy-products.component';
import { PrivacyPolicyEmployeesComponent } from './pages/privacy-policy-employees/privacy-policy-employees.component';
import { Platform } from '@angular/cdk/platform';
import { ConditionsComponent } from './pages/conditions/conditions.component';
import { UserCreateRequestComponent } from './pages/user-create-request/user-create-request.component';
import { UserInviteComponent } from './pages/user-invite/user-invite.component';
import { MainComponent } from './pages/main/main.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MainNavComponent,
    TaskListComponent,
    CustomerListComponent,
    UserListComponent,
    TaskComponent,
    DataComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    AlertComponent,
    CreatetaskComponent,
    TasktypeComponent,
    TimeslotListComponent,
    MatConfirmDialogComponent,
    TaskeditComponent,
    TimeslotListComponent,
    GanttComponent,
    TestComponent,
    CommentCreateComponent,
    AttachmentCreateComponent,
    TaskstateConfigurationComponent,
    TaskstateCreateComponent,
    TaskstateListComponent,
    TaskstateUpdateComponent,
    SettingsComponent,
    TaskstatebuttonCreateComponent,
    TaskstatebuttonUpdateComponent,
    ForbiddenComponent,
    UserCreateComponent,
    UserComponent,
    TasktypeListComponent,
    TasktypeCreateComponent,
    TasktypeEditComponent,
    CustomerCreateComponent,
    CustomerComponent,
    CustomerEditComponent,
    TableOverviewExampleComponent,
    ProfileComponent,
    TimeregistrationCreateComponent,
    TimeregistrationUpdateComponent,
    TimeregistrationListComponent,
    MoreSpaceComponent,
    PlanUpdateComponent,
    SupportticketCreateComponent,
    SupportticketListComponent,
    SupportticketComponent,
    MasterDashboardComponent,
    ForgotComponent,
    ResetPasswordComponent,
    TitleCaseDirective,
    FooterComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    PrivacyPolicyMarketingComponent,
    PrivacyPolicyCustomersComponent,
    PrivacyPolicyThirdPartiesComponent,
    PrivacyPolicyProductsComponent,
    PrivacyPolicyEmployeesComponent,
    ConditionsComponent,
    UserCreateRequestComponent,
    UserInviteComponent,
    MainComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    LayoutModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    FontAwesomeModule,
    NgbModule,
    MaterialFileInputModule,
    DragDropModule,
    GoogleChartsModule.forRoot(),
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD9ARPoaWP8AwEJpK-LTZhgKpsc6M9q-X8',
      libraries: ['places']
    })
  ],
  exports: [],
  providers:
    [
      ApplicationStore,
      TasktypeService,
      TimeslotService,
      CommentCreateComponent,
      AttachmentCreateComponent,
      {
        provide: DateAdapter, useClass: CustomDateAdapter
      },
      PlacePredictionService,
      FileService,
      AuthenticationService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInteceptor,
        multi: true
      },
      {
        provide : LocationStrategy, 
        useClass: HashLocationStrategy}
    ],
  bootstrap: [AppComponent],
  entryComponents: [TasktypeComponent, TimeslotListComponent, CommentCreateComponent, AttachmentCreateComponent, MatConfirmDialogComponent]
})
export class AppModule {

}
