import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskService, AlertService } from 'src/app/_services';
import { ToastrService } from 'ngx-toastr';
import { Task } from 'src/app/models/task.model';
import { faClock, faCalendar, faHashtag, faIndustry, faCircle, faGlobeAmericas, faUser } from "@fortawesome/free-solid-svg-icons";
import { MapsAPILoader } from '@agm/core';
import { AttachmentService } from 'src/app/_services/attachment.service';
import { Attachment } from 'src/app/models/attachment.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FileService } from 'src/app/_services/file.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { CommentCreateComponent } from '../comment-create/comment-create.component';
import { AttachmentCreateComponent } from '../file-create/attachment-create.component';
import { TaskStateButton } from 'src/app/models/taskStateButton.model';
import { UserRole } from 'src/app/enums/userRole.enum';
import { DialogService } from 'src/app/_services/dialog.service';
import { first } from 'rxjs/operators';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {
  public role: UserRole;
  id: number;
  task: Task;
  tempTask: Task;
  faClock = faClock;
  faCalendar = faCalendar;
  faHashtag = faHashtag;
  faIndustry = faIndustry;
  faCircle = faCircle;
  faGlobeAmericas = faGlobeAmericas;
  faUser = faUser;
  sameaddress: boolean;
  hasLocation: boolean;

  srcData : SafeResourceUrl[] = new Array();
  latitude: number;
  longitude: number;
  zoom:number;
  private geoCoder;
  attachments: Attachment[];
  tempAttachments: Attachment[];

  taskStateButtons: TaskStateButton[] = [];
  lastTaskStateButton: TaskStateButton;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private activatedRouter: ActivatedRoute,
    private taskService: TaskService,
    private attachmentService: AttachmentService,
    private fileService: FileService,
    private alertService: AlertService,
    private toastr: ToastrService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private mapsAPILoader: MapsAPILoader,
    private sanitizer: DomSanitizer,
    private uiService: UiService
    ){}

  ngOnInit() {
    this.id = +this.activatedRouter.snapshot.paramMap.get('id');
    this.role = this.authenticationService.getRole();
    
    /*
    this.taskService.get(this.id).subscribe(
      data => {
        if(this.taskService.success === false){
          this.toastr.error(this.taskService.message, "Task error");
        } else{
          this.task = data;
          this.latitude = +this.task.location.latitude;
          this.longitude = +this.task.location.longitude;
          this.zoom = 12;

          if(this.task.sameStartAndEndDay == true){
            this.sameaddress = true;
          } else{
            this.sameaddress = false;
          }

          this.mapsAPILoader.load().then(() => {
            this.geoCoder = new google.maps.Geocoder;
          });
          this.loadFiles();
        }
      },
      error => {
        this.toastr.error('Could not fetch \'customers\' due to network issues. Please check if your are connected to the internet.', "Task error");
    });
    */

   this.initTask();
   //this.getStateButtons();
  }

  private async initTask(){

    (await this.taskService.initTask(this.id)).subscribe(
      data => {
        if(this.taskService.success === false){
          this.toastr.error(this.taskService.message, "Task error");
        } 
        else{
            // Init task
            this.task = data.task;

            if(this.task.location != null){
              this.hasLocation = true;
              
              this.latitude = +this.task.location.latitude;
              this.longitude = +this.task.location.longitude;
              this.zoom = 12;

              this.mapsAPILoader.load().then(() => {
                this.geoCoder = new google.maps.Geocoder;
              });

            } else{
              this.hasLocation = false;
            }

            if(this.task.sameStartAndEndDay == true){
              this.sameaddress = true;
            } else{
              this.sameaddress = false;
            }
            
            // Init State buttons
            this.taskStateButtons = data.stateButtons;
            this.lastTaskStateButton = data.lastTaskStateButton;
            if(data.lastTaskStateButton != null)
              this.task.stateTypeId = data.lastTaskStateButton.statetypeId;
              
            // Init files
            this.tempAttachments = data.attachments;
            this.srcData = new Array();
            for (var i = 0, len = this.tempAttachments.length; i < len; i++) {
              this.srcData.push(this.sanitizer.bypassSecurityTrustResourceUrl("data:image/jpg;base64, " + this.tempAttachments[i].base64));
            }
            
            this.attachments = this.tempAttachments;
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
      });
  }

  /*
  private getStateButtons(){
    this.taskService.getStateButtonsForTask(this.id).subscribe(
      data => {
        if(this.taskService.success === false){
          this.toastr.error(this.taskService.message, "Task error");
        } else{
          this.taskStateButtons = data.taskStateButtons;
          this.lastTaskStateButton = data.lastTaskStateButton;
          this.task.stateTypeId = data.lastTaskStateButton.statetypeId;
        }
      },
      error => {
        this.toastr.error('Could not fetch \'customers\' due to network issues. Please check if your are connected to the internet.', "Task error");
    });
  }
  */

  async taskStateButtonPressed(taskStateButtonId: number){
    
    (await this.taskService.setStateButtonForTask(taskStateButtonId, this.id)).subscribe(
      data => {
        if(this.taskService.success === false){
          this.toastr.error(this.taskService.message, "Task Button error");
        } else{
          this.taskStateButtons = data.taskStateButtons;
          this.lastTaskStateButton = data.lastTaskStateButton;
          this.task.stateTypeId = data.lastTaskStateButton.statetypeId;
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

  private async reloadNotes(){
    (await this.taskService.get(this.id)).subscribe(
      data => {
        if(this.taskService.success === false){
          this.toastr.error(this.taskService.message, "Task error");
        } else{
          this.tempTask = data;
          this.task.notes = this.tempTask.notes;
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

  private async loadFiles() {
    (await this.attachmentService.getAll(this.id)).subscribe(
      data => {
        if(this.attachmentService.success === false){
          this.alertService.error(this.attachmentService.message);
        } else{
          //this.attachments = data;
          this.tempAttachments = data;
          this.srcData = new Array();
          for (var i = 0, len = this.tempAttachments.length; i < len; i++) {
            this.srcData.push(this.sanitizer.bypassSecurityTrustResourceUrl("data:image/jpg;base64, " + this.tempAttachments[i].base64));
          }

          this.attachments = this.tempAttachments;
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

  onShow($event, row: string, filename: string){
    $event.preventDefault();
    // alert("row: " + row);
    // alert("filename: " + filename);

    this.fileService.downloadFile(row, filename);
  }

  onEdit(){
    this.router.navigate(['/taskedit/', this.id]); 
  }

  onDelete() {
    this.dialogService.openConfirmDialog('Are you sure to delete this task?')
      .afterClosed().subscribe(async res => {
        if (res) {
          (await this.taskService.delete(this.id)).pipe(first()).subscribe(
            data => {
              if(data.success === false){
                this.toastr.error(data.message, "");
              } else{
                this.router.navigate(['/tasklist']);
                this.toastr.success(data.message, "");
              }
            },
            error => {
              this.uiService.loadingCompleteWithError();
          });
        }
      });
  }

  onAddComment($event){
    $event.preventDefault();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = { taskId: this.id };
    this.dialog.open(CommentCreateComponent, dialogConfig).afterClosed().subscribe(result => {
      this.reloadNotes();
    });
  }

  onAddFile($event){
    $event.preventDefault();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = { taskId: this.id };
    this.dialog.open(AttachmentCreateComponent, dialogConfig).afterClosed().subscribe(result => {
      this.loadFiles();
    });
  }
}
