import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ITask } from '../interfaces/task.interface';
import { Task } from '../models/task.model';
import { AssignmentFilter } from '../enums/assignmentfilter.enum';
import { TaskState } from '../models/taskState.model';
import { Tasktype } from '../models/tasktype.model';
import { TaskStateButton } from '../models/taskStateButton.model';
import { AuthenticationService } from './authentication.service';
import { AuthenticationStateQuery } from '../state/authentication/authentication.query';

@Injectable({
  providedIn: 'root'
})
export class TaskStateService {
  
  private headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

  constructor(
    private http: HttpClient, 
    private authService: AuthenticationService,
    private authQuery: AuthenticationStateQuery
    ) { }

  success: boolean;
  message: string;

  get(taskStateId: number) {

    var parameters: string = "token="+this.authQuery.getJwtToken() + "&" + "taskStateId=" + taskStateId;
    return this.http.get<any>(environment.baseUrl +"/ServiceTasks/GetTaskState?" + parameters)
    .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        return data.taskstate;
    }));
  }

  async initTaskState(taskStateId: number){

    await this.authService.isExpired();

    var model = {
      TaskStateId: taskStateId
    };

    return this.http.post<any>(environment.worktasksUrl +"/WTTasks/InitTaskState", model)
    .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;

        // Init Get Task Type
        var taskState = data.taskstate;

        // Init Avaliable Task Types
        var avaliableTaskTypes: Tasktype[] = [];
        data.avaliableTaskTypes.forEach(tasktype => {
            var tt = new Tasktype();
            tt.id = tasktype.assignmentTypeId;
            tt.type = tasktype.type;
            avaliableTaskTypes.push(tt);
        });

        // Init Selected Task Types
        var selectedTaskTypes: Tasktype[] = [];
        data.selectedTaskTypes.forEach(tasktype => {
            var tt = new Tasktype();
            tt.id = tasktype.assignmentTypeId;
            tt.type = tasktype.type;
            selectedTaskTypes.push(tt);
        });

        var modifiedData = {
          TaskState: taskState,
          AvaliableTaskTypes: avaliableTaskTypes,
          SelectedTaskTypes: selectedTaskTypes
        };

        return modifiedData;
    }));
  }

  async getAll() {

    await this.authService.isExpired();

    return this.http.get<any>(environment.baseUrl +"/TaskState/GetTaskStatesByCompany")
    .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        
        var listOfTaskstates: TaskState[] = [];
        data.taskstates.forEach(taskState => {
          var ts = new TaskState();
          ts.id = taskState.id;
          ts.name = taskState.name;
          
          ts.tasktypes = [];
          taskState.assignmentTypes.forEach(taskType => {
              var tt = new Tasktype();
              tt.id = taskType.assignmentTypeId;
              tt.type = taskType.type;
              ts.tasktypes.push(tt);
          });

          listOfTaskstates.push(ts);
      });

        return listOfTaskstates;
    }));

  }

  async create(taskState: TaskState) {

    await this.authService.isExpired();

    var model = {
      TaskStateName: taskState.name,
      TaskTypes: Array<number>()
    };

    taskState.tasktypes.forEach(tasktype => model.TaskTypes.push(tasktype.id));

    return this.http.post<any>(environment.baseUrl +"/TaskState/CreateTaskState", model)
    .pipe(map(data => {
        return data;
    }));
  }

  async update(taskState: TaskState) {

    await this.authService.isExpired();

    var model = {
      Id: taskState.id,
      Name: taskState.name,
      TaskTypes: Array<number>()
    };

    taskState.tasktypes.forEach(tasktype => model.TaskTypes.push(tasktype.id));
    
    return this.http.post<any>(environment.baseUrl +"/TaskState/UpdateTaskState", model)
    .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        return data;
    }));
  }

  async updateTaskStateButton(taskStateButton: TaskStateButton) {
    
    await this.authService.isExpired();

    var model = {
      Id: taskStateButton.id,
      TaskStateId: taskStateButton.id,
      Name: taskStateButton.name,
      StateAfterwards: taskStateButton.stateAfterwards,
      StatetypeId: taskStateButton.statetypeId,
      FinishTask: taskStateButton.finishTask,
      ShownAtStart: taskStateButton.shownAtStart,
      Timetracking: taskStateButton.timetracking,
      Priority: taskStateButton.priority,
      ListenForButtonEvents: Array<number>()
    };

    taskStateButton.shownAfterPressedBtns.forEach(ts => {
      model.ListenForButtonEvents.push(ts.id);
    });
    
    return this.http.post<any>(environment.baseUrl +"/TaskState/UpdateTaskStateButton", model)
    .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        return data;
    }));
  }

  async deleteTaskState(taskStateId: number) {
    
    await this.authService.isExpired();

    var model = {
      TaskStateId: taskStateId,
    };

    return this.http.post<any>(environment.baseUrl +"/TaskState/DeleteTaskState", model)
    .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        return data;
    }));
  }

  async getAllTaskStatesButtons(taskStateId: number){

    await this.authService.isExpired();

    var model = {
      TaskStateId: taskStateId
    };

    return this.http.post<any>(environment.baseUrl +"/TaskState/GetTaskStatesButtonsByCompany", model)
    .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        return data.taskStateButtons;
    }));
  }

  async getTaskStateButton(taskStateButtonId: number, taskStateId: number) {

    await this.authService.isExpired();

    var model = {
      TaskStateButtonId: taskStateButtonId,
      TaskStateId: taskStateId
    };

    return this.http.post<any>(environment.worktasksUrl +"/WTTasks/InitTaskStateButton", model)
    .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        return data;
    }));
  }

  async createTaskStateButton(taskStateButton: TaskStateButton){

    await this.authService.isExpired();

    var model = {
      TaskStateId: taskStateButton.taskStateId,
      Name: taskStateButton.name,
      StateAfterwards: taskStateButton.stateAfterwards,
      StatetypeId: taskStateButton.statetypeId,
      FinishTask: taskStateButton.finishTask,
      ShownAtStart: taskStateButton.shownAtStart,
      Timetracking: taskStateButton.timetracking,
      Priority: taskStateButton.priority,
      ReferencedTaskStateButtonIds: Array<number>()
    };

    taskStateButton.shownAfterPressedBtns.forEach(ts => model.ReferencedTaskStateButtonIds.push(ts.id));

    return this.http.post<any>(environment.baseUrl +"/TaskState/CreateTaskStateButton", model)
    .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        return data;
    }));
  }

  async deleteButton(taskStateButtonId: number) {
    
    await this.authService.isExpired();

    var model = {
      TaskStateButtonId: taskStateButtonId
    };

    return this.http.post<any>(environment.baseUrl +"/TaskState/DeleteTaskStateButton", model)
    .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        return data;
    }));
  }

  getSelectedTaskStatesButtons(taskStateId: number, taskStateButtonId: number) {
    var parameters: string = "token="+this.authQuery.getJwtToken() + "&" + "taskStateId=" + taskStateId + "&" + "taskStateButtonId=" + taskStateButtonId;
    return this.http.get<any>(environment.baseUrl +"/ServiceTasks/GetAvailableTaskStateButtons?" + parameters)
    .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        return data.taskStateButtons;
    }));
  }

}
