import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TaskService, UserService, AuthenticationService } from 'src/app/_services';
import { ToastrService } from 'ngx-toastr';
import { Task } from 'src/app/models/task.model';
import { User } from 'src/app/models/user.model';
import { GoogleTimeLineData } from 'src/app/models/googletimelinedata.model';
import { DateAdapter } from '@angular/material';
import * as moment from 'moment';
import { Router } from '@angular/router';
//import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import { CompanySettingsGantt } from 'src/app/models/companySettingsGantt.model';
import { UiService } from 'src/app/_services/ui.service';
import { LanguageStateQuery } from 'src/app/state/language/language.query';
import { AuthenticationStateQuery } from 'src/app/state/authentication/authentication.query';
import { GanttStateService } from 'src/app/state/gantt/gantt.service';
import { GanttStateQuery } from 'src/app/state/gantt/gantt.query';

@Component({
  selector: 'app-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.css']
})
export class GanttComponent implements OnInit {

  tasks: Task[] = [];
  users: User[] = [];
  companySettingsGantt: CompanySettingsGantt;

  currentDate: Date;
  chosenDate: Date;

  dateFormatted: string;

  type: string = 'Timeline';

  myOptions = {
    hAxis: {
      slantedText: true,
      slantedTextAngle: 45,
      format: 'HH:mm',
      minValue: new Date(),
      maxValue: new Date()
    },
    timeline: {
      singleColor: '#4285F4',
      //colors: ['#cbb69d', '#603913', '#c69c6e'],
      groupByRowLabel: true,
      tooltipDateFormat: 'HH:mm',
      rowLabelStyle: {
        fontName: 'sans-serif',
        fontSize: 11
      },
      barLabelStyle: {
        fontName: 'sans-serif',
        fontSize: 11
      }
    },
    avoidOverlappingGridLines: true
  };

  myData: any;

  myDataDetails: number[] = [];

  constructor(
    private router: Router,
    public taskService: TaskService,
    private userService: UserService,
    private authService: AuthenticationService,
    private dateAdapter: DateAdapter<Date>,
    private toastr: ToastrService,
    private uiService: UiService,
    private languageStateQuery: LanguageStateQuery,
    private ganttStateService: GanttStateService,
    private ganttQuery: GanttStateQuery
  ) {
    this.dateAdapter.setLocale(this.languageStateQuery.getLocaleIdentifier());
  }

  ngOnInit() {
    this.setFilters();
  }

  async setFilters() {
    // var gantt_chosenDate: string = localStorage.getItem("gantt_chosenDate");
    // if (gantt_chosenDate == undefined || gantt_chosenDate == null){
    //   var today = moment().toDate();
    //   this.currentDate = today;
    // }
    // else{
    //   this.currentDate = new Date(gantt_chosenDate);
    // }
    this.currentDate = this.ganttQuery.getSelectedDate();
    this.initData();
  }

  private setCurrentDate(date: Date) {
    this.currentDate = date;

    this.myOptions.hAxis.minValue.setFullYear(this.currentDate.getFullYear());
    this.myOptions.hAxis.maxValue.setFullYear(this.currentDate.getFullYear());
    this.myOptions.hAxis.minValue.setMonth(this.currentDate.getMonth() + 1);
    this.myOptions.hAxis.maxValue.setMonth(this.currentDate.getMonth() + 1);
    this.myOptions.hAxis.minValue.setDate(this.currentDate.getDate());
    this.myOptions.hAxis.maxValue.setDate(this.currentDate.getDate());
    this.myOptions.hAxis.minValue.setHours(this.companySettingsGantt.minGanttTimeStartHour);
    this.myOptions.hAxis.maxValue.setHours(this.companySettingsGantt.minGanttTimeEndHour);
    this.myOptions.hAxis.minValue.setMinutes(this.companySettingsGantt.minGanttTimeStartMinute);
    this.myOptions.hAxis.maxValue.setMinutes(this.companySettingsGantt.minGanttTimeEndMinute);
  }

  async onDateChanged() {
    this.saveCurrentDate();
    this.getGantt(this.currentDate);
  }

  saveCurrentDate(){
    this.ganttStateService.updateSelectedDate(this.currentDate);
    //localStorage.setItem("gantt_chosenDate", this.currentDate.toString());
  }

  /*
  private async getLocaleDate(){
    (await this.taskService.getInitCurrentLocaleDate()).subscribe(
      async data => {
        var jsDate = moment(this.taskService.date).toDate();
        await this.setCurrentDate(jsDate);
        this.loadAllUsers();
      },
      error => {
        this.toastr.error(this.taskService.message, "");
      }
    );
  }
  */

  private async getGantt(date: Date) {
    (await this.taskService.getGantt(date)).subscribe(
      async data => {
        var date: any = data.date;
        this.dateFormatted = data.dateFormatted;
        var jsDate = moment(date).toDate();
        this.setCurrentDate(jsDate);

        this.users = data.users;
        this.tasks = data.assignments;

        this.myDataDetails = [];

        this.loadChart();
      },
      error => {
        this.uiService.loadingCompleteWithError();
      }
    );

  }

  private async refreshGanttPreviousDate() {

    this.myData = null;
    this.currentDate.setDate(this.currentDate.getDate() - 1);

    (await this.taskService.getLocaleDate(this.currentDate)).subscribe(
      data => {
        if (this.taskService.success === false) {
          this.toastr.error(this.taskService.message, "");
        } else {
          var jsDate = moment(this.taskService.date).toDate();
          this.setCurrentDate(jsDate);
          this.loadAllUsers();
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }

  private async refreshGanttNextDate() {
    this.myData = null;
    this.currentDate.setDate(this.currentDate.getDate() + 1);

    (await this.taskService.getLocaleDate(this.currentDate)).subscribe(
      data => {
        if (this.taskService.success === false) {
          this.toastr.error(this.taskService.message, "");
        } else {
          var jsDate = moment(this.taskService.date).toDate();
          this.setCurrentDate(jsDate);
          this.loadAllUsers();
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }

  private async loadAllUsers() {
    (await this.userService.getAll()).subscribe(
      data => {
        if (this.userService.success === false) {
          this.toastr.error(this.userService.message, "");
        } else {
          this.users = data;
          this.loadAllTasks();
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }

  private async loadAllTasks() {
    (await this.taskService.getAssignmentsByDay(this.currentDate)).subscribe(
      data => {
        if (this.taskService.success === false) {
          this.toastr.error(this.taskService.message, "");
        } else {
          this.myDataDetails = [];

          this.tasks = data;
          this.loadChart();
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      }
    );
  }

  private async initData() {
    //await this.getLocaleDate();
    (await this.taskService.initGantt(this.currentDate)).subscribe(
      async data => {
        this.dateFormatted = data.dateFormatted;
        this.users = data.users;
        this.tasks = data.assignments;
        this.companySettingsGantt = data.companySettingsGantt;
        var jsDate = moment(data.date).toDate();
        this.setCurrentDate(jsDate);

        this.myDataDetails = [];

        this.loadChart();
      },
      error => {
        this.uiService.loadingCompleteWithError();
      }
    );

  }

  private loadChart() {
    var tempMyData = [];

    var day = this.currentDate.getDay();
    // Performancewise its okay because its only for one day.
    this.users.forEach((user: User, index, arr) => {
      var enabled: boolean;
      var startTimeHour: number;
      var startTimeMinute: number;
      var endTimeHour: number;
      var endTimeMinute: number;

      switch (day) {
        case 0: {
          // Sunday
          enabled = user.sundayEnabled;
          startTimeHour = user.startTimeHourSunday;
          startTimeMinute = user.startTimeMinuteSunday;
          endTimeHour = user.endTimeHourSunday;
          endTimeMinute = user.endTimeMinuteSunday;
          break;
        }
        case 1: {
          // Monday
          enabled = user.mondayEnabled;
          startTimeHour = user.startTimeHourMonday;
          startTimeMinute = user.startTimeMinuteMonday;
          endTimeHour = user.endTimeHourMonday;
          endTimeMinute = user.endTimeMinuteMonday;
          break;
        }
        case 2: {
          // Tuesday
          enabled = user.tuesdayEnabled;
          startTimeHour = user.startTimeHourTuesday;
          startTimeMinute = user.startTimeMinuteTuesday;
          endTimeHour = user.endTimeHourTuesday;
          endTimeMinute = user.endTimeMinuteTuesday;
          break;
        }
        case 3: {
          // Wednesday
          enabled = user.wednesdayEnabled;
          startTimeHour = user.startTimeHourWednesday;
          startTimeMinute = user.startTimeMinuteWednesday;
          endTimeHour = user.endTimeHourWednesday;
          endTimeMinute = user.endTimeMinuteWednesday;
          break;
        }
        case 4: {
          // Thursday
          enabled = user.thursdayEnabled;
          startTimeHour = user.startTimeHourThursday;
          startTimeMinute = user.startTimeMinuteThursday;
          endTimeHour = user.endTimeHourThursday;
          endTimeMinute = user.endTimeMinuteThursday;
          break;
        }
        case 5: {
          // Friday
          enabled = user.fridayEnabled;
          startTimeHour = user.startTimeHourFriday;
          startTimeMinute = user.startTimeMinuteFriday;
          endTimeHour = user.endTimeHourFriday;
          endTimeMinute = user.endTimeMinuteFriday;
          break;
        }
        case 6: {
          // Saturday
          enabled = user.saturdayEnabled;
          startTimeHour = user.startTimeHourSaturday;
          startTimeMinute = user.startTimeMinuteSaturday;
          endTimeHour = user.endTimeHourSaturday;
          endTimeMinute = user.endTimeMinuteSaturday;
          break;
        }
        default: {
          //statements; 
          break;
        }
      }

      if (enabled === true) {
        var start = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, this.currentDate.getDate(), startTimeHour, startTimeMinute, 0);
        tempMyData.push([user.fullname, "Start", start, start]);
        this.myDataDetails.push(0);
        var end = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, this.currentDate.getDate(), endTimeHour, endTimeMinute, 0);
        tempMyData.push([user.fullname, "End", end, end]);
        this.myDataDetails.push(0);
      }

      this.tasks.forEach((task: Task, index, arr) => {
        var startDate = moment(task.startAt).toDate();
        startDate.setMonth(startDate.getMonth() + 1);
        var endDate = moment(task.completeAt).toDate();
        endDate.setMonth(endDate.getMonth() + 1);
        
        if (task.assignedUsers.some(e => e.id == user.id)) {
          // user is assigned to this assignment
          tempMyData.push([user.fullname, task.typeName, startDate, endDate]);
          this.myDataDetails.push(task.id);
        }
      });

    });
    this.myData = tempMyData;
  }

  onSelect($event) {
    var taskId: number = this.myDataDetails[$event[0].row];
    if (taskId === 0) {
      return;
    }

    this.router.navigate(['/task/', taskId]);
  }

  onPrevious() {
    this.myData = null;
    this.currentDate.setDate(this.currentDate.getDate() - 1);
    this.getGantt(this.currentDate);
    this.saveCurrentDate();
    //this.refreshGanttPreviousDate();
  }

  onNext() {
    this.myData = null;
    this.currentDate.setDate(this.currentDate.getDate() + 1);
    this.getGantt(this.currentDate);
    this.saveCurrentDate();
    //this.refreshGanttNextDate();
  }

}
