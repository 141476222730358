import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { TaskListState } from './task-list.model';
import * as moment from 'moment';

export interface ITaskListState extends TaskListState{}

export function createInitialState(): ITaskListState {
  return {
    isAwaitingChecked: true,
    isOngoingChecked: false,
    isCompletedChecked: false,
    selectedTaskGroup: 'all',
    startDate: moment().toDate(),
    endDate: moment().add(1, 'months').toDate()
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'taskListState', resettable: true })
export class TaskListStateStore extends Store<ITaskListState> {
  constructor() {
    super(createInitialState());
  }
}