import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ITasktype } from '../interfaces/tasktype.interface';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Tasktype } from '../models/tasktype.model';
import { AuthenticationService } from './authentication.service';
import { AuthenticationStateQuery } from '../state/authentication/authentication.query';

@Injectable({
    providedIn: 'root'
})
export class TasktypeService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    constructor(
        private http: HttpClient, 
        private authService: AuthenticationService,
        private authQuery: AuthenticationStateQuery
        ) { }

    success: boolean;
    message: string;

    form: FormGroup = new FormGroup({
        $key: new FormControl(null),
        taskType: new FormControl('', Validators.required)
    });

    initializeFormGroup() {
        this.form.setValue({
            $key: null,
            taskType: ''
        });
    }

    async getAll() {
        
        await this.authService.isExpired();

        return this.http.get<any>(environment.baseUrl +"/TaskType/GetTaskTypes")
        .pipe(map(tasktypesModel => {
            this.success = tasktypesModel.success;
            this.message = tasktypesModel.message;

            var listOfTasktypes: any[] = [];
            
            tasktypesModel.assignmentTypes.forEach(tasktype => {
                var tt = {
                    assignmentTypeId: tasktype.assignmentTypeId,
                    type: tasktype.type
                };

                listOfTasktypes.push(tt);
            });

            return listOfTasktypes;
        }));
    }

    async getAllTaskstateAvailableTasktypes(){

        await this.authService.isExpired();

        return this.http.get<any>(environment.baseUrl +"/TaskState/GetAllTaskstateAvailableTasktypes")
        .pipe(map(tasktypesModel => {
            this.success = tasktypesModel.success;
            this.message = tasktypesModel.message;

            var listOfTasktypes: Tasktype[] = [];

            tasktypesModel.assignmentTypes.forEach(tasktype => {
                var tt = new Tasktype();
                tt.id = tasktype.assignmentTypeId;
                tt.type = tasktype.type;
                listOfTasktypes.push(tt);
            });
            return listOfTasktypes;
        }));
    }

    getTasktypesByTaskstate(taskStateId: number){
        var parameters: string = "token="+this.authQuery.getJwtToken() + "&taskStateId="+taskStateId;

        return this.http.get<any>(environment.baseUrl +"/ServiceTasks/GetTasktypesByTaskstate?" + parameters)
        .pipe(map(tasktypesModel => {
            this.success = tasktypesModel.success;
            this.message = tasktypesModel.message;

            var listOfTasktypes: Tasktype[] = [];
            tasktypesModel.assignmentTypes.forEach(tasktype => {
                var tt = new Tasktype();
                tt.id = tasktype.assignmentTypeId;
                tt.type = tasktype.type;
                listOfTasktypes.push(tt);
            });

            return listOfTasktypes;
        }));
    }

    async get(id: number) {

        await this.authService.isExpired();

        var model = {
            TaskTypeId: id
        };
        
        return this.http.post<any>(environment.baseUrl +"/TaskType/GetTaskType", model)
        .pipe(map(tasktypeModel => {
            return tasktypeModel;
        }));
    }

    async create(model: any) {
        
        await this.authService.isExpired();
        
        return this.http.post<any>(environment.baseUrl +"/TaskType/CreateTaskType", model)
        .pipe(map(model => {
            return model;
        }));
    }

    async edit(taskTypeId: number, taskTypeName: string) {
      
        await this.authService.isExpired();

        var model = {
            TaskTypeId: taskTypeId,
            TaskTypeName: taskTypeName
        };

        return this.http.post<any>(environment.baseUrl +"/TaskType/EditTaskType", model)
        .pipe(map(editTasktypeUserModel => {
            return editTasktypeUserModel;
        }));
    }

    async delete(id: number) {
      
        await this.authService.isExpired();

        var body = {
            TaskTypeId: id
        };
  
        return this.http.post<any>(environment.baseUrl +"/TaskType/DeleteTaskType", body)
        .pipe(map(data => {
            return data;
        }));
    }

    allTaskstateAvailableTasktypes(){
        var parameters: string = "token="+this.authQuery.getJwtToken();
        let test = environment.baseUrl +"/ServiceTasks/GetAssignmentTypes?" + parameters;

        return this.http.get<any>(environment.baseUrl +"/ServiceTasks/GetAssignmentTypes?" + parameters)
        .pipe(map(tasktypesModel => {
            this.success = tasktypesModel.success;
            this.message = tasktypesModel.message;
            return tasktypesModel.assignmentTypes;
        }));
    }
}