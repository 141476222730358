import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { faSignOutAlt, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from './_services';
import { first, take } from 'rxjs/operators';
import { UiService } from './_services/ui.service';
import { Subscription } from 'rxjs';
import { CookieConsentService } from './_services/cookie-consent.service';
import { IpaddressService } from './_services/ipaddress.service';
import { CookieConsentEventService } from './_services/cookie-consent-event.service';
import { CookieConsentStateService } from './state/cookie-consent/cookie-consent.service';
import { CookieConsentStateQuery } from './state/cookie-consent/cookie-consent.query';
import { AuthenticationStateQuery } from './state/authentication/authentication.query';


@Component({ selector: 'app-root', templateUrl: 'app.component.html', styleUrls: ['app.component.css'] })
export class AppComponent implements OnInit, OnDestroy {
    private popupOpenSubscription: Subscription;
    private popupCloseSubscription: Subscription;
    private initializeSubscription: Subscription;
    private statusChangeSubscription: Subscription;
    private revokeChoiceSubscription: Subscription;
    private noCookieLawSubscription: Subscription;
    public subRouterEvent$: Subscription;
    private roles$: Subscription;

    roles: String[];
    role: String;
    isAdmin: boolean;

    showDetails: boolean = false;
    functionalCookieConcent: boolean = false;
    statisticalCookieConcent: boolean = false;
    marketingCookieConcent: boolean = false;
    moreInfoNecessary: boolean = false;
    moreInfoFunctional: boolean = false;
    moreInfoStatistical: boolean = false;
    moreInfoMarketing: boolean = false;

    faSignOutAlt = faSignOutAlt;
    faSignInAlt = faSignInAlt;

    constructor(
        private router: Router,
        private uiService: UiService,
        private authenticationService: AuthenticationService,
        private cookieService: CookieConsentService,
        private cookieEventService: CookieConsentEventService,
        private cookieConsentStateService: CookieConsentStateService,
        private cookieConsentStateQuery: CookieConsentStateQuery,
        public authQuery: AuthenticationStateQuery
    ) 
    {
        //this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        //this.authenticationService.roles.subscribe(x => this.roles = x);
        //this.authenticationService.roles.subscribe(x => this.role = x[0]);

        this.roles$ = this.authQuery.usersRoles$.subscribe(roles => { this.roles = roles; });
    }

    ngOnInit(): void {
        this.subRouterEvent$ = this.router.events.subscribe((event: any) => {
            //if (event instanceof NavigationStart && !localStorage.getItem("accepted-cookies")) {
            if (event instanceof NavigationStart && !this.cookieConsentStateQuery.isCookieConsentAccepted()) {
                switch (event.url) {
                    case '/privacy-policy':
                    case '/privacy-policy-marketing':
                    case '/privacy-policy-customers':
                    case '/privacy-policy-employees':
                    case '/privacy-policy-products':
                    case '/privacy-policy-third-parties':
                    case '/cookie-policy':
                    case '/conditions':
                        ($("#staticBackdrop") as any).modal('hide');
                        break;
                    default:
                        ($("#staticBackdrop") as any).modal('show');
                        break;
                }
            }
        });
    }

    async logout() {

        this.authenticationService.logout()
            .pipe(first())
            .subscribe(
                loginModel => {
                    this.router.navigate(['/main']);
                },
                error => {
                    this.uiService.loadingComplete();
                    this.router.navigate(['/main']);
                });
    }

    onSupport() {
        if(this.authQuery.isLoggedIn()){
            this.router.navigate(['/supportticket-create']);
        } else {
            window.location.href = "https://employeetasks.mabytes.com/#contact";
        }
    }

    onShowDetails() {
        this.showDetails = true
    }

    onHideDetails() {
        this.showDetails = false;
        this.moreInfoNecessary = false;
        this.moreInfoFunctional = false;
        this.moreInfoStatistical = false;
        this.moreInfoMarketing = false;
    }

    onChangeFunctionalCookieConcent(checked: boolean) {
        this.functionalCookieConcent = checked;
    }

    onChangeStatisticalCookieConcent(checked: boolean) {
        this.statisticalCookieConcent = checked;
    }

    onChangeMarketingCookieConcent(checked: boolean) {
        this.marketingCookieConcent = checked;
    }

    onMoreInfoNecessary() {
        this.moreInfoNecessary = !this.moreInfoNecessary;
    }

    onMoreInfoFunctional() {
        this.moreInfoFunctional = !this.moreInfoFunctional;
    }

    onMoreInfoStatistical() {
        this.moreInfoStatistical = !this.moreInfoStatistical;
    }

    onMoreInfoMarketing() {
        this.moreInfoMarketing = !this.moreInfoMarketing;
    }

    onRegretNotAcceptingFunctional() {
        ($("#staticBackdrop") as any).modal('show');
    }

    onContinueNotAcceptingFunctional() {
        this.createCookieConsent(this.functionalCookieConcent, this.statisticalCookieConcent, this.marketingCookieConcent);
    }

    onBrand(){
        if(this.authQuery.isLoggedIn()){
            this.router.navigate(['/']);
        } else{
            this.router.navigate(['/main']);
        }
    }

    async onAllowChosenCookieCategories() {
        if(this.functionalCookieConcent != true){
            ($("#staticBackdrop") as any).modal('hide');
            ($("#confirmNonFunctionalCookies") as any).modal('show');
        } else {
            ($("#staticBackdrop") as any).modal('hide');
            this.createCookieConsent(this.functionalCookieConcent, this.statisticalCookieConcent, this.marketingCookieConcent);
        }
    }

    onAllowAllCookieCategories() {
        this.createCookieConsent(true, true, true);
    }

    getCurrentYear(): string {
        var currentYear: string = new Date().getFullYear().toString();
        return currentYear;
    }

    private async createCookieConsent(functionalAllowed: boolean, statisticalAllowed: boolean, marketingAllowed: boolean) {

        let request = {
            FunctionalAllowed: functionalAllowed,
            StatisticalAllowed: statisticalAllowed,
            MarketingAllowed: marketingAllowed
        };

        (await (this.cookieService.sendConsent(request))).pipe(take(1)).subscribe(
            data => { 
                if(data.success) {
                    this.cookieConsentStateService.updateCookieConsent(
                        true, functionalAllowed, statisticalAllowed, marketingAllowed,  data.externalKey);
                    //localStorage.setItem("accepted-cookies", data.externalKey);
                    this.cookieEventService.emitCookieConsent();
                }
            },
            error => { }
        );
    }

    ngOnDestroy(): void {
        this.popupOpenSubscription.unsubscribe();
        this.popupCloseSubscription.unsubscribe();
        this.initializeSubscription.unsubscribe();
        this.statusChangeSubscription.unsubscribe();
        this.revokeChoiceSubscription.unsubscribe();
        this.noCookieLawSubscription.unsubscribe();
        this.subRouterEvent$.unsubscribe();
        this.roles$.unsubscribe();
    }
}