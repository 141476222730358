import { IRegisterCompany } from '../interfaces/registerCompany.interface';

export class RegisterCompany implements IRegisterCompany{
    defaultToken: string = "";
    countryId: string = "";
    companyName: string = "";
    admin: boolean = false;
    email: string = "";
    password: string = "";
    firstName: string = "";
    middleNames: string = "";
    lastName: string = "";
    timeZone: string = "";
    databaseExternalKey: string = "0";
    productId: number = 1;
    country: string = "";
    url: string = "";
    phoneNumber: string = "";
    companyEmail: string = "";
    cityName: string = "";
    streetName: string = "";
    streetNumber: string = "";
    zipCode: string = "";
    latitude: number = 0;
    longitude: number = 0;
}