import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styles: []
})
export class ForgotComponent implements OnInit {
  forgotForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private uiService: UiService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.forgotForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotForm.invalid) {
      return;
    }

    this.uiService.loadingStart();
    this.loading = true;
    this.authenticationService.forgotPassword(this.f.email.value)
      .pipe(first())
      .subscribe(
        data => {
          this.uiService.loadingComplete();
          if (data.success === false) {
            this.toastr.error(data.message, "");
          }
          else {
            this.toastr.success(data.message, "");
            this.router.navigate(['/login']);
          }
          this.loading = false;
        },
        error => {
          this.uiService.loadingCompleteWithError();
          this.loading = false;
        });
  }
}
