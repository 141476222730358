import { ITaskStateButton } from '../interfaces/taskStatebutton.interface';

export class TaskStateButton implements ITaskStateButton{
    id: number = 0;    
    name: string = "";
    stateAfterwards: string = "";
    statetypeId: number = 0;
    finishTask: boolean = false;
    shownAtStart: boolean = false;
    shownAfterPressedBtns: ITaskStateButton[] = [];
    timetracking: boolean = false;
    priority: number = 1;
    taskStateId: number = 0;
}