import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AuthenticationState } from './authentication.model';
import { IAuthenticationState, AuthenticationStateStore } from './authentication.store';

export interface AuthenticationStateState extends AuthenticationState {} 

@Injectable({ providedIn: 'root' })
export class AuthenticationStateQuery extends Query<IAuthenticationState> {
  
  usersRoles$ = this.select(state => this.getRoles(state));
  usersRole$ = this.select(state => this.getRole(state));

  constructor(protected store: AuthenticationStateStore) {
    super(store);
  }

  private getRoles(state: IAuthenticationState): string[] {
    return (
      state.roles
    );
  }

  private getRole(state: IAuthenticationState): string {
    return (
      state.role
    );
  }

  isLoggedIn(): boolean {
    return this.store.getValue().isLoggedIn;
  }

  getRefreshToken(): string {
    return this.store.getValue().refreshToken;
  }

  getJwtToken(): string {
    return this.store.getValue().jwtToken;
  }

  getUsersRoles(): string[] {
    return this.store.getValue().roles;
  }

  getUsersRole(): string {
    return this.store.getValue().role;
  }

  // getJwtTokenExpiry(): string {
  //   return this.store.getValue().jwtTokenExpiry;
  // }

  isSessionExpired(): boolean {
    
    var jwtTokenExpiry = this.store.getValue().jwtTokenExpiry;
    if (jwtTokenExpiry != null && !moment.utc().add(1, 'minutes').isSameOrAfter(moment(jwtTokenExpiry))) {
        return true;
    }
    return false;
  }
}
