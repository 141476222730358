import { Component, OnInit, ViewChild, ElementRef, NgZone, TemplateRef } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, DateAdapter } from "@angular/material";
import { TasktypeService } from 'src/app/_services/tasktype.service';
import { AlertService, UserService, TaskService } from 'src/app/_services';
import { CustomerService } from 'src/app/_services/customer.service';
import { Location } from 'src/app/models/location.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Customer } from 'src/app/models/customer.model';
import { UiService } from 'src/app/_services/ui.service';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { HereService } from 'src/app/_services/here.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customer-create',
  templateUrl: './customer-create.component.html',
  styleUrls: ['./customer-create.component.css']
})
export class CustomerCreateComponent implements OnInit {
  @ViewChild('addressNotFoundModal', { static: false }) addressNotFoundModalContent: TemplateRef<any>;
  @ViewChild('confirmAddressModal', { static: false }) confirmAddressModalContent: TemplateRef<any>;
  @ViewChild('multipleAddressesFoundModal', { static: false }) multipleAddressesFoundModalContent: TemplateRef<any>;

  public loading: Boolean = false;
  submitted = false;
  createCustomerForm: FormGroup;
  countries = [];
  defaultCountry: string;
  selectedCountry: string;
  location: Location = new Location();
  faPlusCircle = faPlusCircle;

  latitude: number;
  longitude: number;
  zoom: number;

  public address: string;

  foundAddresses: any[];
  foundAddress: string;

  selectedAddress: any;

  addressSelectedFromList = false;

  customer: Customer = new Customer();

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private tasktypeService: TasktypeService,
    private alertService: AlertService,
    private customerService: CustomerService,
    private userService: UserService,
    private taskService: TaskService,
    private dateAdapter: DateAdapter<Date>,
    private mapsAPILoader: MapsAPILoader,
    private router: Router,
    private uiService: UiService,
    private toastr: ToastrService,
    private here: HereService,
    private modalService: NgbModal,
    private ngZone: NgZone) {
  }

  ngOnInit() {
    this.createCustomerForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      email: [''],
      phonenumber: [''],
      url: [''],
      address: ['']
    });
  }

  get f() { return this.createCustomerForm.controls; }

  async onSubmit() {
    this.submitted = true;
    if (this.createCustomerForm.valid) {

      this.customer.name = this.createCustomerForm.value.name;
      this.customer.description = this.createCustomerForm.value.description;
      this.customer.email = this.createCustomerForm.value.email;
      this.customer.phoneNumber = this.createCustomerForm.value.phonenumber;
      this.customer.url = this.createCustomerForm.value.url;
      this.customer.location = this.location;

      this.loading = true;
      if (this.createCustomerForm.value.address !== '') {
        // Address has a value

        const items: any = await this.here.getCustomerAddress(this.createCustomerForm.value.address);

        if (items.length === 0)
          this.openAddressNotFoundModal();
        else if (items.length === 1)
          this.openConfirmAddressModal(items[0]);
        else
          this.openMultipleAddressesFoundModal(items);

        //Location loc = await this.here.convertAddress(customer.location);
      } else {
        this.Save();
      }
    }
  }

  openAddressNotFoundModal() {
    this.modalService.open(this.addressNotFoundModalContent).result.then((result) => { }, (reason) => {
      this.loading = false;
     });
  }

  openConfirmAddressModal(item: any) {
    this.selectedAddress = item;
    this.foundAddress = item.address.label;
    this.modalService.open(this.confirmAddressModalContent).result.then((result) => {
      this.setSelectedAddress();
    }, (reason) => { 
      this.loading = false;
    });
  }

  openMultipleAddressesFoundModal(items: any[]) {

    this.foundAddresses = [];
    items.forEach((item) => {
      this.foundAddresses.push(item);
      //map.addObject(new H.map.Marker(item.position));
    });

    this.modalService.open(this.multipleAddressesFoundModalContent).result.then((result) => {
      this.setSelectedAddress();
      this.addressSelectedFromList = false;
    }, (reason) => {
      this.addressSelectedFromList = false;
      this.loading = false;
     });
  }

  saveSeletedAddress(listIndex: number) {
    this.selectedAddress = this.foundAddresses[listIndex];
    this.addressSelectedFromList = true;
  }

  private setSelectedAddress() {

    this.customer.location.fullAddress = this.selectedAddress.address.label;
    this.customer.location.cityName = this.selectedAddress.address.city;
    this.customer.location.country = this.selectedAddress.address.countryName;
    this.customer.location.countryId = this.selectedAddress.address.countryCode;
    this.customer.location.latitude = this.selectedAddress.position.lat;
    this.customer.location.longitude = this.selectedAddress.position.lng;
    this.customer.location.regionName = this.selectedAddress.address.state;
    this.customer.location.streetName = this.selectedAddress.address.street;
    this.customer.location.streetNumber = this.selectedAddress.address.houseNumber;
    this.customer.location.zipCode = this.selectedAddress.address.postalCode;
    // console.log("county: " + this.selectedAddress.address.county);
    // console.log("district: " + this.selectedAddress.address.district);
    this.Save();
  }

  private async Save(){
    this.loading = true;

    (await this.customerService.create(this.customer)).subscribe(
      data => {
        this.loading = false;
        
        if (data.success === false) {
          this.toastr.error(data.message, "Failed");
        } else {
          this.router.navigate(['/customer-list/']); 
          this.toastr.success(data.message, "");
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
        this.loading = false;
      });
  }
}
