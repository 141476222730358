import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AuthenticationState } from './authentication.model';

export interface IAuthenticationState extends AuthenticationState { }

export function createInitialState(): IAuthenticationState {
    return {
        isLoggedIn: false,
        refreshToken: null,
        jwtToken: null,
        jwtTokenExpiry: null,
        jwtTokenExpiryTicks: 0,
        roles: [],
        role: null
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'authenticationState', resettable: true })
export class AuthenticationStateStore extends Store<IAuthenticationState> {
    constructor() {
        super(createInitialState());
    }
}