import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/_services';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user.model';
import { UiService } from 'src/app/_services/ui.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserEditRequest } from 'src/app/models/userEditRequest.model';
import { WorkDay } from 'src/app/models/workday.model';
import { DayOfWeek } from 'src/app/enums/dayofweek.enum';
import { Subscription } from 'rxjs';
import { faCheckCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  id: number;
  user: User;
  isEditingEmployeeInformation: boolean = false;
  isEditingWorkSchdule: boolean = false;
  isEditing: boolean = false;
  updateEmployeeForm: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  private subscriptions: Subscription[] = [];
  faCheckCircle = faCheckCircle;
  faPencilAlt = faPencilAlt;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private activatedRouter: ActivatedRoute,
    private uiService: UiService,
    private toastr: ToastrService) { }

  get f() { return this.updateEmployeeForm.controls; }

  ngOnDestroy(){
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  ngOnInit() {
    this.id = +this.activatedRouter.snapshot.paramMap.get('id');

    this.updateEmployeeForm = this.formBuilder.group({
      password: [''],
      firstname: ['', Validators.required],
      middlenames: [''],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      mobilenumber: [''],
      worknumber: [''],
      roleId: ['', Validators.required],
      mondayEnabled: [false],
      startTimeMonday: [],
      endTimeMonday: [],
      tuesdayEnabled: [false],
      startTimeTuesday: [],
      endTimeTuesday: [],
      wednesdayEnabled: [false],
      startTimeWednesday: [],
      endTimeWednesday: [],
      thursdayEnabled: [false],
      startTimeThursday: [],
      endTimeThursday: [],
      fridayEnabled: [false],
      startTimeFriday: [],
      endTimeFriday: [],
      saturdayEnabled: [false],
      startTimeSaturday: [],
      endTimeSaturday: [],
      sundayEnabled: [false],
      startTimeSunday: [],
      endTimeSunday: []
    });

    this.subscribeEvents();

    this.initUser();
  }

  subscribeEvents() {

    // Monday
    this.subscriptions.push(this.updateEmployeeForm.get('mondayEnabled').valueChanges.subscribe(val => {
      if (val === true){
        this.updateEmployeeForm.controls['startTimeMonday'].setValidators([Validators.required]);
        this.updateEmployeeForm.controls['endTimeMonday'].setValidators([Validators.required]);
      }
      else{
        this.updateEmployeeForm.controls['startTimeMonday'].clearValidators();
        this.updateEmployeeForm.controls['endTimeMonday'].clearValidators();
      }
      
      this.updateEmployeeForm.controls['startTimeMonday'].updateValueAndValidity();
      this.updateEmployeeForm.controls['endTimeMonday'].updateValueAndValidity();
    }));

    // Tuesday
    this.subscriptions.push(this.updateEmployeeForm.get('tuesdayEnabled').valueChanges.subscribe(val => {
      if (val === true){
        this.updateEmployeeForm.controls['startTimeTuesday'].setValidators([Validators.required]);
        this.updateEmployeeForm.controls['endTimeTuesday'].setValidators([Validators.required]);
      }
      else{
        this.updateEmployeeForm.controls['startTimeTuesday'].clearValidators();
        this.updateEmployeeForm.controls['endTimeTuesday'].clearValidators();
      }
      
      this.updateEmployeeForm.controls['startTimeTuesday'].updateValueAndValidity();
      this.updateEmployeeForm.controls['endTimeTuesday'].updateValueAndValidity();
    }));

    // Wednesday
    this.subscriptions.push(this.updateEmployeeForm.get('wednesdayEnabled').valueChanges.subscribe(val => {
      if (val === true){
        this.updateEmployeeForm.controls['startTimeWednesday'].setValidators([Validators.required]);
        this.updateEmployeeForm.controls['endTimeWednesday'].setValidators([Validators.required]);
      }
      else{
        this.updateEmployeeForm.controls['startTimeWednesday'].clearValidators();
        this.updateEmployeeForm.controls['endTimeWednesday'].clearValidators();
      }
      
      this.updateEmployeeForm.controls['startTimeWednesday'].updateValueAndValidity();
      this.updateEmployeeForm.controls['endTimeWednesday'].updateValueAndValidity();
    }));

    // Thursday
    this.subscriptions.push(this.updateEmployeeForm.get('thursdayEnabled').valueChanges.subscribe(val => {
      if (val === true){
        this.updateEmployeeForm.controls['startTimeThursday'].setValidators([Validators.required]);
        this.updateEmployeeForm.controls['endTimeThursday'].setValidators([Validators.required]);
      }
      else{
        this.updateEmployeeForm.controls['startTimeThursday'].clearValidators();
        this.updateEmployeeForm.controls['endTimeThursday'].clearValidators();
      }
      
      this.updateEmployeeForm.controls['startTimeThursday'].updateValueAndValidity();
      this.updateEmployeeForm.controls['endTimeThursday'].updateValueAndValidity();
    }));

    // Friday
    this.subscriptions.push(this.updateEmployeeForm.get('fridayEnabled').valueChanges.subscribe(val => {
      if (val === true){
        this.updateEmployeeForm.controls['startTimeFriday'].setValidators([Validators.required]);
        this.updateEmployeeForm.controls['endTimeFriday'].setValidators([Validators.required]);
      }
      else{
        this.updateEmployeeForm.controls['startTimeFriday'].clearValidators();
        this.updateEmployeeForm.controls['endTimeFriday'].clearValidators();
      }
      
      this.updateEmployeeForm.controls['startTimeFriday'].updateValueAndValidity();
      this.updateEmployeeForm.controls['endTimeFriday'].updateValueAndValidity();
    }));

    // Saturday
    this.subscriptions.push(this.updateEmployeeForm.get('saturdayEnabled').valueChanges.subscribe(val => {
      if (val === true){
        this.updateEmployeeForm.controls['startTimeSaturday'].setValidators([Validators.required]);
        this.updateEmployeeForm.controls['endTimeSaturday'].setValidators([Validators.required]);
      }
      else{
        this.updateEmployeeForm.controls['startTimeSaturday'].clearValidators();
        this.updateEmployeeForm.controls['endTimeSaturday'].clearValidators();
      }
      
      this.updateEmployeeForm.controls['startTimeSaturday'].updateValueAndValidity();
      this.updateEmployeeForm.controls['endTimeSaturday'].updateValueAndValidity();
    }));

    // Sunday
    this.subscriptions.push(this.updateEmployeeForm.get('sundayEnabled').valueChanges.subscribe(val => {
      if (val === true){
        this.updateEmployeeForm.controls['startTimeSunday'].setValidators([Validators.required]);
        this.updateEmployeeForm.controls['endTimeSunday'].setValidators([Validators.required]);
      }
      else{
        this.updateEmployeeForm.controls['startTimeSunday'].clearValidators();
        this.updateEmployeeForm.controls['endTimeSunday'].clearValidators();
      }
      
      this.updateEmployeeForm.controls['startTimeSunday'].updateValueAndValidity();
      this.updateEmployeeForm.controls['endTimeSunday'].updateValueAndValidity();
    }));
  }

  private async initUser() {
    (await this.userService.initUser(this.id)).subscribe(
      data => {
        if (data.success === false) {
          this.toastr.error(data.message, "User error");
        }
        else {
          this.user = data.user;

          this.updateEmployeeForm.controls['roleId'].setValue(this.user.roleId);
          this.updateEmployeeForm.controls['firstname'].setValue(this.user.firstName);
          this.updateEmployeeForm.controls['middlenames'].setValue(this.user.middlenames);
          this.updateEmployeeForm.controls['lastname'].setValue(this.user.lastname);
          this.updateEmployeeForm.controls['email'].setValue(this.user.email);
          this.updateEmployeeForm.controls['mobilenumber'].setValue(this.user.mobilenumber);
          this.updateEmployeeForm.controls['worknumber'].setValue(this.user.worknumber);
          
          if (this.user.mondayEnabled) {
            this.updateEmployeeForm.controls['mondayEnabled'].setValue(this.user.mondayEnabled);
            this.updateEmployeeForm.controls['startTimeMonday'].setValue(this.user.startTimeFormattedMonday);
            this.updateEmployeeForm.controls['endTimeMonday'].setValue(this.user.endTimeFormattedMonday);
          }
          
          if (this.user.tuesdayEnabled) {
            this.updateEmployeeForm.controls['tuesdayEnabled'].setValue(this.user.tuesdayEnabled);
            this.updateEmployeeForm.controls['startTimeTuesday'].setValue(this.user.startTimeFormattedTuesday);
            this.updateEmployeeForm.controls['endTimeTuesday'].setValue(this.user.endTimeFormattedTuesday);
          }

          if (this.user.wednesdayEnabled) {
            this.updateEmployeeForm.controls['wednesdayEnabled'].setValue(this.user.wednesdayEnabled);
            this.updateEmployeeForm.controls['startTimeWednesday'].setValue(this.user.startTimeFormattedWednesday);
            this.updateEmployeeForm.controls['endTimeWednesday'].setValue(this.user.endTimeFormattedWednesday);
          }

          if (this.user.thursdayEnabled) {
            this.updateEmployeeForm.controls['thursdayEnabled'].setValue(this.user.thursdayEnabled);
            this.updateEmployeeForm.controls['startTimeThursday'].setValue(this.user.startTimeFormattedThursday);
            this.updateEmployeeForm.controls['endTimeThursday'].setValue(this.user.endTimeFormattedThursday);
          }

          if (this.user.fridayEnabled) {
            this.updateEmployeeForm.controls['fridayEnabled'].setValue(this.user.fridayEnabled);
            this.updateEmployeeForm.controls['startTimeFriday'].setValue(this.user.startTimeFormattedFriday);
            this.updateEmployeeForm.controls['endTimeFriday'].setValue(this.user.endTimeFormattedFriday);
          }

          if (this.user.saturdayEnabled) {
            this.updateEmployeeForm.controls['saturdayEnabled'].setValue(this.user.saturdayEnabled);
            this.updateEmployeeForm.controls['startTimeSaturday'].setValue(this.user.startTimeFormattedSaturday);
            this.updateEmployeeForm.controls['endTimeSaturday'].setValue(this.user.endTimeFormattedSaturday);
          }

          if (this.user.sundayEnabled) {
            this.updateEmployeeForm.controls['sundayEnabled'].setValue(this.user.sundayEnabled);
            this.updateEmployeeForm.controls['startTimeSunday'].setValue(this.user.startTimeFormattedSunday);
            this.updateEmployeeForm.controls['endTimeSunday'].setValue(this.user.endTimeFormattedSunday);
          }
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }

  onEditEmployeeInformation() {
    this.isEditingEmployeeInformation = true;
    this.isEditing = true;
  }

  async onSaveEmployee() {
    this.submitted = true;
    if (this.updateEmployeeForm.valid) {
      var user = new UserEditRequest();
      user.id = this.id;
      user.firstName = this.updateEmployeeForm.value.firstname;
      user.middlenames = this.updateEmployeeForm.value.middlenames;
      user.lastname = this.updateEmployeeForm.value.lastname;
      user.email = this.updateEmployeeForm.value.email;
      user.password = this.updateEmployeeForm.value.password;
      user.mobilenumber = this.updateEmployeeForm.value.mobilenumber;
      user.worknumber = this.updateEmployeeForm.value.worknumber;
      user.role = this.updateEmployeeForm.value.roleId;
      user.workdays = [];

      if (this.updateEmployeeForm.value.mondayEnabled) {
        var wdMonday = new WorkDay();
        wdMonday.dayOfWeek = DayOfWeek.Monday;
        wdMonday.startTimeHour = this.updateEmployeeForm.value.startTimeMonday.split(":")[0];
        wdMonday.startTimeMinute = this.updateEmployeeForm.value.startTimeMonday.split(":")[1];
        wdMonday.endTimeHour = this.updateEmployeeForm.value.endTimeMonday.split(":")[0];
        wdMonday.endTimeMinute = this.updateEmployeeForm.value.endTimeMonday.split(":")[1];
        user.workdays.push(wdMonday);
      }

      if (this.updateEmployeeForm.value.tuesdayEnabled) {
        var wdTuesday = new WorkDay();
        wdTuesday.dayOfWeek = DayOfWeek.Tuesday;
        wdTuesday.startTimeHour = this.updateEmployeeForm.value.startTimeTuesday.split(":")[0];
        wdTuesday.startTimeMinute = this.updateEmployeeForm.value.startTimeTuesday.split(":")[1];
        wdTuesday.endTimeHour = this.updateEmployeeForm.value.endTimeTuesday.split(":")[0];
        wdTuesday.endTimeMinute = this.updateEmployeeForm.value.endTimeTuesday.split(":")[1];
        user.workdays.push(wdTuesday);
      }

      if (this.updateEmployeeForm.value.wednesdayEnabled) {
        var wdWednesday = new WorkDay();
        wdWednesday.dayOfWeek = DayOfWeek.Wednesday;
        wdWednesday.startTimeHour = this.updateEmployeeForm.value.startTimeWednesday.split(":")[0];
        wdWednesday.startTimeMinute = this.updateEmployeeForm.value.startTimeWednesday.split(":")[1];
        wdWednesday.endTimeHour = this.updateEmployeeForm.value.endTimeWednesday.split(":")[0];
        wdWednesday.endTimeMinute = this.updateEmployeeForm.value.endTimeWednesday.split(":")[1];
        user.workdays.push(wdWednesday);
      }

      if (this.updateEmployeeForm.value.thursdayEnabled) {
        var wdThursday = new WorkDay();
        wdThursday.dayOfWeek = DayOfWeek.Thursday;
        wdThursday.startTimeHour = this.updateEmployeeForm.value.startTimeThursday.split(":")[0];
        wdThursday.startTimeMinute = this.updateEmployeeForm.value.startTimeThursday.split(":")[1];
        wdThursday.endTimeHour = this.updateEmployeeForm.value.endTimeThursday.split(":")[0];
        wdThursday.endTimeMinute = this.updateEmployeeForm.value.endTimeThursday.split(":")[1];
        user.workdays.push(wdThursday);
      }

      if (this.updateEmployeeForm.value.fridayEnabled) {
        var wdFriday = new WorkDay();
        wdFriday.dayOfWeek = DayOfWeek.Friday;
        wdFriday.startTimeHour = this.updateEmployeeForm.value.startTimeFriday.split(":")[0];
        wdFriday.startTimeMinute = this.updateEmployeeForm.value.startTimeFriday.split(":")[1];
        wdFriday.endTimeHour = this.updateEmployeeForm.value.endTimeFriday.split(":")[0];
        wdFriday.endTimeMinute = this.updateEmployeeForm.value.endTimeFriday.split(":")[1];
        user.workdays.push(wdFriday);
      }

      if (this.updateEmployeeForm.value.saturdayEnabled) {
        var wdSaturday = new WorkDay();
        wdSaturday.dayOfWeek = DayOfWeek.Saturday;
        wdSaturday.startTimeHour = this.updateEmployeeForm.value.startTimeSaturday.split(":")[0];
        wdSaturday.startTimeMinute = this.updateEmployeeForm.value.startTimeSaturday.split(":")[1];
        wdSaturday.endTimeHour = this.updateEmployeeForm.value.endTimeSaturday.split(":")[0];
        wdSaturday.endTimeMinute = this.updateEmployeeForm.value.endTimeSaturday.split(":")[1];
        user.workdays.push(wdSaturday);
      }

      if (this.updateEmployeeForm.value.sundayEnabled) {
        var wdSunday = new WorkDay();
        wdSunday.dayOfWeek = DayOfWeek.Sunday;
        wdSunday.startTimeHour = this.updateEmployeeForm.value.startTimeSunday.split(":")[0];
        wdSunday.startTimeMinute = this.updateEmployeeForm.value.startTimeSunday.split(":")[1];
        wdSunday.endTimeHour = this.updateEmployeeForm.value.endTimeSunday.split(":")[0];
        wdSunday.endTimeMinute = this.updateEmployeeForm.value.endTimeSunday.split(":")[1];
        user.workdays.push(wdSunday);
      }

      this.loading = true;

      (await this.userService.edit(user)).subscribe(
        data => {
          this.loading = false;
          
          if(data.success === false){
            this.toastr.error(data.message, "Update failure");
          } else{
            this.isEditingEmployeeInformation = false;
            this.isEditingWorkSchdule = false;
            this.isEditing = false;
            this.toastr.success(data.message, "");
            this.initUser();
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
        }
      );
    }
  }

  onEditWorkSchdule() {
    this.isEditingWorkSchdule = true;
    this.isEditing = true;
  }

  // private onEdit() {
  //   this.router.navigate(['/useredit/', this.id]);
  // }

}
