import { IAssignedUser } from '../interfaces/assignedUser.interface';

export class AssignedUser implements IAssignedUser {
    assigned: boolean;
    hoursUsed: number;
    minutesUsed: number;
    id: number = 0;
    identifier: string = "";
    isAdmin: boolean = false;
    language: string = "";
    username: string = "";
    firstName: string = "";
    middleNames: string = "";
    lastName: string = "";
    email: string = "";
    mobilenumber: string = "";
    worknumber: string = "";
    mondayEnabled: boolean = false;
    startTimeMonday: number = 0;
    endTimeMonday: number = 0;
    tuesdayEnabled: boolean = false;
    startTimeTuesday: number = 0;
    endTimeTuesday: number = 0;
    wednesdayEnabled: boolean = false;
    startTimeWednesday: number = 0;
    endTimeWednesday: number = 0;
    thursdayEnabled: boolean = false;
    startTimeThursday: number = 0;
    endTimeThursday: number = 0;
    fridayEnabled: boolean = false;
    startTimeFriday: number = 0;
    endTimeFriday: number = 0;
    saturdayEnabled: boolean = false;
    startTimeSaturday: number = 0;
    endTimeSaturday: number = 0;
    sundayEnabled: boolean = false;
    startTimeSunday: number = 0;
    endTimeSunday: number = 0;
}