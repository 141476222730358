import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieConsentStateQuery } from '../cookie-consent/cookie-consent.query';
import { LanguageStateStore } from './language.store';

@Injectable({
  providedIn: 'root'
})
export class LanguageStateService {

  constructor(
    private http: HttpClient,
    private languageStateStore: LanguageStateStore,
    private cookieConsentQuery: CookieConsentStateQuery
  ) { }

  async updateLocaleIdentifier(lcid: string) {

    if (lcid == '' || lcid == null) {
      return;
    }

    if (this.cookieConsentQuery.isFunctionalCookiesAllowed()) {
      this.languageStateStore.update({
        ...this.languageStateStore.getValue(),
        localeIdentifier: lcid.trim()
      });
    }
  }

  async updateFirstDayOfWeek(firstDayOfWeek: number) {

    if (this.cookieConsentQuery.isFunctionalCookiesAllowed()) {
      this.languageStateStore.update({
        ...this.languageStateStore.getValue(),
        firstDayOfWeek: firstDayOfWeek
      });
    }
  }

  async reset() {
    this.languageStateStore.reset();
  }
}
