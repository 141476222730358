import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-master-dashboard',
  templateUrl: './master-dashboard.component.html',
  styles: []
})
export class MasterDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
