import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { GanttState } from './gantt.model';
import { IGanttState, GanttStateStore } from './gantt.store';

export interface GanttStateState extends GanttState { }

@Injectable({ providedIn: 'root' })
export class GanttStateQuery extends Query<IGanttState> {

  constructor(protected store: GanttStateStore) {
    super(store);
  }

  getSelectedDate(): Date {
    return this.store.getValue().selectedDate;
  }
  
}
