import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieConsentStateQuery } from '../cookie-consent/cookie-consent.query';
import { GanttStateStore } from './gantt.store';

@Injectable({
  providedIn: 'root'
})
export class GanttStateService {

  constructor(
    private http: HttpClient,
    private ganttStateStore: GanttStateStore,
    private cookieConsentQuery: CookieConsentStateQuery
  ) { }

  async updateSelectedDate(selectedDate: Date){
    if (this.cookieConsentQuery.isFunctionalCookiesAllowed()) {
      this.ganttStateStore.update({
        ...this.ganttStateStore.getValue(),
        selectedDate: selectedDate
      });
    }
  }

  async reset() {
    this.ganttStateStore.reset();
  }
}
