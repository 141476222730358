import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentEventService {
  // Observable sources
  private cookieConsentEventSource = new Subject();

  cookieConsentEvent$ = this.cookieConsentEventSource.asObservable();

  constructor() { }

  emitCookieConsent() {
    this.cookieConsentEventSource.next();
  }
}
