import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-employees',
  templateUrl: './privacy-policy-employees.component.html',
  styles: []
})
export class PrivacyPolicyEmployeesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
