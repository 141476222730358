import { Injectable } from '@angular/core';
import { Router , CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationStateQuery } from '../state/authentication/authentication.query';
import { AuthenticationStateService } from '../state/authentication/authentication.service';
import { AuthenticationService } from '../_services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
      private router: Router,
      private authService: AuthenticationService,
      private authStateQuery: AuthenticationStateQuery
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
    //const currentUser = this.authService.currentUserValue;
    const role = route.data.role;
    var currentRole = this.authStateQuery.getUsersRole();
    if (this.authStateQuery.isLoggedIn()) {
      if(role == currentRole.toLowerCase())
      {
        return true;          
      }
      else {
        // TODO: Send to login - alert("Wrong role! You are " + currentRole + " and role needed is " + role);
        //this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;          
      }
    }
    return false;
  }
}