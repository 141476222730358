import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, AlertService } from '../../_services'
import { UiService } from 'src/app/_services/ui.service';
import { ToastrService } from 'ngx-toastr';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { AuthenticationStateQuery } from 'src/app/state/authentication/authentication.query';

@Component({templateUrl: 'login.component.html'})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private uiService: UiService,
        private alertService: AlertService,
        private toastr: ToastrService,
        private authenticationStateQuery: AuthenticationStateQuery
    ) {
        // redirect to home if already logged in
        if(this.authenticationStateQuery.isLoggedIn){
            this.router.navigate(['/']);
        }

        // if (this.authenticationService.currentUserValue) { 
        //     this.router.navigate(['/']);
        // }
    }
    
    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.uiService.loadingStart();
        this.loading = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.uiService.loadingComplete();
                  if(data.success === false)
                  {
                    this.toastr.error(data.message, "");
                  }
                  else{
                    this.router.navigate([this.returnUrl]);
                  }
                  this.loading = false;
                },
                error => {
                    this.uiService.loadingCompleteWithError();
                    this.loading = false;
                });
    }
}