import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { CookieConsentState } from './cookie-consent.model';

export interface ICookieConsentState extends CookieConsentState { }

export function createInitialState(): ICookieConsentState {
    return {
        isCookiesAccepted: false,
        isFunctionalCookiesAllowed: false,
        isStatisticalCookiesAllowed: false,
        isMarketingCookiesAllowed: false,
        externalKey: ''
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cookieConsentState', resettable: true })
export class CookieConsentStateStore extends Store<ICookieConsentState> {
    constructor() {
        super(createInitialState());
    }
}