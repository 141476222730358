import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ITask } from '../interfaces/task.interface';
import { Task } from '../models/task.model';
import { AssignmentFilter } from '../enums/assignmentfilter.enum';
import { AuthenticationService } from './authentication.service';
import { UiService } from './ui.service';
import { ToastrService } from 'ngx-toastr';
import { TaskEditRequest } from '../models/taskEditRequest.model';
import { AuthenticationStateQuery } from '../state/authentication/authentication.query';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  
  private headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

  constructor(private http: HttpClient,
    private authService: AuthenticationService,
    private uiService: UiService,
    private toastr: ToastrService,
    private authQuery: AuthenticationStateQuery
    ) { }

  success: boolean;
  message: string;
  date: any;
  dateFormatted: string;

  public async initDashboard() {
    
    this.uiService.loadingStart();
    
    await this.authService.isExpired();
    
    return this.http.get<any>(environment.worktasksUrl + "/WTTasks/InitDashboard")
      .pipe(map(data => {
        this.uiService.loadingComplete();
        return data;
      }));
  }

  public async initGantt(currentDate: Date) {
    
    this.uiService.loadingStart();
    
    await this.authService.isExpired();
    
    var model = {
      AssignmentFilter: AssignmentFilter.Created,
      CurrentDate: currentDate,
      Only24Hours: true
    };
    
    return this.http.post<any>(environment.worktasksUrl + "/WTTasks/InitGantt", model)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        return data;
      }));
  }

  public async getGantt(date: Date) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    var model = {
      Ticks: ((date.getTime() * 10000) + 621355968000000000),
      AssignmentFilter: AssignmentFilter.Created,
      Only24Hours: true
    };

    return this.http.post<any>(environment.worktasksUrl + "/WTTasks/GetGantt", model)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        return data;
      }));
  }

  /*
  public async getInitCurrentLocaleDate() {

    this.uiService.loadingStart();
    return this.http.get<any>(environment.baseUrl + "/Session/GetCurrentLocaleDate")
      .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        this.date = data.date;
        this.dateFormatted = data.dateFormatted;
        return data;
      }));
  }
  */

  async getLocaleDate(date: Date) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    var ticks = ((date.getTime() * 10000) + 621355968000000000);

    var body = {
      Ticks: ticks,
    };

    return this.http.post<any>(environment.baseUrl + "/Session/GetLocaleDate", body)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        this.success = data.success;
        this.message = data.message;
        this.date = data.date;
        this.dateFormatted = data.dateFormatted;
        return data;
      }));
  }

  async get(id: number) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    var body = {
      TaskId: id
    };

    return this.http.post<any>(environment.baseUrl + "/Task/GetTask", body)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        this.success = data.success;
        this.message = data.message;
        return data.assignment;
      }));
  }

  /*
  getStateButtonsForTask(taskId: number) {
    var parameters: string = "token=" + localStorage.getItem('token') + "&" + "taskId=" + taskId;
    return this.http.get<any>(environment.baseUrl + "/ServiceTasks/GetStateButtonsForTask?" + parameters)
      .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        return data;
      }));
  }
  */

  async setStateButtonForTask(taskStateButtonId: number, taskId: number) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    var body = {
      TaskId: taskId,
      TaskStateButtonId: taskStateButtonId
    };

    return this.http.post<any>(environment.baseUrl + "/TaskState/ChangeTaskState", body)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        this.success = data.success;
        this.message = data.message;
        return data;
      }));
  }

  async getAll(assignmentFilters: AssignmentFilter[], startDate: Date, endDate: Date) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    var model = {
      AssignmentFilters: assignmentFilters,
      StartDate: startDate,
      EndDate: endDate
    };
    console.info("Posting to GetAssignmentsByCompanyWithMultipleFilters (#1)");
    return this.http.post<any>(environment.baseUrl + "/Task/GetAssignmentsByCompanyWithMultipleFilters", model)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        this.success = data.success;
        this.message = data.message;
        return data.assignments;
      }));
  }

  async getAllUnassigned(assignmentFilters: AssignmentFilter[], startDate: Date, endDate: Date) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    var model = {
      AssignmentFilters: assignmentFilters,
      StartDate: startDate,
      EndDate: endDate
    };
    console.info("Posting to GetUnassignedAssignmentsByCompany (#2)");
    return this.http.post<any>(environment.baseUrl + "/Task/GetUnassignedAssignmentsByCompany", model)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        this.success = data.success;
        this.message = data.message;
        return data.assignments;
      }));
  }

  async getUsersTasksForUser(){
    this.uiService.loadingStart();
    await this.authService.isExpired();

    var model = {
      AssignmentFilter: AssignmentFilter.AllNotClosed
    };

    console.info("Posting to GetUsersAssignments (#3)");
    return this.http.post<any>(environment.baseUrl + "/Task/GetUsersAssignments", model)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        this.success = data.success;
        this.message = data.message;
        return data.assignments;
      }));
  }

  async getUsersTasks(assignmentFilters: AssignmentFilter[], startDate: Date, endDate: Date) {
    
    this.uiService.loadingStart();
    await this.authService.isExpired();

    var model = {
      AssignmentFilters: assignmentFilters,
      StartDate: startDate,
      EndDate: endDate
    };
    console.info("Posting to GetUsersAssignmentsWithMultipleFilters (#4)");
    return this.http.post<any>(environment.baseUrl + "/Task/GetUsersAssignmentsWithMultipleFilters", model)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        this.success = data.success;
        this.message = data.message;
        return data.assignments;
      }));
  }

  async getAssignmentsByDay(date: Date) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    var ticks = ((date.getTime() * 10000) + 621355968000000000);

    var body = {
      Token: this.authQuery.getJwtToken(),
      Ticks: ticks,
      AssignmentFilter: AssignmentFilter.Created
    };

    return this.http.post<any>(environment.baseUrl + "/Task/GetAssignmentsByDay", body)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        this.success = data.success;
        this.message = data.message;
        return data.assignments;
      }));
  }

  async create(task: Task) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    var model = {
      CustomerId: task.customerId.toString(),
      TypeId: task.typeId.toString(),
      Name: task.name,
      Description: task.description,
      StartYear: task.startYear.toString(),
      StartMonth: task.startMonth.toString(),
      StartDay: task.startDay.toString(),
      StartHour: task.startHour.toString(),
      StartMinute: task.startMinute.toString(),
      EndYear: task.endYear.toString(),
      EndMonth: task.endMonth.toString(),
      EndDay: task.endDay.toString(),
      EndHour: task.endHour.toString(),
      EndMinute: task.endMinute.toString(),
      UseCustomerAddress: task.useCustomerLocation,
      Latitude: task.location.latitude,
      Longitude: task.location.longitude,
      FullAddress: task.location.fullAddress,
      StreetAddress: task.location.streetName,
      StreetNo: task.location.streetNumber,
      ZipCode: task.location.zipCode,
      City: task.location.cityName,
      District: task.location.regionName,
      Country: task.location.country,
      AssignTo: Array<number>()
    };

    task.assignedUsers.forEach(user => {
      model.AssignTo.push(user.id);
    });

    return this.http.post<any>(environment.baseUrl + "/Task/CreateTask", model)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        this.success = data.success;
        this.message = data.message;
        return data;
      }));
  }

  async edit(task: TaskEditRequest) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    var model = {
      TaskId: task.taskId,
      CustomerId: task.customerId.toString(),
      TypeId: task.typeId.toString(),
      Name: "",
      Description: task.description,
      StartYear: task.startYear.toString(),
      StartMonth: task.startMonth.toString(),
      StartDay: task.startDay.toString(),
      StartHour: task.startHour.toString(),
      StartMinute: task.startMinute.toString(),
      EndYear: task.endYear.toString(),
      EndMonth: task.endMonth.toString(),
      EndDay: task.endDay.toString(),
      EndHour: task.endHour.toString(),
      EndMinute: task.endMinute.toString(),
      LocationId: task.location.id.toString(),
      Latitude: task.location.latitude,
      Longitude: task.location.longitude,
      UseCustomerAddress: task.useCustomerLocation,
      FullAddress: task.location.fullAddress,
      StreetAddress: task.location.streetName,
      StreetNo: task.location.streetNumber,
      ZipCode: task.location.zipCode,
      City: task.location.cityName,
      District: task.location.regionName,
      Country: task.location.country,
      AssignTo: Array<number>()
    };

    task.assignedTo.forEach(user => {
      model.AssignTo.push(user.id);
    });

    return this.http.post<any>(environment.baseUrl + "/Task/EditTask", model)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        this.success = data.success;
        this.message = data.message;
        return data;
      }));
  }

  async delete(id: number) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    var model = {
      TaskId: id.toString()
    };

    return this.http.post<any>(environment.baseUrl + "/Task/DeleteTask", model)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        return data;
      }));
  }

  async initCreateTask() {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    return this.http.get<any>(environment.worktasksUrl + "/WTTasks/InitCreateTask")
      .pipe(map(data => {
        this.uiService.loadingComplete();
        return data;
      }));
  }

  async initTask(id: number) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    var body = {
      TaskId: id
    };

    return this.http.post<any>(environment.worktasksUrl + "/WTTasks/InitTask", body)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        this.success = data.success;
        this.message = data.message;
        return data;
      }));
  }

  async initEditTask(id: number) {
    
    this.uiService.loadingStart();
    await this.authService.isExpired();

    var body = {
      TaskId: id
    };

    return this.http.post<any>(environment.worktasksUrl + "/WTTasks/InitEditTask", body)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        this.success = data.success;
        this.message = data.message;
        return data;
      }));
  }

}
