import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services';
import { Observable } from 'rxjs';
import { AuthenticationStateQuery } from '../state/authentication/authentication.query';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuardService implements CanActivate {
  constructor(
      private router: Router,
      private authService: AuthenticationService,
      private authQuery: AuthenticationStateQuery
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {

    const role = this.authQuery.getUsersRole();
    
    if (role) {
      this.router.navigate(['/'+role.toLowerCase()]);
      return true;      
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}