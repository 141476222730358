import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/_services/dialog.service';
import { UserService, AuthenticationService } from 'src/app/_services';
import { first } from 'rxjs/operators';
import { UiService } from 'src/app/_services/ui.service';
import { faPlusCircle  } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService,
    private userService: UserService,
    private dialogService: DialogService,
    private uiService: UiService,
    private toastr: ToastrService,
    private router: Router) { }
     
  listData: MatTableDataSource<any>;
  displayedColumns: string[]  = ['name', 'role', 'actions'];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  searchKey: string;
  faPlusCircle = faPlusCircle;

  ngOnInit() {
    this.loadAllUsers();
  }

  onSearchClear(){
    this.searchKey = "";
    this.applyFilter();
  }

  applyFilter(){
      this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  private async loadAllUsers() {
      (await this.userService.getAll()).subscribe(
          list => {
              let array = list.map(item => {
                  return {
                      id: item.id,
                      name: item.fullname,
                      role: item.role
                  };
              });
              this.listData = new MatTableDataSource(array);
              this.listData.sort = this.sort;
              this.listData.paginator = this.paginator;
              this.listData.filterPredicate = (data, filter) => {
                  return this.displayedColumns.some(ele => {
                      return ele != 'actions' && data[ele].toString().toLowerCase().indexOf(filter) != -1;
                  })
              }
          }
      );
  }

  onShow(row){
      let userId: number = row.id;
      this.router.navigate(['/user/', userId]); 
  }

  onEdit(row){
      let userId: number = row.id;
      this.router.navigate(['/useredit/', userId]); 
  }

  onDelete(row) {
      this.dialogService.openConfirmDialog('Are you sure to delete this user?')
      .afterClosed().subscribe(async res => {
          if(res)
          {
              let userId: number = row.id;
              (await this.userService.delete(userId))
                  .pipe(first())
                  .subscribe(
                    data => {
                        if(data.success === false){
                          this.toastr.error(data.message, "Error deleting user");
                        } 
                        else{
                            this.toastr.success('Deleted successfully', 'User');
                            this.loadAllUsers();
                          }
                        },
                        error => {
                          this.uiService.loadingCompleteWithError();
                      });
          }
      });
  }
}
