import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { LanguageState } from './language.model';
import { ILanguageState, LanguageStateStore } from './language.store';

export interface LanguageStateState extends LanguageState {} 

@Injectable({ providedIn: 'root' })
export class LanguageStateQuery extends Query<ILanguageState> {
  
  constructor(protected store: LanguageStateStore) {
    super(store);
  }

  getLocaleIdentifier(): string {
    return this.store.getValue().localeIdentifier;
  }

  getFirstDayOfWeek(): number {
    return this.store.getValue().firstDayOfWeek;
  }
}
