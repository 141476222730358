import { Component, OnInit } from '@angular/core';
import { Dashboard } from 'src/app/models/dashboard.model';
import { TaskService } from 'src/app/_services';
import { ToastrService } from 'ngx-toastr';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public dashboard: Dashboard;

  constructor(
    private taskService: TaskService,
    private uiService: UiService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.loadViewModel();
  }

  async loadViewModel(){

    (await this.taskService.initDashboard()).subscribe(
      data => {
        if(data.success === false){
          this.toastr.error(data.message, "");
        } else{
          this.dashboard = data.dashboard;
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

}
