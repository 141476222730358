import { Component, OnInit } from '@angular/core';
import { PricingPlan } from 'src/app/enums/pricingplan.enum';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CompanyService } from 'src/app/_services';
import { ToastrService } from 'ngx-toastr';
import { UiService } from 'src/app/_services/ui.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-plan-update',
  templateUrl: './plan-update.component.html',
  styleUrls: ['./plan-update.component.css']
})
export class PlanUpdateComponent implements OnInit {
  public chosenplanName: string;
  public chosenplan: PricingPlan;
  public existingPlan: PricingPlan;
  public email: string;
  public priceBasic: number;
  public priceStandard: number;
  public pricePlus: number;
  public pricePro: number;
  public currency: string;
  public submitted: boolean;
  changeplanForm: FormGroup;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private toastr: ToastrService,
    private uiService: UiService) { }

  ngOnInit() {
    this.changeplanForm = this.formBuilder.group({
      email: [this.email, Validators.required]
    });

    this.loadViewModel();
  }

  get f() { return this.changeplanForm.controls; }

  async loadViewModel() {

    (await this.companyService.initChangePricingPlan()).pipe(take(1)).subscribe(
      data => {
        if (data.success === false) {
          this.toastr.error(data.message, "");
        } else {
          this.currency = data.currency;
          this.email = data.usersEmail;
          this.existingPlan = data.pricingPlanId;
          this.priceBasic = data.priceBasic;
          this.priceStandard = data.priceStandard;
          this.pricePlus = data.pricePlus;
          this.pricePro = data.pricePro;
          $("#price_table").removeAttr("hidden");
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }

  async OnConfirmed() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.email == "") {
      return;
    }

    this.loading = true;

    (await this.companyService.editPricingPlan(this.chosenplan, this.email)).pipe(take(1)).subscribe(
      data => {
        if (data.success === false) {
          this.toastr.error(data.message, "");
        }
        else {
          this.toastr.success("Your order has been sent", "Order sent");
          document.getElementById('closeDialog').click();
        }
        this.loading = false;
      },
      error => {
        this.uiService.loadingCompleteWithError();
        this.loading = false;
      });
  }

  OnSelectPlan(plan: PricingPlan) {
    this.chosenplan = plan;
    this.chosenplanName = PricingPlan[plan];
  }
}
