import { ILocation } from '../interfaces/location.interface';

export class Location implements ILocation{
    countryId: number = 0;
    id: number = 0;
    streetName: string = "";
    streetNumber: string = "";
    cityName: string = "";
    sublocality: string = "";
    administrativeArea: string = "";
    regionName: string = "";
    zipCode: string = "";
    country: string = "";
    fullAddress: string = "";
    latitude: string = "0";
    longitude: string = "0";
}