import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TaskListStateService } from '../task-list/task-list.service';
import { CookieConsentStateStore } from './cookie-consent.store';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentStateService {

  constructor(
    private http: HttpClient,
    private cookieConsentStateStore: CookieConsentStateStore,
    private taskListStateService: TaskListStateService
  ) { }

  async updateCookieConsent(
      isCookieConsentAccepted: boolean,
      isFunctionalCookiesAllowed: boolean,
      isStatisticalCookiesAllowed: boolean,
      isMarketingCookiesAllowed: boolean,
      externalKey: string) {

    this.cookieConsentStateStore.update({
        ...this.cookieConsentStateStore.getValue(),
        isCookiesAccepted: isCookieConsentAccepted,
        isFunctionalCookiesAllowed: isFunctionalCookiesAllowed,
        isStatisticalCookiesAllowed: isStatisticalCookiesAllowed,
        isMarketingCookiesAllowed: isMarketingCookiesAllowed,
        externalKey: externalKey
      });
  }

  async withdrawConsent(){
    this.cookieConsentStateStore.reset();
    this.taskListStateService.reset();
  }
}
