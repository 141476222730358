import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ITask } from '../interfaces/task.interface';
import { Task } from '../models/task.model';
import { AuthenticationService } from './authentication.service';
import { AuthenticationStateQuery } from '../state/authentication/authentication.query';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

  constructor(
    private http: HttpClient, 
    private authService: AuthenticationService,
    private authQuery: AuthenticationStateQuery 
    ) { }

  success: boolean;
  message: string;

  get(attachmentId: number) {

    var parameters: string = "token="+this.authQuery.getJwtToken() + "&" + "attachmentId="+attachmentId;
    return this.http.get<any>(environment.baseUrl +"/ServiceTasks/GetFile?" + parameters)
    .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        return data.file;
    }));
  }

  getContent(attachmentId: string) {

    var parameters: string = "token="+this.authQuery.getJwtToken() + "&" + "attachmentId="+attachmentId;
    //this.http.get<any>(environment.baseUrl +"/ServiceTasks/GetFileContent?" + parameters);

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    
    this.http.get(environment.baseUrl +"/ServiceTasks/GetFileContent?" + parameters, { headers: headers, responseType: 'blob' as 'json' }).subscribe((res) => {

      //this.saveFile(res., "downloadedfile.pdf");
    });

  }

  async getAll(assignmentId: number) {

    await this.authService.isExpired();

    var body = {
      TaskId: assignmentId
    };

    return this.http.post<any>(environment.baseUrl +"/Attachment/GetAssignmentAttachments", body)
    .pipe(map(data => {
        this.success = data.success;
        this.message = data.message;
        return data.attachments;
    }));
  }

  async upload(file: File, taskId: number, title: string, description: string) {

    await this.authService.isExpired();

    const formData: FormData = new FormData();
    formData.append("file", file);
    formData.append("extension", file.name.split('.').pop());
    formData.append("title", title);
    formData.append("description", description);
    formData.append("token", this.authQuery.getJwtToken());
    formData.append("assignmentId", taskId.toString());

    return this.http.post<any>(environment.baseUrl +"/Attachment/UploadFileAsync", formData)
      .pipe(map(data => {
          return data;
      }));
  }
}
