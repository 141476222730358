import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  status!: string;
  public language: string = 'en';
  sendEmailForm: FormGroup;
  public loading: boolean = false;
  submitted = false;
  
  constructor(private formBuilder: FormBuilder) { }

  get f() { return this.sendEmailForm.controls; }

  ngOnInit() {
    this.sendEmailForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      companyname: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  async onSubmit() {
    alert("OnSubmit");
  }
  
}
