import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { LanguageState } from './language.model';

export interface ILanguageState extends LanguageState { }

export function createInitialState(): ILanguageState {
    return {
        localeIdentifier: 'en-GB',
        firstDayOfWeek: 1
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'languageState', resettable: true })
export class LanguageStateStore extends Store<ILanguageState> {
    constructor() {
        super(createInitialState());
    }
}