import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/_services/dialog.service';
import { UserService, AuthenticationService } from 'src/app/_services';
import { first } from 'rxjs/operators';
import { CustomerService } from 'src/app/_services/customer.service';
import { UiService } from 'src/app/_services/ui.service';
import { faPlusCircle  } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    private customerService: CustomerService,
    private dialogService: DialogService,
    private uiService: UiService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  listData: MatTableDataSource<any>;
  displayedColumns: string[]  = ['name', 'fullAddress', 'actions'];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  searchKey: string;
  faPlusCircle = faPlusCircle;

  ngOnInit() {
    this.loadCustomers();
  }

  onSearchClear(){
    this.searchKey = "";
    this.applyFilter();
  }

  applyFilter(){
      this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  private async loadCustomers() {
      (await this.customerService.getAll()).subscribe(
          list => {
              let array = list.customers.map(item => {

                  return {
                      id: item.id,
                      name: item.name,
                      fullAddress: item.location == null ? '' : item.location.fullAddress
                  };
              });
              this.listData = new MatTableDataSource(array);
              this.listData.sort = this.sort;
              this.listData.paginator = this.paginator;
              this.listData.filterPredicate = (data, filter) => {
                  return this.displayedColumns.some(ele => {
                      return ele != 'actions' && data[ele].toString().toLowerCase().indexOf(filter) != -1;
                  })
              }
          }
      );
  }

  onShow(row){
      let customerId: number = row.id;
      const create = false;
      this.router.navigate(['/customer/', customerId, create]); 
  }

  onEdit(row){
      let customerId: number = row.id;
      this.router.navigate(['/customer-edit/', customerId]); 
  }

  onDelete(row) {
      this.dialogService.openConfirmDialog('Are you sure to delete this customer?')
      .afterClosed().subscribe(async res => {
          if(res)
          {
              let customerId: number = row.id;
              (await this.customerService.delete(customerId))
                  .pipe(first())
                  .subscribe(
                    data => {
                        if(data.success === false){
                          this.toastr.error(data.message, "Error deleting customer");
                        } 
                        else{
                            this.toastr.success('Deleted successfully', 'Customer');
                            this.loadCustomers();
                          }
                        },
                        error => {
                          this.uiService.loadingCompleteWithError();
                      });
          }
      });
  }
}
