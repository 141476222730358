import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faCheckCircle, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, first, take } from 'rxjs/operators';
import { DayOfWeek } from 'src/app/enums/dayofweek.enum';
import { Profile } from 'src/app/models/profile.model';
import { User } from 'src/app/models/user.model';
import { WorkDay } from 'src/app/models/workday.model';
import { AuthenticationService, FileService, UserService } from 'src/app/_services';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  profile: Profile;
  updateProfileForm: FormGroup;
  isEditingProfileInformation: boolean = false;
  isEditingLoginInformation: boolean = false;
  isEditingWorkSchdule: boolean = false;
  isEditing: boolean = false;
  submitted: boolean = false;
  loading: boolean = false;
  faCheckCircle = faCheckCircle;
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private uiService: UiService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private fileService: FileService
  ) { }

  get f() { return this.updateProfileForm.controls; }

  ngOnInit() {

    this.updateProfileForm = this.formBuilder.group({
      username: [''],
      password: [''],
      confirmpassword: [''],
      firstname: ['', Validators.required],
      middlenames: [''],
      lastname: ['', Validators.required],
      email: [''],
      mobilenumber: [''],
      worknumber: [''],
      mondayEnabled: [false],
      startTimeMonday: [],
      endTimeMonday: [],
      tuesdayEnabled: [false],
      startTimeTuesday: [],
      endTimeTuesday: [],
      wednesdayEnabled: [false],
      startTimeWednesday: [],
      endTimeWednesday: [],
      thursdayEnabled: [false],
      startTimeThursday: [],
      endTimeThursday: [],
      fridayEnabled: [false],
      startTimeFriday: [],
      endTimeFriday: [],
      saturdayEnabled: [false],
      startTimeSaturday: [],
      endTimeSaturday: [],
      sundayEnabled: [false],
      startTimeSunday: [],
      endTimeSunday: []
    });

    this.initUser();
    this.subscribeEvents();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  subscribeEvents() {
    this.subscriptions.push(this.updateProfileForm.get('password').valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
      this.updatePasswordValidity(val);
    }));

    this.subscriptions.push(this.updateProfileForm.get('confirmpassword').valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
      this.updatePasswordValidity(val);
    }));

    // Monday
    this.subscriptions.push(this.updateProfileForm.get('mondayEnabled').valueChanges.subscribe(val => {
      if (val === true){
        this.updateProfileForm.controls['startTimeMonday'].setValidators([Validators.required]);
        this.updateProfileForm.controls['endTimeMonday'].setValidators([Validators.required]);
      }
      else{
        this.updateProfileForm.controls['startTimeMonday'].clearValidators();
        this.updateProfileForm.controls['endTimeMonday'].clearValidators();
      }
      
      this.updateProfileForm.controls['startTimeMonday'].updateValueAndValidity();
      this.updateProfileForm.controls['endTimeMonday'].updateValueAndValidity();
    }));

    // Tuesday
    this.subscriptions.push(this.updateProfileForm.get('tuesdayEnabled').valueChanges.subscribe(val => {
      if (val === true){
        this.updateProfileForm.controls['startTimeTuesday'].setValidators([Validators.required]);
        this.updateProfileForm.controls['endTimeTuesday'].setValidators([Validators.required]);
      }
      else{
        this.updateProfileForm.controls['startTimeTuesday'].clearValidators();
        this.updateProfileForm.controls['endTimeTuesday'].clearValidators();
      }
      
      this.updateProfileForm.controls['startTimeTuesday'].updateValueAndValidity();
      this.updateProfileForm.controls['endTimeTuesday'].updateValueAndValidity();
    }));

    // Wednesday
    this.subscriptions.push(this.updateProfileForm.get('wednesdayEnabled').valueChanges.subscribe(val => {
      if (val === true){
        this.updateProfileForm.controls['startTimeWednesday'].setValidators([Validators.required]);
        this.updateProfileForm.controls['endTimeWednesday'].setValidators([Validators.required]);
      }
      else{
        this.updateProfileForm.controls['startTimeWednesday'].clearValidators();
        this.updateProfileForm.controls['endTimeWednesday'].clearValidators();
      }
      
      this.updateProfileForm.controls['startTimeWednesday'].updateValueAndValidity();
      this.updateProfileForm.controls['endTimeWednesday'].updateValueAndValidity();
    }));

    // Thursday
    this.subscriptions.push(this.updateProfileForm.get('thursdayEnabled').valueChanges.subscribe(val => {
      if (val === true){
        this.updateProfileForm.controls['startTimeThursday'].setValidators([Validators.required]);
        this.updateProfileForm.controls['endTimeThursday'].setValidators([Validators.required]);
      }
      else{
        this.updateProfileForm.controls['startTimeThursday'].clearValidators();
        this.updateProfileForm.controls['endTimeThursday'].clearValidators();
      }
      
      this.updateProfileForm.controls['startTimeThursday'].updateValueAndValidity();
      this.updateProfileForm.controls['endTimeThursday'].updateValueAndValidity();
    }));

    // Friday
    this.subscriptions.push(this.updateProfileForm.get('fridayEnabled').valueChanges.subscribe(val => {
      if (val === true){
        this.updateProfileForm.controls['startTimeFriday'].setValidators([Validators.required]);
        this.updateProfileForm.controls['endTimeFriday'].setValidators([Validators.required]);
      }
      else{
        this.updateProfileForm.controls['startTimeFriday'].clearValidators();
        this.updateProfileForm.controls['endTimeFriday'].clearValidators();
      }
      
      this.updateProfileForm.controls['startTimeFriday'].updateValueAndValidity();
      this.updateProfileForm.controls['endTimeFriday'].updateValueAndValidity();
    }));

    // Saturday
    this.subscriptions.push(this.updateProfileForm.get('saturdayEnabled').valueChanges.subscribe(val => {
      if (val === true){
        this.updateProfileForm.controls['startTimeSaturday'].setValidators([Validators.required]);
        this.updateProfileForm.controls['endTimeSaturday'].setValidators([Validators.required]);
      }
      else{
        this.updateProfileForm.controls['startTimeSaturday'].clearValidators();
        this.updateProfileForm.controls['endTimeSaturday'].clearValidators();
      }
      
      this.updateProfileForm.controls['startTimeSaturday'].updateValueAndValidity();
      this.updateProfileForm.controls['endTimeSaturday'].updateValueAndValidity();
    }));

    // Sunday
    this.subscriptions.push(this.updateProfileForm.get('sundayEnabled').valueChanges.subscribe(val => {
      if (val === true){
        this.updateProfileForm.controls['startTimeSunday'].setValidators([Validators.required]);
        this.updateProfileForm.controls['endTimeSunday'].setValidators([Validators.required]);
      }
      else{
        this.updateProfileForm.controls['startTimeSunday'].clearValidators();
        this.updateProfileForm.controls['endTimeSunday'].clearValidators();
      }
      
      this.updateProfileForm.controls['startTimeSunday'].updateValueAndValidity();
      this.updateProfileForm.controls['endTimeSunday'].updateValueAndValidity();
    }));
  }

  private updatePasswordValidity(val: any) {
    if (val !== '') {
      this.updateProfileForm.controls['password'].setValidators([Validators.required]);
      this.updateProfileForm.controls['confirmpassword'].setValidators([Validators.required]);
    }
    else {
      this.updateProfileForm.controls['password'].clearValidators();
      this.updateProfileForm.controls['confirmpassword'].clearValidators();
    }

    this.updateProfileForm.controls['password'].updateValueAndValidity();
    this.updateProfileForm.controls['confirmpassword'].updateValueAndValidity();
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmpassword').value;

    return pass === confirmPass ? null : { notSame: true }
  }

  private async initUser() {
    (await this.userService.initProfile()).pipe(take(1)).subscribe(
      data => {
        if (data.success === false) {
          this.toastr.error(data.message, "Profile error");
        }
        else {

          this.profile = data.profile;

          this.updateProfileForm.controls['username'].setValue(this.profile.username);
          this.updateProfileForm.controls['firstname'].setValue(this.profile.firstname);
          this.updateProfileForm.controls['middlenames'].setValue(this.profile.middlenames);
          this.updateProfileForm.controls['lastname'].setValue(this.profile.lastname);
          this.updateProfileForm.controls['email'].setValue(this.profile.email);
          this.updateProfileForm.controls['mobilenumber'].setValue(this.profile.mobilenumber);
          this.updateProfileForm.controls['worknumber'].setValue(this.profile.worknumber);

          if (this.profile.mondayEnabled) {
            this.updateProfileForm.controls['mondayEnabled'].setValue(this.profile.mondayEnabled);
            this.updateProfileForm.controls['startTimeMonday'].setValue(this.profile.startTimeFormattedMonday);
            this.updateProfileForm.controls['endTimeMonday'].setValue(this.profile.endTimeFormattedMonday);
          }
          
          if (this.profile.tuesdayEnabled) {
            this.updateProfileForm.controls['tuesdayEnabled'].setValue(this.profile.tuesdayEnabled);
            this.updateProfileForm.controls['startTimeTuesday'].setValue(this.profile.startTimeFormattedTuesday);
            this.updateProfileForm.controls['endTimeTuesday'].setValue(this.profile.endTimeFormattedTuesday);
          }

          if (this.profile.wednesdayEnabled) {
            this.updateProfileForm.controls['wednesdayEnabled'].setValue(this.profile.wednesdayEnabled);
            this.updateProfileForm.controls['startTimeWednesday'].setValue(this.profile.startTimeFormattedWednesday);
            this.updateProfileForm.controls['endTimeWednesday'].setValue(this.profile.endTimeFormattedWednesday);
          }

          if (this.profile.thursdayEnabled) {
            this.updateProfileForm.controls['thursdayEnabled'].setValue(this.profile.thursdayEnabled);
            this.updateProfileForm.controls['startTimeThursday'].setValue(this.profile.startTimeFormattedThursday);
            this.updateProfileForm.controls['endTimeThursday'].setValue(this.profile.endTimeFormattedThursday);
          }

          if (this.profile.fridayEnabled) {
            this.updateProfileForm.controls['fridayEnabled'].setValue(this.profile.fridayEnabled);
            this.updateProfileForm.controls['startTimeFriday'].setValue(this.profile.startTimeFormattedFriday);
            this.updateProfileForm.controls['endTimeFriday'].setValue(this.profile.endTimeFormattedFriday);
          }

          if (this.profile.saturdayEnabled) {
            this.updateProfileForm.controls['saturdayEnabled'].setValue(this.profile.saturdayEnabled);
            this.updateProfileForm.controls['startTimeSaturday'].setValue(this.profile.startTimeFormattedSaturday);
            this.updateProfileForm.controls['endTimeSaturday'].setValue(this.profile.endTimeFormattedSaturday);
          }

          if (this.profile.sundayEnabled) {
            this.updateProfileForm.controls['sundayEnabled'].setValue(this.profile.sundayEnabled);
            this.updateProfileForm.controls['startTimeSunday'].setValue(this.profile.startTimeFormattedSunday);
            this.updateProfileForm.controls['endTimeSunday'].setValue(this.profile.endTimeFormattedSunday);
          }
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }

  async onDeleteProfile(){
    (await this.userService.deleteCurrentUser())
                  .pipe(first())
                  .subscribe(
                    data => {
                        if(data.success === false){
                          this.toastr.error(data.message, "Error");
                        } 
                        else{
                            this.toastr.success('Account deleted', 'Success');
                            this.authenticationService.removeUsersData();
                            this.router.navigate(['/login']);
                          }
                        },
                        error => {
                          this.uiService.loadingCompleteWithError();
                      });
  }

  onEditLoginInformation() {
    this.isEditingLoginInformation = true;
    this.isEditing = true;
  }

  onEditProfileInformation() {
    this.isEditingProfileInformation = true;
    this.isEditing = true;
  }

  onEditWorkSchdule() {
    this.isEditingWorkSchdule = true;
    this.isEditing = true;
  }

  onExportAccountData() {
    this.fileService.exportAccountData();
  }

  async onSaveProfile() {

    if (this.updateProfileForm.value.password !== this.updateProfileForm.value.confirmpassword){
      this.toastr.error("Passwords don't match", "Error");
      return;
    }

    this.submitted = true;

    if (this.updateProfileForm.valid) {
      var profile = new Profile();
      profile.firstname = this.updateProfileForm.value.firstname;
      profile.middlenames = this.updateProfileForm.value.middlenames;
      profile.lastname = this.updateProfileForm.value.lastname;
      profile.password = this.updateProfileForm.value.password;
      profile.email = this.updateProfileForm.value.email;
      profile.mobilenumber = this.updateProfileForm.value.mobilenumber;
      profile.worknumber = this.updateProfileForm.value.worknumber;
      profile.workdays = [];

      if (this.updateProfileForm.value.mondayEnabled) {
        var wdMonday = new WorkDay();
        wdMonday.dayOfWeek = DayOfWeek.Monday;
        wdMonday.startTimeHour = this.updateProfileForm.value.startTimeMonday.split(":")[0];
        wdMonday.startTimeMinute = this.updateProfileForm.value.startTimeMonday.split(":")[1];
        wdMonday.endTimeHour = this.updateProfileForm.value.endTimeMonday.split(":")[0];
        wdMonday.endTimeMinute = this.updateProfileForm.value.endTimeMonday.split(":")[1];
        profile.workdays.push(wdMonday);
      }

      if (this.updateProfileForm.value.tuesdayEnabled) {
        var wdTuesday = new WorkDay();
        wdTuesday.dayOfWeek = DayOfWeek.Tuesday;
        wdTuesday.startTimeHour = this.updateProfileForm.value.startTimeTuesday.split(":")[0];
        wdTuesday.startTimeMinute = this.updateProfileForm.value.startTimeTuesday.split(":")[1];
        wdTuesday.endTimeHour = this.updateProfileForm.value.endTimeTuesday.split(":")[0];
        wdTuesday.endTimeMinute = this.updateProfileForm.value.endTimeTuesday.split(":")[1];
        profile.workdays.push(wdTuesday);
      }

      if (this.updateProfileForm.value.wednesdayEnabled) {
        var wdWednesday = new WorkDay();
        wdWednesday.dayOfWeek = DayOfWeek.Wednesday;
        wdWednesday.startTimeHour = this.updateProfileForm.value.startTimeWednesday.split(":")[0];
        wdWednesday.startTimeMinute = this.updateProfileForm.value.startTimeWednesday.split(":")[1];
        wdWednesday.endTimeHour = this.updateProfileForm.value.endTimeWednesday.split(":")[0];
        wdWednesday.endTimeMinute = this.updateProfileForm.value.endTimeWednesday.split(":")[1];
        profile.workdays.push(wdWednesday);
      }

      if (this.updateProfileForm.value.thursdayEnabled) {
        var wdThursday = new WorkDay();
        wdThursday.dayOfWeek = DayOfWeek.Thursday;
        wdThursday.startTimeHour = this.updateProfileForm.value.startTimeThursday.split(":")[0];
        wdThursday.startTimeMinute = this.updateProfileForm.value.startTimeThursday.split(":")[1];
        wdThursday.endTimeHour = this.updateProfileForm.value.endTimeThursday.split(":")[0];
        wdThursday.endTimeMinute = this.updateProfileForm.value.endTimeThursday.split(":")[1];
        profile.workdays.push(wdThursday);
      }

      if (this.updateProfileForm.value.fridayEnabled) {
        var wdFriday = new WorkDay();
        wdFriday.dayOfWeek = DayOfWeek.Friday;
        wdFriday.startTimeHour = this.updateProfileForm.value.startTimeFriday.split(":")[0];
        wdFriday.startTimeMinute = this.updateProfileForm.value.startTimeFriday.split(":")[1];
        wdFriday.endTimeHour = this.updateProfileForm.value.endTimeFriday.split(":")[0];
        wdFriday.endTimeMinute = this.updateProfileForm.value.endTimeFriday.split(":")[1];
        profile.workdays.push(wdFriday);
      }

      if (this.updateProfileForm.value.saturdayEnabled) {
        var wdSaturday = new WorkDay();
        wdSaturday.dayOfWeek = DayOfWeek.Saturday;
        wdSaturday.startTimeHour = this.updateProfileForm.value.startTimeSaturday.split(":")[0];
        wdSaturday.startTimeMinute = this.updateProfileForm.value.startTimeSaturday.split(":")[1];
        wdSaturday.endTimeHour = this.updateProfileForm.value.endTimeSaturday.split(":")[0];
        wdSaturday.endTimeMinute = this.updateProfileForm.value.endTimeSaturday.split(":")[1];
        profile.workdays.push(wdSaturday);
      }

      if (this.updateProfileForm.value.sundayEnabled) {
        var wdSunday = new WorkDay();
        wdSunday.dayOfWeek = DayOfWeek.Sunday;
        wdSunday.startTimeHour = this.updateProfileForm.value.startTimeSunday.split(":")[0];
        wdSunday.startTimeMinute = this.updateProfileForm.value.startTimeSunday.split(":")[1];
        wdSunday.endTimeHour = this.updateProfileForm.value.endTimeSunday.split(":")[0];
        wdSunday.endTimeMinute = this.updateProfileForm.value.endTimeSunday.split(":")[1];
        profile.workdays.push(wdSunday);
      }

      this.loading = true;

      (await this.userService.editProfile(profile)).pipe(take(1)).subscribe(
        data => {
          this.loading = false;

          if (data.success === false) {
            this.toastr.error(data.message, "Update failure");
          } else {
            this.isEditingLoginInformation = false;
            this.isEditingProfileInformation = false;
            this.isEditingWorkSchdule = false;
            this.isEditing = false;
            this.initUser();
            this.toastr.success(data.message, "");
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
        }
      );
    }
  }
}
