import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { first } from 'rxjs/operators';

import { AuthenticationService, TaskService } from '../../_services';
import { MatTableDataSource, MatSort, MatPaginator, DateAdapter } from '@angular/material';
import { DialogService } from 'src/app/_services/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserRole } from 'src/app/enums/userRole.enum';
import { SCREEN_SIZE } from 'src/app/enums/screen-size.enum';
import { AssignmentFilter } from 'src/app/enums/assignmentfilter.enum';
import { Location } from 'src/app/models/location.model';
import * as moment from 'moment';
import { UiService } from 'src/app/_services/ui.service';
import { TaskListStateService } from 'src/app/state/task-list/task-list.service';
import { TaskListStateQuery } from 'src/app/state/task-list/task-list.query';
import { LanguageStateQuery } from 'src/app/state/language/language.query';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
//import { faCalendarAlt, IconDefinition  } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TaskListComponent implements OnInit {

  public role: UserRole;
  public size: SCREEN_SIZE;
  public selectedVal: string;
  public awaiting: boolean;
  public ongoing: boolean;
  public completed: boolean;

  public chosenStartDate: Date;
  public chosenEndDate: Date;

  model: NgbDateStruct;
  //faCalendarAlt: IconDefinition = faCalendarAlt;

  constructor(
    private authenticationService: AuthenticationService,
    private taskService: TaskService,
    private dateAdapter: DateAdapter<Date>,
    private dialogService: DialogService,
    private toastr: ToastrService,
    private router: Router,
    private uiService: UiService,
    private stateService: TaskListStateService,
    private stateQuery: TaskListStateQuery,
    private languageStateQuery: LanguageStateQuery
  ) 
  {
    this.dateAdapter.setLocale(this.languageStateQuery.getLocaleIdentifier());
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setScreenSize(window.innerWidth);
  }

  private setScreenSize(screensize: number) {
    if (screensize < 767) {
      this.size = SCREEN_SIZE.XS;
      this.displayedColumns = ['taskNumber', 'type', 'starttime', 'actions'];
    }
    else if (screensize < 991) {
      this.size = SCREEN_SIZE.SM;
      this.displayedColumns = ['taskNumber', 'type', 'customer', 'starttime', 'endtime', 'actions'];
    }
    else if (screensize < 1219) {
      this.size = SCREEN_SIZE.MD;
      this.displayedColumns = ['taskNumber', 'type', 'address', 'starttime', 'endtime', 'actions'];
    }
    else {
      this.size = SCREEN_SIZE.LG;
      this.displayedColumns = ['taskNumber', 'type', 'customer', 'address', 'starttime', 'endtime', 'state', 'actions'];
    }
  }

  listData: MatTableDataSource<any>;
  displayedColumns: string[] = ['taskNumber', 'type', 'customer', 'address', 'starttime', 'endtime', 'state', 'actions'];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  searchKey: string;

  ngOnInit() {
    this.role = this.authenticationService.getRole();
    this.setScreenSize(window.innerWidth);

    if (this.role == UserRole.User)
      this.loadUsersTasksForUser();
    else
      this.setFilters();
  }

  async onDateChanged(){
    this.stateService.updateStartDate(this.chosenStartDate);
    this.stateService.updateEndDate(this.chosenEndDate);

    if (this.selectedVal == "assigned") {
      await this.loadUsersTasks();
    }
    else if (this.selectedVal == "unassigned") {
      await this.loadUnassignedCompanyTasks();
    }
    else // all
    {
      await this.loadCompanyTasks();
    }
  }

  async setFilters() {
    // var assignedState = localStorage.getItem("assignedState");

    // // Set assignedState
    // if (assignedState == undefined || assignedState == null)
    //   this.selectedVal = 'all';
    // else
    //   this.selectedVal = assignedState;

    this.selectedVal = this.stateQuery.getSelectedTaskGroup();
    this.awaiting = this.stateQuery.isAwaitingChecked();
    this.ongoing = this.stateQuery.isOngoingChecked();
    this.completed = this.stateQuery.isCompletedChecked();
    this.chosenStartDate = this.stateQuery.getStartDate();
    this.chosenEndDate = this.stateQuery.getEndDate();
    
    // Load tasks
    if (this.selectedVal == "assigned") {
      await this.loadUsersTasks();
    }
    else if (this.selectedVal == "unassigned") {
      await this.loadUnassignedCompanyTasks();
    }
    else // all
    {
      await this.loadCompanyTasks();
    }
  }

  public async onValChange(val: string) {
    this.selectedVal = val;
    if (val == "assigned") {
      await this.loadUsersTasks();
    }
    else if (val == "unassigned") {
      await this.loadUnassignedCompanyTasks();
    }
    else // all
    {
      await this.loadCompanyTasks();
    }
    this.stateService.updateSelectedTaskGroup(val);
    //localStorage.setItem("assignedState", val);
  }

  public async onCheckChanged() {
    if (this.selectedVal == "assigned") {
      await this.loadUsersTasks();
    }
    else if (this.selectedVal == "unassigned") {
      await this.loadUnassignedCompanyTasks();
    }
    else // all
    {
      await this.loadCompanyTasks();
    }

    this.stateService.updateIsAwaitingChecked(this.awaiting);
    this.stateService.updateIsOngoingChecked(this.ongoing);
    this.stateService.updateIsCompletedChecked(this.completed);
  }

  onSearchClear() {
    this.searchKey = "";
    //localStorage.setItem("searchKey", "");
    this.applyFilter();
  }

  applyFilter() {
    //var trimmedSearchKey: string = this.searchKey.trim().toLowerCase();
    //this.listData.filter = trimmedSearchKey;
    //localStorage.setItem("searchKey", trimmedSearchKey);
  }

  private refreshTableInit(list: any) {
    if (list == null)
      list = [];

    let array = list.map(item => {
      
      if(item.location == null)
        item.location = new Location();

      return {
        id: item.id,
        taskNumber: item.taskNumber,
        name: item.name,
        type: item.typeName,
        customer: item.customerName,
        address: item.location.fullAddress,
        starttime: item.startAtFormattedDate + " " + item.startAtFormattedTime,
        starttimedate: new Date(item.startAt),
        endtime: item.completeAtFormattedDate + " " + item.completeAtFormattedTime,
        endtimedate: new Date(item.completeAt),
        state: item.stateName
      };
    });

    this.listData = new MatTableDataSource(array);
    this.listData.sort = this.sort;
    this.listData.paginator = this.paginator;
    this.listData.filterPredicate = (data, filter) => {
      return this.displayedColumns.some(ele => {
        return ele != 'actions' && data[ele].toString().toLowerCase().indexOf(filter) != -1;
      })
    };
    this.listData.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'starttime': {
          return item.starttimedate;
        }
        case 'endtime': {
          return item.endtimedate;
        }
        default: return item[property];
      }
    };
    this.applyFilter();
  }

  private async loadTasks() {
    if (this.role == UserRole.Admin)
      await this.loadCompanyTasks();
    if (this.role == UserRole.User)
      await this.loadUsersTasks();
  }

  private async loadUnassignedCompanyTasks() {

    var assignmentFilters: AssignmentFilter[] = [];

    if (this.awaiting == true)
      assignmentFilters.push(AssignmentFilter.AllNotStarted);
    if (this.ongoing == true)
      assignmentFilters.push(AssignmentFilter.AllOngoing);
    if (this.completed == true)
      assignmentFilters.push(AssignmentFilter.Closed);

    (await this.taskService.getAllUnassigned(assignmentFilters, this.chosenStartDate, this.chosenEndDate)).subscribe(
      list => {
        this.refreshTableInit(list);
      }
    );
  }

  private async loadCompanyTasks() {

    var assignmentFilters: AssignmentFilter[] = [];

    if (this.awaiting == true)
      assignmentFilters.push(AssignmentFilter.AllNotStarted);
    if (this.ongoing == true)
      assignmentFilters.push(AssignmentFilter.AllOngoing);
    if (this.completed == true)
      assignmentFilters.push(AssignmentFilter.Closed);

    (await this.taskService.getAll(assignmentFilters, this.chosenStartDate, this.chosenEndDate)).subscribe(
      list => {
        this.refreshTableInit(list);
      }
    );
  }

  private async loadUsersTasksForUser() {

    (await this.taskService.getUsersTasksForUser()).subscribe(
      list => {
        this.refreshTableInit(list);
      }
    );
  }

  private async loadUsersTasks() {

    var assignmentFilters: AssignmentFilter[] = [];

    if (this.awaiting == true)
      assignmentFilters.push(AssignmentFilter.AllNotStarted);
    if (this.ongoing == true)
      assignmentFilters.push(AssignmentFilter.AllOngoing);
    if (this.completed == true)
      assignmentFilters.push(AssignmentFilter.Closed);

    (await this.taskService.getUsersTasks(assignmentFilters, this.chosenStartDate, this.chosenEndDate)).subscribe(
      list => {
        this.refreshTableInit(list);
      }
    );
  }

  onShow(row) {
    let taskId: number = row.id;
    this.router.navigate(['/task/', taskId]);
  }

  onEdit(row) {
    let taskId: number = row.id;
    this.router.navigate(['/taskedit/', taskId]);
  }

  onDelete(row) {
    this.dialogService.openConfirmDialog('Are you sure to delete this task?')
      .afterClosed().subscribe(async res => {
        if (res) {
          let taskId: number = row.id;
          
          (await this.taskService.delete(taskId)).pipe(first()).subscribe(
            data => {
              if(data.success === false){
                this.toastr.error(data.message, "");
              } else{
                this.loadCompanyTasks();
                this.toastr.success(data.message, "");
              }
            },
            error => {
              this.uiService.loadingCompleteWithError();
          });
        }
      });
  }
}