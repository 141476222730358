import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UiService } from 'src/app/_services/ui.service';
import { SupportService } from 'src/app/_services';
import { SupportTicketCreateRequest } from 'src/app/models/supportTicketCreateRequest.model';

@Component({
  selector: 'app-supportticket-create',
  templateUrl: './supportticket-create.component.html',
  styles: []
})
export class SupportticketCreateComponent implements OnInit {
  public submitted: Boolean = false;
  createSupportTicketForm: FormGroup;
  supportTicketTypes = [];
  selectedSupportTicketTypeId: number;
  description: string;
  ticketNumber: string;

  constructor(
    private formBuilder: FormBuilder,
    private supportService: SupportService,
    private router: Router,
    private toastr: ToastrService,
    private uiService: UiService
  ) { }

  ngOnInit() {

    this.createSupportTicketForm = this.formBuilder.group({
      description: ['', Validators.required],
      supportTicketType: ['', Validators.required],
      contactEmail: ['', Validators.required]
    });

    this.loadViewModel();
  }

  get f() { return this.createSupportTicketForm.controls; }

  private async loadViewModel() {
    (await this.supportService.initCreateSupportTicket()).subscribe(
      data => {

        if (data.success === false) 
        {
          this.toastr.error(data.message);
          return;
        } 

        this.supportTicketTypes = data.supportTicketTypes;
        this.f.contactEmail.setValue(data.contactEmail);
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }

  onSupportTicketTypeChanged($event) {
    var typeId = $event.target.options[$event.target.options.selectedIndex].value;
    this.selectedSupportTicketTypeId = typeId;

    // Set description
    var type = this.supportTicketTypes.filter(x => x.id == typeId)[0];
    this.description = type.description;

  }

  /*
  onSubmit(){
    //supportTicketType
    alert("SelectedSupportTicketTypeId: " + this.f.supportTicketType.value);

    if(this.f.supportTicketType.value === undefined){
    }
  }
  */

  async onSubmit() {
    this.submitted = true;
    if (!this.createSupportTicketForm.valid)
      return;
    
    var ticket = new SupportTicketCreateRequest();
    ticket.contactEmail = this.f.contactEmail.value;
    ticket.description = this.f.description.value;
    ticket.supportTicketTypeId = this.f.supportTicketType.value;

    
    (await this.supportService.createSupportTicket(ticket)).subscribe(
      data => {
        if (data.success === false) {
          this.toastr.error(data.message, "");
        } else {
          this.ticketNumber = data.supportTicketNumber;
          this.createSupportTicketForm.reset();
          this.toastr.success(data.message, "");
          this.router.navigate(['/supportticket-list']); 
          //this.createSupportTicketForm.initializeFormGroup();
        }
        this.submitted = false;
      },
      error => {
        this.uiService.loadingCompleteWithError();
        this.submitted = false;
      });
  }

}
