import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DayOfWeek } from 'src/app/enums/dayofweek.enum';
import { UserCreateFromInviteRequest } from 'src/app/models/userCreateFromInviteRequest.model';
import { WorkDay } from 'src/app/models/workday.model';
import { AuthenticationService, UserService } from 'src/app/_services';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite.component.html',
  styles: []
})
export class UserInviteComponent implements OnInit, OnDestroy {
  createuserForm: FormGroup;
  externalKey: string;
  private subscriptions: Subscription[] = [];
  public validation = false;
  public validationText: string;
  public loading: Boolean = false;
  submitted = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private uiService: UiService,
    private userService: UserService,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.createuserForm = this.formBuilder.group({
      password: ['', Validators.required],
      firstname: ['', Validators.required],
      middlenames: [''],
      lastname: ['', Validators.required],
      mobilenumber: [''],
      worknumber: [''],
      mondayEnabled: [false],
      startTimeMonday: [],
      endTimeMonday: [],
      tuesdayEnabled: [false],
      startTimeTuesday: [],
      endTimeTuesday: [],
      wednesdayEnabled: [false],
      startTimeWednesday: [],
      endTimeWednesday: [],
      thursdayEnabled: [false],
      startTimeThursday: [],
      endTimeThursday: [],
      fridayEnabled: [false],
      startTimeFriday: [],
      endTimeFriday: [],
      saturdayEnabled: [false],
      startTimeSaturday: [],
      endTimeSaturday: [],
      sundayEnabled: [false],
      startTimeSunday: [],
      endTimeSunday: []
    });

    this.externalKey = await this.activatedRoute.snapshot.queryParamMap.get("q");

    this.uiService.loadingStart();
    this.authService.getUserInvite(this.externalKey)
      .pipe(first())
      .subscribe(
        data => {
          this.uiService.loadingComplete();
          if (data.success === false) {
            this.validation = false;
            this.toastr.error(data.message, "Create User Failed");
          }
          else {
            this.validation = true;
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
        });

    this.subscribeEvents();
  }

  get f() { return this.createuserForm.controls; }

  async onSubmit() {
    this.submitted = true;

    if(this.createuserForm.valid) {
      
      var user = new UserCreateFromInviteRequest();
      user.externalKey = this.externalKey;
      user.firstName = this.createuserForm.value.firstname;
      user.middlenames = this.createuserForm.value.middlenames;
      user.lastname = this.createuserForm.value.lastname;
      user.password = this.createuserForm.value.password;
      user.mobilenumber = this.createuserForm.value.mobilenumber;
      user.worknumber = this.createuserForm.value.worknumber;
      
      user.workdays = [];

      if (this.createuserForm.value.mondayEnabled) {
        var wdMonday = new WorkDay();
        wdMonday.dayOfWeek = DayOfWeek.Monday;
        wdMonday.startTimeHour = this.createuserForm.value.startTimeMonday.split(":")[0];
        wdMonday.startTimeMinute = this.createuserForm.value.startTimeMonday.split(":")[1];
        wdMonday.endTimeHour = this.createuserForm.value.endTimeMonday.split(":")[0];
        wdMonday.endTimeMinute = this.createuserForm.value.endTimeMonday.split(":")[1];
        user.workdays.push(wdMonday);
      }

      if (this.createuserForm.value.tuesdayEnabled) {
        var wdTuesday = new WorkDay();
        wdTuesday.dayOfWeek = DayOfWeek.Tuesday;
        wdTuesday.startTimeHour = this.createuserForm.value.startTimeTuesday.split(":")[0];
        wdTuesday.startTimeMinute = this.createuserForm.value.startTimeTuesday.split(":")[1];
        wdTuesday.endTimeHour = this.createuserForm.value.endTimeTuesday.split(":")[0];
        wdTuesday.endTimeMinute = this.createuserForm.value.endTimeTuesday.split(":")[1];
        user.workdays.push(wdTuesday);
      }

      if (this.createuserForm.value.wednesdayEnabled) {
        var wdWednesday = new WorkDay();
        wdWednesday.dayOfWeek = DayOfWeek.Wednesday;
        wdWednesday.startTimeHour = this.createuserForm.value.startTimeWednesday.split(":")[0];
        wdWednesday.startTimeMinute = this.createuserForm.value.startTimeWednesday.split(":")[1];
        wdWednesday.endTimeHour = this.createuserForm.value.endTimeWednesday.split(":")[0];
        wdWednesday.endTimeMinute = this.createuserForm.value.endTimeWednesday.split(":")[1];
        user.workdays.push(wdWednesday);
      }

      if (this.createuserForm.value.thursdayEnabled) {
        var wdThursday = new WorkDay();
        wdThursday.dayOfWeek = DayOfWeek.Thursday;
        wdThursday.startTimeHour = this.createuserForm.value.startTimeThursday.split(":")[0];
        wdThursday.startTimeMinute = this.createuserForm.value.startTimeThursday.split(":")[1];
        wdThursday.endTimeHour = this.createuserForm.value.endTimeThursday.split(":")[0];
        wdThursday.endTimeMinute = this.createuserForm.value.endTimeThursday.split(":")[1];
        user.workdays.push(wdThursday);
      }

      if (this.createuserForm.value.fridayEnabled) {
        var wdFriday = new WorkDay();
        wdFriday.dayOfWeek = DayOfWeek.Friday;
        wdFriday.startTimeHour = this.createuserForm.value.startTimeFriday.split(":")[0];
        wdFriday.startTimeMinute = this.createuserForm.value.startTimeFriday.split(":")[1];
        wdFriday.endTimeHour = this.createuserForm.value.endTimeFriday.split(":")[0];
        wdFriday.endTimeMinute = this.createuserForm.value.endTimeFriday.split(":")[1];
        user.workdays.push(wdFriday);
      }

      if (this.createuserForm.value.saturdayEnabled) {
        var wdSaturday = new WorkDay();
        wdSaturday.dayOfWeek = DayOfWeek.Saturday;
        wdSaturday.startTimeHour = this.createuserForm.value.startTimeSaturday.split(":")[0];
        wdSaturday.startTimeMinute = this.createuserForm.value.startTimeSaturday.split(":")[1];
        wdSaturday.endTimeHour = this.createuserForm.value.endTimeSaturday.split(":")[0];
        wdSaturday.endTimeMinute = this.createuserForm.value.endTimeSaturday.split(":")[1];
        user.workdays.push(wdSaturday);
      }

      if (this.createuserForm.value.sundayEnabled) {
        var wdSunday = new WorkDay();
        wdSunday.dayOfWeek = DayOfWeek.Sunday;
        wdSunday.startTimeHour = this.createuserForm.value.startTimeSunday.split(":")[0];
        wdSunday.startTimeMinute = this.createuserForm.value.startTimeSunday.split(":")[1];
        wdSunday.endTimeHour = this.createuserForm.value.endTimeSunday.split(":")[0];
        wdSunday.endTimeMinute = this.createuserForm.value.endTimeSunday.split(":")[1];
        user.workdays.push(wdSunday);
      }

      this.loading = true;

      (await this.userService.createFromInvite(user)).subscribe(
        data => {
          this.loading = false;
          
          if(data.success === false){
            this.toastr.error(data.message, "Create User Failed");
          } else{
            this.router.navigate(["/"]);
            this.toastr.success(data.message, "");
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
        });
      }
    }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  subscribeEvents() {

    // Monday
    this.subscriptions.push(this.createuserForm.get('mondayEnabled').valueChanges.subscribe(val => {
      console.log('valueChanges mondayEnabled');
      if (val === true) {
        this.createuserForm.controls['startTimeMonday'].setValidators([Validators.required]);
        this.createuserForm.controls['endTimeMonday'].setValidators([Validators.required]);
      }
      else {
        this.createuserForm.controls['startTimeMonday'].clearValidators();
        this.createuserForm.controls['endTimeMonday'].clearValidators();
      }

      this.createuserForm.controls['startTimeMonday'].updateValueAndValidity();
      this.createuserForm.controls['endTimeMonday'].updateValueAndValidity();
    }));

    // Tuesday
    this.subscriptions.push(this.createuserForm.get('tuesdayEnabled').valueChanges.subscribe(val => {
      if (val === true) {
        this.createuserForm.controls['startTimeTuesday'].setValidators([Validators.required]);
        this.createuserForm.controls['endTimeTuesday'].setValidators([Validators.required]);
      }
      else {
        this.createuserForm.controls['startTimeTuesday'].clearValidators();
        this.createuserForm.controls['endTimeTuesday'].clearValidators();
      }

      this.createuserForm.controls['startTimeTuesday'].updateValueAndValidity();
      this.createuserForm.controls['endTimeTuesday'].updateValueAndValidity();
    }));

    // Wednesday
    this.subscriptions.push(this.createuserForm.get('wednesdayEnabled').valueChanges.subscribe(val => {
      if (val === true) {
        this.createuserForm.controls['startTimeWednesday'].setValidators([Validators.required]);
        this.createuserForm.controls['endTimeWednesday'].setValidators([Validators.required]);
      }
      else {
        this.createuserForm.controls['startTimeWednesday'].clearValidators();
        this.createuserForm.controls['endTimeWednesday'].clearValidators();
      }

      this.createuserForm.controls['startTimeWednesday'].updateValueAndValidity();
      this.createuserForm.controls['endTimeWednesday'].updateValueAndValidity();
    }));

    // Thursday
    this.subscriptions.push(this.createuserForm.get('thursdayEnabled').valueChanges.subscribe(val => {
      if (val === true) {
        this.createuserForm.controls['startTimeThursday'].setValidators([Validators.required]);
        this.createuserForm.controls['endTimeThursday'].setValidators([Validators.required]);
      }
      else {
        this.createuserForm.controls['startTimeThursday'].clearValidators();
        this.createuserForm.controls['endTimeThursday'].clearValidators();
      }

      this.createuserForm.controls['startTimeThursday'].updateValueAndValidity();
      this.createuserForm.controls['endTimeThursday'].updateValueAndValidity();
    }));

    // Friday
    this.subscriptions.push(this.createuserForm.get('fridayEnabled').valueChanges.subscribe(val => {
      if (val === true) {
        this.createuserForm.controls['startTimeFriday'].setValidators([Validators.required]);
        this.createuserForm.controls['endTimeFriday'].setValidators([Validators.required]);
      }
      else {
        this.createuserForm.controls['startTimeFriday'].clearValidators();
        this.createuserForm.controls['endTimeFriday'].clearValidators();
      }

      this.createuserForm.controls['startTimeFriday'].updateValueAndValidity();
      this.createuserForm.controls['endTimeFriday'].updateValueAndValidity();
    }));

    // Saturday
    this.subscriptions.push(this.createuserForm.get('saturdayEnabled').valueChanges.subscribe(val => {
      if (val === true) {
        this.createuserForm.controls['startTimeSaturday'].setValidators([Validators.required]);
        this.createuserForm.controls['endTimeSaturday'].setValidators([Validators.required]);
      }
      else {
        this.createuserForm.controls['startTimeSaturday'].clearValidators();
        this.createuserForm.controls['endTimeSaturday'].clearValidators();
      }

      this.createuserForm.controls['startTimeSaturday'].updateValueAndValidity();
      this.createuserForm.controls['endTimeSaturday'].updateValueAndValidity();
    }));

    // Sunday
    this.subscriptions.push(this.createuserForm.get('sundayEnabled').valueChanges.subscribe(val => {
      if (val === true) {
        this.createuserForm.controls['startTimeSunday'].setValidators([Validators.required]);
        this.createuserForm.controls['endTimeSunday'].setValidators([Validators.required]);
      }
      else {
        this.createuserForm.controls['startTimeSunday'].clearValidators();
        this.createuserForm.controls['endTimeSunday'].clearValidators();
      }

      this.createuserForm.controls['startTimeSunday'].updateValueAndValidity();
      this.createuserForm.controls['endTimeSunday'].updateValueAndValidity();
    }));
  }

}
