import { ITask } from '../interfaces/task.interface';
import { IAssignmentStatus } from '../interfaces/assignmentStatus.interface';
import { ILocation } from '../interfaces/location.interface';
import { IUser } from '../interfaces/user.interface';
import { INote } from '../interfaces/note.interface';
import { Assignmentstatus } from '../enums/assignmentstatus.enum';
import { IAssignedUser } from '../interfaces/assignedUser.interface';
import { User } from './user.model';

export class Task implements ITask{
    createdAtFormattedDate: any;
    createdAtFormattedTime: any;
    timeUsedMinutes: any;
    timeUsedHours: any;
    completeAtFormattedTime: any;
    completeAtFormattedDate: any;
    startAtFormattedDate: any;
    startAtFormattedTime: any;
    
    id: number = 0;
    taskNumber: number = 0;
    customerId: number = 0;
    customerName: string = "";
    companyId: number = 0;
    typeId: number = 0;
    typeName: string = "";
    name: string = "";
    description: string = "";
    url: string = "";
    price: string = "";
    priceCurrency: string = "";
    createdBy: string = "";
    createdAt: any;
    startAt: any;
    startAtUTC: any;
    startEarliestAt: any;
    startEarliestAtUTC: any;
    completeAt: any;
    completeAtUTC: any;
    startYear: number;
    startMonth: number;
    startDay: number;
    startHour: number;
    startMinute: number;
    endYear: number;
    endMonth: number;
    endDay: number;
    endHour: number;
    endMinute: number;
    sameStartAndEndDay: boolean = false;
    timeUsed: any;
    stateName: string = "";
    stateTypeId: number;
    statusId: Assignmentstatus = Assignmentstatus.Created;
    assignmentStatuses: IAssignmentStatus[];
    location: ILocation;
    useCustomerLocation: boolean = false;
    assignedUserList: IAssignedUser[];
    assignedUsers: User[];
    assignedUsersAndTimeUsed: IUser[];
    notes: INote[];
}