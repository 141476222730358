import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UiService } from 'src/app/_services/ui.service';
import { CompanyService } from 'src/app/_services';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-more-space',
  templateUrl: './more-space.component.html',
  styleUrls: ['./more-space.component.css']
})
export class MoreSpaceComponent implements OnInit {
  public chosenGB: number;
  public email: string;
  public currency: string;
  public submitted: boolean;
  changeplanForm: FormGroup;
  loading = false;

  constructor(
    private companyService: CompanyService,
    private toastr: ToastrService,
    private uiService: UiService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.changeplanForm = this.formBuilder.group({
      email: [this.email, Validators.required]
    });
  }

  get f() { return this.changeplanForm.controls; }

  async OnConfirmed() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.email == "") {
      return;
    }

    /*
    (await this.companyService.editPricingPlan(this.chosenplan, this.email)).pipe(take(1))
      .subscribe(
        data => {
          if (data.success === false) {
            this.toastr.error(data.message, "");
          }
          else {
            this.toastr.success("Your order has been sent", "Order sent");
            document.getElementById('closeDialog').click();
          }
          this.loading = false;
        },
        error => {
          this.uiService.loadingCompleteWithError();
          this.loading = false;
        });
        */

    //this.loading = true;
  }

  OnSelectStorage(gb: number) {
    this.chosenGB = gb;
  }
}
