import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieConsentStateQuery } from '../cookie-consent/cookie-consent.query';
import { TaskstateConfigurationStateStore } from './taskstate-configuration.store';

@Injectable({
  providedIn: 'root'
})
export class TaskstateConfigurationStateService {

  constructor(
    private http: HttpClient,
    private taskstateConfigurationStateStore: TaskstateConfigurationStateStore,
    private cookieConsentQuery: CookieConsentStateQuery
  ) { }

  async updateSelectedTaskStateId(selectedTaskStateId: number) {
    if (this.cookieConsentQuery.isFunctionalCookiesAllowed()) {
      this.taskstateConfigurationStateStore.update({
        ...this.taskstateConfigurationStateStore.getValue(),
        selectedTaskStateId: selectedTaskStateId
      });
    }
  }

  async reset() {
    this.taskstateConfigurationStateStore.reset();
  }
}
