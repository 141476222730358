import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SupportService } from 'src/app/_services';
import { Router } from '@angular/router';
import { AuthenticationStateQuery } from 'src/app/state/authentication/authentication.query';

@Component({
  selector: 'app-supportticket-list',
  templateUrl: './supportticket-list.component.html',
  styleUrls: ['./supportticket-list.component.css']
})
export class SupportticketListComponent implements OnInit {

  constructor(
    public supportService: SupportService,
    private router: Router,
    private authQuery: AuthenticationStateQuery
  ) { }

  listData: MatTableDataSource<any>;
  displayedColumns: string[] = ['ticketNumber', 'type', 'dateCreated', 'state'];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  searchKey: string;

  ngOnInit() {
    if(this.authQuery.getUsersRole() === 'Master'){
      this.loadMasterViewModel();
    } else {
      this.loadViewModel();
    }

  }

  public async loadViewModel(){
    (await this.supportService.getAllByCompany()).subscribe(
      data => {
        this.refreshTableInit(data.supportTickets);
      }
    );
  }

  public async loadMasterViewModel(){
    (await this.supportService.getAll()).subscribe(
      data => {
        this.refreshTableInit(data.supportTickets);
      }
    );
  }

  private refreshTableInit(list: any) {
    if (list == null)
      list = [];

    let array = list.map(item => {
      return {
        id: item.id,
        ticketNumber: item.ticketNumber,
        type: item.type,
        dateCreated: new Date(item.dateCreated),
        dateCreatedFormattedDate: item.dateCreatedFormattedDate,
        dateCreatedFormattedTime: item.dateCreatedFormattedTime,
        state: item.state,
        isClosed: item.isClosed
      };
    });

    this.listData = new MatTableDataSource(array);
    
    this.listData.paginator = this.paginator;
    this.listData.filterPredicate = (data, filter) => {
      return this.displayedColumns.some(ele => {
        return ele != 'actions' && data[ele].toString().toLowerCase().indexOf(filter) != -1;
      })
    };
  }

  onShow(row){
    let SupportTicketId: number = row.id;
    this.router.navigate(['/supportticket/', SupportTicketId]); 
  }

}
