import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { CookieConsentState } from './cookie-consent.model';
import { CookieConsentStateStore, ICookieConsentState } from './cookie-consent.store';

export interface CookieConsentStateState extends CookieConsentState {} 

@Injectable({ providedIn: 'root' })
export class CookieConsentStateQuery extends Query<ICookieConsentState> {
  
  constructor(protected store: CookieConsentStateStore) {
    super(store);
  }

  isCookieConsentAccepted(): boolean{
    return this.store.getValue().isCookiesAccepted;
  }

  isFunctionalCookiesAllowed(): boolean{
    return this.store.getValue().isFunctionalCookiesAllowed;
  }

  isStatisticalCookiesAllowed(): boolean{
    return this.store.getValue().isStatisticalCookiesAllowed;
  }

  isMarketingCookiesAllowed(): boolean{
    return this.store.getValue().isMarketingCookiesAllowed;
  }

  getExternalKey(): string {
    return this.store.getValue().externalKey;
  }
}
