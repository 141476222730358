import { Component, OnInit } from '@angular/core';
import { Tasktype } from 'src/app/models/tasktype.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { TasktypeService } from 'src/app/_services/tasktype.service';
import { UiService } from 'src/app/_services/ui.service';
declare var $ : any;

@Component({
  selector: 'app-tasktype-list',
  templateUrl: './tasktype-list.component.html',
  styles: []
})
export class TasktypeListComponent implements OnInit {
  tasktypeId: number;
  tasktypes: Tasktype[] = [];
  loading: boolean = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private tasktypeService: TasktypeService,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.loadTaskTypes();
  }

  onCreate(){
    this.router.navigate(['/tasktype-create/']); 
  }

  onEdit(row: any){
    let id: number = row.assignmentTypeId;
    this.router.navigate(['/tasktype-edit/', id]); 
  }

  onPromptDelete(row: any){
    this.tasktypeId = row.assignmentTypeId;
    $('#deleteTaskTypeModal').modal('show');
  }

  async onDelete(){
    this.loading = true;
    $('#deleteTaskTypeModal').modal('hide');
    // Delete
    (await this.tasktypeService.delete(this.tasktypeId)).subscribe(
    data => {
      this.loading = false;

      if(data.success === false){
        this.toastr.error(data.message, "");
      } else{
        this.loadTaskTypes();
        this.toastr.success('Deleted successfully', 'Task Type');
      }
    },
    error => {
      this.uiService.loadingCompleteWithError();
    });
  }

  async loadTaskTypes(){
    (await this.tasktypeService.getAll()).subscribe(
      data => {
        if(this.tasktypeService.success === false){
          this.toastr.error(this.tasktypeService.message, "");
        } else{
          this.tasktypes = data;
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

}
