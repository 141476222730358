import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';

import { getFileNameFromResponseContentDisposition, saveFile } from '../_helpers/file-download-helper';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { AuthenticationStateQuery } from '../state/authentication/authentication.query';
import { map } from 'rxjs/internal/operators/map';

@Injectable()
export class FileService {
    
    constructor(
        private http: HttpClient, 
        private authService: AuthenticationService,
        private authQuery: AuthenticationStateQuery
    ) {}

    downloadFile(attachmentId: string, filename: string) {
        var parameters: string = "token=" + this.authQuery.getJwtToken() + "&" + "attachmentId="+attachmentId;
        var url = environment.baseUrl +"/Attachment/GetFileContent?" + parameters;

        this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
          saveAs(blob, filename, {
            type: 'application/octet-stream' // --> or whatever you need here
         });
      });
    }

    exportAccountData() {

        this.http.get(environment.baseUrl +"/User/ExportAccountData", { responseType: 'blob' }).subscribe(blob => {
          saveAs(blob, 'ExportedAccountData.csv', {
            type: 'application/octet-stream' // --> or whatever you need here
         });
      });
    }

    async exportCompanyData() {

      await this.authService.isExpired();

      return this.http.get(environment.baseUrl +"/Company/ExportCompanyData", { responseType: 'blob' })
        .pipe(map(data => {
          saveAs(data, 'AccountData.zip', {
            type: 'application/zip'
         });
            return true;
        }));
    }

    // async downloadFile2(attachmentId: string, filename: string) {

    //   await this.authService.isExpired();

    //   var body = {
    //     AttachmentId: attachmentId
    //   };

    //   this.http.post(environment.baseUrl + "/Attachment/GetFile", body, { responseType: 'blob' }).subscribe(blob => {
    //       saveAs(blob, filename, {
    //         type: 'application/octet-stream' // --> or whatever you need here
    //      });
    //   });
    // }
}