import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Customer } from '../models/customer.model';
import { AuthenticationService } from './authentication.service';
import { CustomerEditRequest } from '../models/customerEditRequest.model';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

  constructor(private http: HttpClient,
    private authService: AuthenticationService,
    private uiService: UiService) { }

  success: boolean;
  message: string;

  async get(id: number) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    var model = {
      CustomerId: id
    };

    return this.http.post<any>(environment.baseUrl + "/Customer/GetCustomer", model)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        return data;
      }));
  }

  async getAll() {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    return this.http.get<any>(environment.baseUrl + "/Customer/GetCustomersByCompany")
      .pipe(map(data => {
        this.uiService.loadingComplete();
        return data;
      }));
  }

  async create(customer: Customer) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    return this.http.post<any>(environment.baseUrl + "/Customer/CreateCustomer", customer)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        return data;
      }));
  }

  async edit(customer: CustomerEditRequest) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    return this.http.post<any>(environment.baseUrl + "/Customer/EditCustomer", customer)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        return data;
      }));
  }

  async delete(id: number) {

    this.uiService.loadingStart();
    await this.authService.isExpired();

    var model = {
      CustomerId: id
    };

    return this.http.post<any>(environment.baseUrl + "/Customer/DeleteCustomer", model)
      .pipe(map(model => {
        this.uiService.loadingComplete();
        return model;
      }));
  }

}
