import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { LanguageStateQuery } from 'src/app/state/language/language.query';

@Component({
  selector: 'app-timeregistration-create',
  templateUrl: './timeregistration-create.component.html',
  styles: []
})
export class TimeregistrationCreateComponent implements OnInit {
  public loading: Boolean = false;
  submitted = false;
  createtimeregistrationForm: FormGroup;

  public chosenStartDate: Date;
  public chosenEndDate: Date;

  twoLetterCountryCode: string;

  constructor(
    private formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    private router: Router,
    private toastr: ToastrService,
    private languageStateQuery: LanguageStateQuery
  ) {
    this.dateAdapter.setLocale(this.languageStateQuery.getLocaleIdentifier());
  }

  ngOnInit() {
    this.createtimeregistrationForm = this.formBuilder.group({
      startdate: ['', Validators.required],
      enddate: ['', Validators.required],
      startTimeHour: ['00', Validators.required],
      startTimeMinute: ['00', Validators.required],
      endTimeHour: ['00', Validators.required],
      endTimeMinute: ['00', Validators.required]
    });

    var startDate = moment().toDate();
    this.createtimeregistrationForm.controls['startdate'].setValue(startDate);
    this.chosenStartDate = startDate;

    var endDate = moment().toDate();
    this.createtimeregistrationForm.controls['enddate'].setValue(endDate);
    this.chosenEndDate = endDate;

    //this.loadViewModel();
  }

  get f() { return this.createtimeregistrationForm.controls; }

  /*
  private async loadViewModel() {
    (await this.taskService.initCreateTask()).subscribe(
      data => {

        if (data.success === false) {
          this.alertService.error(data.message);
        } else {

          // Init customers
          this.customers = data.customers;
          if (this.customers.length != 0) {
            this.defaultCustomer = data.customers[0].id + "";
          }

          // Init TaskTypes
          this.tasktypes = data.assignmentTypes;
          if (this.tasktypes.length != 0) {
            this.defaultType = data.assignmentTypes[0].assignmentTypeId + "";
          }

          // Init Users
          this.users = data.users;
          this.users.forEach((user, index, arr) => {
            var aUser = new AssignedUser();
            aUser.id = user.id;
            aUser.assigned = false;
            aUser.firstName = user.firstName;
            aUser.middleNames = user.middlenames;
            aUser.lastName = user.lastname;
            this.assignedUserList.push(aUser);
          });
        }
      },
      error => {
        this.alertService.error('Could not fetch data due to network issues. Please check if your are connected to the internet.');
      });
  }
  */
  
 async onSubmit() {
   
 }

  /*
  async onSubmit() {

    this.submitted = true;
    if (this.createtaskForm.valid) {

      var task = new Task();
      task.location = new Location();
      task.customerId = this.createtaskForm.value.customer;
      task.typeId = this.createtaskForm.value.type;
      task.description = this.createtaskForm.value.description;

      task.useCustomerLocation = this.createtaskForm.value.useCustomerLocation;
      task.location.cityName = this.location.cityName;
      task.location.sublocality = this.location.sublocality;
      task.location.administrativeArea = this.location.administrativeArea;
      task.location.country = this.location.country;
      task.location.countryId = this.location.countryId;
      task.location.latitude = this.location.latitude;
      task.location.longitude = this.location.longitude;
      task.location.regionName = this.location.regionName;
      task.location.streetName = this.location.streetName;
      task.location.streetNumber = this.location.streetNumber;
      task.location.zipCode = this.location.zipCode;
      task.location.fullAddress = this.location.fullAddress;

      this.chosenStartDate.setHours(+this.createtaskForm.value.startTimeHour);
      this.chosenStartDate.setMinutes(+this.createtaskForm.value.startTimeMinute);
      var chosenStartDateOffset = this.chosenStartDate.getTimezoneOffset();
      chosenStartDateOffset = Math.abs(chosenStartDateOffset / 60);
      this.chosenStartDate.setHours(this.chosenStartDate.getHours() + chosenStartDateOffset);
      task.startAt = this.chosenStartDate.toJSON();


      this.chosenEndDate.setHours(+this.createtaskForm.value.endTimeHour);
      this.chosenEndDate.setMinutes(+this.createtaskForm.value.endTimeMinute);
      var chosenEndDateOffset = this.chosenEndDate.getTimezoneOffset();
      chosenEndDateOffset = Math.abs(chosenEndDateOffset / 60);
      this.chosenEndDate.setHours(this.chosenEndDate.getHours() + chosenEndDateOffset);
      task.completeAt = this.chosenEndDate.toJSON();

      task.startYear = this.chosenStartDate.getUTCFullYear();
      task.startMonth = this.chosenStartDate.getUTCMonth() + 1;
      task.startDay = this.chosenStartDate.getUTCDate();
      task.startHour = this.chosenStartDate.getUTCHours();
      task.startMinute = this.chosenStartDate.getUTCMinutes();
      task.endYear = this.chosenEndDate.getUTCFullYear();
      task.endMonth = this.chosenEndDate.getUTCMonth() + 1;
      task.endDay = this.chosenEndDate.getUTCDate();
      task.endHour = this.chosenEndDate.getUTCHours();
      task.endMinute = this.chosenEndDate.getUTCMinutes();

      task.assignedUsers = [];

      this.selectedUserIds.forEach(userId => {
        var user = new User();
        user.id = userId;
        task.assignedUsers.push(user);
      });

      this.loading = true;
      (await this.taskService.create(task)).subscribe(
        data => {
          if (this.taskService.success === false) {
            this.toastr.error(this.taskService.message, "");
          } else {
            this.users = data;
            this.tasktypeService.form.reset();
            this.tasktypeService.initializeFormGroup();
            this.toastr.success(this.taskService.message, "");
            this.router.navigate(['/tasklist']);
          }
          this.loading = false;
        },
        error => {
          this.toastr.error('Could not fetch data due to network issues. Please check if your are connected to the internet.', "");
          this.loading = false;
        });
    }
  }
  */
}
