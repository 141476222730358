import { Component, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';

import { AuthenticationService, TaskService } from '../../_services';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { NotificationService } from 'src/app/_services/notification.service';
import { DialogService } from 'src/app/_services/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

export interface Food {
    value: string;
    viewValue: string;
}

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent implements OnInit {
    // currentUser: any;

    // foods: Food[] = [
    //     {value: 'steak-0', viewValue: 'Steak'},
    //     {value: 'pizza-1', viewValue: 'Pizza'},
    //     {value: 'tacos-2', viewValue: 'Tacos'}
    //   ];

    constructor(
        private authenticationService: AuthenticationService,
        private taskService: TaskService,
        private notificationService: NotificationService,
        private dialogService: DialogService,
        private toastr: ToastrService,
        private router: Router
    ) 
    
    {
        // this.currentUser = this.authenticationService.currentUserValue;
    }

    listData: MatTableDataSource<any>;
    displayedColumns: string[]  = ['id', 'type', 'customer', 'actions'];
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    searchKey: string;

    ngOnInit() {
        this.loadAllTasks();
    }

    onSearchClear(){
        this.searchKey = "";
        this.applyFilter();
    }

    applyFilter(){
        this.listData.filter = this.searchKey.trim().toLowerCase();
    }

    private loadAllTasks() {
        alert("uncomment code");
        /*
        this.taskService.getAll().subscribe(
            list => {
                let array = list.map(item => {
                    return {
                        id: item.id,
                        type: item.typeName,
                        customer: item.customerName
                    };
                });
                this.listData = new MatTableDataSource(array);
                this.listData.sort = this.sort;
                this.listData.paginator = this.paginator;
                this.listData.filterPredicate = (data, filter) => {
                    return this.displayedColumns.some(ele => {
                        return ele != 'actions' && data[ele].toString().toLowerCase().indexOf(filter) != -1;
                    })
                }
            }
        );
        */
    }

    onShow(row){
        let taskId: number = row.id;
        this.router.navigate(['/task/', taskId]); 
    }

    onEdit(row){
        let taskId: number = row.id;
        this.router.navigate(['/taskedit/', taskId]); 
    }

    onDelete(row) {
        this.dialogService.openConfirmDialog('Are you sure to delete this task?')
        .afterClosed().subscribe(async res => {
            if(res)
            {
                let taskId: number = row.id;
                (await this.taskService.delete(taskId))
                    .pipe(first())
                    .subscribe(() => this.loadAllTasks());
                //this.notificationService.warn('! Deleted successfully');
                this.toastr.success('Deleted successfully', 'Task');
            }
        });
    }
}