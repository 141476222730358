import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { TaskstateConfigurationState } from './taskstate-configuration.model';
import { ITaskstateConfigurationState, TaskstateConfigurationStateStore } from './taskstate-configuration.store';

export interface TaskstateConfigurationStateState extends TaskstateConfigurationState { }

@Injectable({ providedIn: 'root' })
export class TaskstateConfigurationStateQuery extends Query<ITaskstateConfigurationState> {

  constructor(protected store: TaskstateConfigurationStateStore) {
    super(store);
  }

  getSelectedTaskStateId(): number {
    return this.store.getValue().selectedTaskStateId;
  }
}
