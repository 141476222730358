import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Tasktype } from 'src/app/models/tasktype.model';
import { TaskStateService } from 'src/app/_services/taskstate.service';
import { TasktypeService } from 'src/app/_services/tasktype.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { TaskState } from 'src/app/models/taskState.model';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-taskstate-create',
  templateUrl: './taskstate-create.component.html',
  styleUrls: ['./taskstate-create.component.css']
})
export class TaskstateCreateComponent implements OnInit {

  createTaskStateForm: FormGroup;
  availableTasktypes: Tasktype[] = [];
  selectedTasktypes: Tasktype[] = [];
  submitted: boolean = false;
  loading = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private tasktypeService: TasktypeService,
    private taskstateService: TaskStateService,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.loadAllTaskstateAvailableTasktypes();

    this.createTaskStateForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  get f() { return this.createTaskStateForm.controls; }

  async onSubmit() {
    this.submitted = true;
    
    if(this.createTaskStateForm.valid) {
      this.loading = true;

      var taskState = new TaskState();
      taskState.name = this.createTaskStateForm.value.name;

      this.selectedTasktypes.forEach(tasktype => {
        taskState.tasktypes.push(tasktype);
      });

      (await this.taskstateService.create(taskState)).subscribe(
        data => {
          this.loading = false;
          if(data.success === false){
            this.toastr.error(data.message, "");
          } else{
            this.createTaskStateForm.reset();
            this.router.navigate(["/taskstate-list/"]);
            this.toastr.success(data.message, "");
          }
        },
        error => {
          alert(JSON.stringify(error));
          this.uiService.loadingCompleteWithError();
      });
    }
  }

  async loadAllTaskstateAvailableTasktypes(){
      (await this.tasktypeService.getAllTaskstateAvailableTasktypes()).subscribe(
        data => {
          if(this.tasktypeService.success === false){
            this.toastr.error(this.tasktypeService.message, "");
          } else{
            this.availableTasktypes = data;
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
      });
  }

  drop(event: CdkDragDrop<Tasktype[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

}
