import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CookieConsentStateQuery } from 'src/app/state/cookie-consent/cookie-consent.query';
import { CookieConsentStateService } from 'src/app/state/cookie-consent/cookie-consent.service';
import { CookieConsentEventService } from 'src/app/_services/cookie-consent-event.service';
import { CookieConsentService } from 'src/app/_services/cookie-consent.service';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styles: []
})
export class CookiePolicyComponent implements OnInit, OnDestroy {
  public onCookieConsentEvent$: Subscription;
  public cookieState: string;
  public cookieDate: string;
  public cookieKey: string;

  constructor(
    private cookieService: CookieConsentService,
    private cookieEventService: CookieConsentEventService,
    private cookieConsentStateQuery: CookieConsentStateQuery,
    private cookieConsentStateService: CookieConsentStateService
  ) {
    this.setCookieState();

    this.onCookieConsentEvent$ = cookieEventService.cookieConsentEvent$.subscribe(() => {
      this.onCookieConsentEvent();
    });
  }

  ngOnInit() {

  }

  private onCookieConsentEvent() {
    this.setCookieState();
  }

  private async setCookieState(): Promise<void> {
    //let externalKey = localStorage.getItem('accepted-cookies') as string;
    if (this.cookieConsentStateQuery.isCookieConsentAccepted()) {

      (await this.cookieService.getCookieConsent(this.cookieConsentStateQuery.getExternalKey())).pipe(take(1)).subscribe(
        data => {
          if (data.success) {
            this.cookieDate = data.formattedDate;
            this.cookieKey = data.externalKey;

            if (data.functionalAllowed && data.statisticalAllowed && data.marketingAllowed) {
              this.cookieState = "Allow all cookies.";
            }
            else {

              this.cookieState = "Necessary allowed. ";

              if (data.functionalAllowed) {
                this.cookieState += "Functional allowed. ";
              }

              if (data.statisticalAllowed) {
                this.cookieState += "Statistical allowed. ";
              }

              if (data.marketingAllowed) {
                this.cookieState += "Marketing allowed.";
              }
            }
          }
        },
        error => { }
      );
    }
    else {
      this.cookieState = 'Cookies not accepted.';
      this.cookieDate = '';
      this.cookieKey = '';
    }
  }

  withdrawConsent() {
    //localStorage.removeItem('accepted-cookies');
    this.cookieConsentStateService.withdrawConsent();
    this.setCookieState();
  }

  changeConsent() {
    //localStorage.removeItem('accepted-cookies');
    this.cookieConsentStateService.withdrawConsent();
    ($("#staticBackdrop") as any).modal('show');
  }

  ngOnDestroy(): void {
    this.onCookieConsentEvent$.unsubscribe();
  }
}
