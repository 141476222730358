import { Injectable } from '@angular/core';

@Injectable()
export class ApplicationStore {
    // getSelectedTaskStateId() {
    //     return localStorage.getItem('SelectedTaskStateId');
    // }

    // setSelectedTaskStateId(taskStateId: string) {
    //     localStorage.setItem('SelectedTaskStateId', taskStateId.toString());
    // }
}