import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { TaskListState } from './task-list.model';
import { ITaskListState, TaskListStateStore } from './task-list.store';

export interface TaskListStateState extends TaskListState { }

@Injectable({ providedIn: 'root' })
export class TaskListStateQuery extends Query<ITaskListState> {

  constructor(protected store: TaskListStateStore) {
    super(store);
  }

  isAwaitingChecked(): boolean {
    return this.store.getValue().isAwaitingChecked;
  }

  isOngoingChecked(): boolean {
    return this.store.getValue().isOngoingChecked;
  }

  isCompletedChecked(): boolean {
    return this.store.getValue().isCompletedChecked;
  }

  getSelectedTaskGroup(): string {
    return this.store.getValue().selectedTaskGroup;
  }

  getStartDate(): Date {
    return this.store.getValue().startDate;
  }

  getEndDate(): Date {
    return this.store.getValue().endDate;
  }
}
