import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { IUser } from '../interfaces/user.interface';
import { AuthenticationService } from './authentication.service';
import { UserLoginRequest } from '../models/userloginRequest.model';
import { User } from '../models/user.model';
import { UiService } from './ui.service';
import { SupportTicket } from '../models/supportTicket.model';

@Injectable({ providedIn: 'root' })
export class SupportService {
    
    constructor(
        private http: HttpClient, 
        private authService: AuthenticationService,
        private uiService: UiService) { }

    async createSupportticketUpdate(id: number, description: string) {
        this.uiService.loadingStart();
        await this.authService.isExpired();

        var model = {
            SupportTicketId: id,
            Description: description
        };

        return this.http.post<any>(environment.baseUrl +"/Support/CreateSupportticketUpdate", model)
        .pipe(map(data => {
            this.uiService.loadingComplete();
            return data;
        }));
    }

    async initCreateSupportTicket() {
        
        this.uiService.loadingStart();
        await this.authService.isExpired();

        return this.http.get<any>(environment.worktasksUrl +"/WTTasks/InitCreateSupportTicket")
        .pipe(map(data => {
            this.uiService.loadingComplete();
            return data;
        }));
    }

    async createSupportTicket(ticket: import("../models/supportTicketCreateRequest.model").SupportTicketCreateRequest) {
        
        this.uiService.loadingStart();
        await this.authService.isExpired();

        return this.http.post<any>(environment.baseUrl +"/Support/CreateSupportTicket", ticket)
        .pipe(map(data => {
            this.uiService.loadingComplete();
            return data;
        }));
    }

    async getAllByCompany() {
        
        this.uiService.loadingStart();
        await this.authService.isExpired();
        
        return this.http.get<any>(environment.baseUrl +"/Support/GetAllSupportTicketsByCompany")
        .pipe(map(data => {
            this.uiService.loadingComplete();
            return data;
        }));
    }

    async getAll() {
        
        this.uiService.loadingStart();
        await this.authService.isExpired();
        
        return this.http.get<any>(environment.baseUrl +"/Support/GetAllSupportTickets")
        .pipe(map(data => {
            this.uiService.loadingComplete();
            return data;
        }));
    }

    async getSupportTicket(id: number) {
        this.uiService.loadingStart();
        await this.authService.isExpired();
        
        var model = {
            SupportTicketId: id
        };

        return this.http.post<any>(environment.baseUrl +"/Support/GetSupportTicket", model)
        .pipe(map(data => {
            this.uiService.loadingComplete();
            return data;
        }));
    }

    async supportTicketUpdate(id: number, description: string, close: boolean) {
        this.uiService.loadingStart();
        await this.authService.isExpired();
        
        var model = {
            SupportTicketId: id,
            Description: description,
            Close: close
        };

        return this.http.post<any>(environment.baseUrl +"/Support/SupportTicketUpdate", model)
        .pipe(map(data => {
            this.uiService.loadingComplete();
            return data;
        }));
    }
}