import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { AuthGuard } from './_helpers';

import { TaskListComponent } from './pages/task-list/task-list.component';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TaskComponent } from './pages/task/task.component';
import { DataComponent } from './data/data.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { HomeComponent } from './pages/home/home.component';
import { CreatetaskComponent } from './pages/createtask/createtask.component';
import { TaskeditComponent } from "./pages/taskedit/taskedit.component";
import { GanttComponent } from './pages/gantt/gantt.component';
import { TestComponent } from './pages/test/test.component';
import { TaskstateListComponent } from './pages/taskstate-list/taskstate-list.component';
import { TaskstateCreateComponent } from './pages/taskstate-create/taskstate-create.component';
import { TaskstateUpdateComponent } from './pages/taskstate-update/taskstate-update.component';
import { TaskstateConfigurationComponent } from './pages/taskstate-configuration/taskstate-configuration.component';
import { TaskstatebuttonCreateComponent } from './pages/taskstatebutton-create/taskstatebutton-create.component';
import { TaskstatebuttonUpdateComponent } from './pages/taskstatebutton-update/taskstatebutton-update.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { UserCreateComponent } from './pages/user-create/user-create.component';
import { UserComponent } from './pages/user/user.component';
import { TasktypeListComponent } from './pages/tasktype-list/tasktype-list.component';
import { TasktypeCreateComponent } from './pages/tasktype-create/tasktype-create.component';
import { TasktypeEditComponent } from './pages/tasktype-edit/tasktype-edit.component';
import { CustomerCreateComponent } from './pages/customer-create/customer-create.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { CustomerEditComponent } from './pages/customer-edit/customer-edit.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { RedirectGuardService } from './_services/redirect-guard.service';
import { AuthGuardService } from './_services/auth-guard.service';
import { AuthGuard } from './_helpers/auth.guard';
import { TableOverviewExampleComponent } from './pages/table-overview-example/table-overview-example.component';
import { TimeregistrationListComponent } from './pages/timeregistration-list/timeregistration-list.component';
import { TimeregistrationCreateComponent } from './pages/timeregistration-create/timeregistration-create.component';
import { TimeregistrationUpdateComponent } from './pages/timeregistration-update/timeregistration-update.component';
import { MoreSpaceComponent } from './pages/more-space/more-space.component';
import { PlanUpdateComponent } from './pages/plan-update/plan-update.component';
import { SupportticketCreateComponent } from './pages/supportticket-create/supportticket-create.component';
import { SupportticketListComponent } from './pages/supportticket-list/supportticket-list.component';
import { SupportticketComponent } from './pages/supportticket/supportticket.component';
import { MasterDashboardComponent } from './pages/master-dashboard/master-dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { PrivacyPolicyMarketingComponent } from './pages/privacy-policy-marketing/privacy-policy-marketing.component';
import { PrivacyPolicyCustomersComponent } from './pages/privacy-policy-customers/privacy-policy-customers.component';
import { PrivacyPolicyEmployeesComponent } from './pages/privacy-policy-employees/privacy-policy-employees.component';
import { PrivacyPolicyProductsComponent } from './pages/privacy-policy-products/privacy-policy-products.component';
import { PrivacyPolicyThirdPartiesComponent } from './pages/privacy-policy-third-parties/privacy-policy-third-parties.component';
import { ConditionsComponent } from './pages/conditions/conditions.component';
import { UserCreateRequestComponent } from './pages/user-create-request/user-create-request.component';
import { UserInviteComponent } from './pages/user-invite/user-invite.component';

const routes: Routes = [
    { path: '', redirectTo: '', pathMatch: 'full', canActivate: [RedirectGuardService] },
    { path: 'master', component: MasterDashboardComponent, canActivate: [AuthGuardService], data: { role: 'master' } },
    { path: 'admin', component: DashboardComponent, canActivate: [AuthGuardService], data: { role: 'admin' } },
    { path: 'user', component: TaskListComponent, canActivate: [AuthGuardService], data: { role: 'user' } },

    { path: 'table-overview-example', component: TableOverviewExampleComponent, canActivate: [AuthGuard] },

    { path: 'gantt', component: GanttComponent, canActivate: [AuthGuard] },

    { path: 'tasktype-list', component: TasktypeListComponent, canActivate: [AuthGuard] },
    { path: 'tasktype-create', component: TasktypeCreateComponent, canActivate: [AuthGuard] },
    { path: 'tasktype-edit/:id', component: TasktypeEditComponent, canActivate: [AuthGuard] },

    { path: 'taskstate-configuration', component: TaskstateConfigurationComponent, canActivate: [AuthGuard] },
    { path: 'taskstate-list', component: TaskstateListComponent, canActivate: [AuthGuard] },
    { path: 'taskstate-create', component: TaskstateCreateComponent, canActivate: [AuthGuard] },
    { path: 'taskstate-update/:id', component: TaskstateUpdateComponent, canActivate: [AuthGuard] },

    { path: 'taskstatebutton-create/:id', component: TaskstatebuttonCreateComponent, canActivate: [AuthGuard] },
    { path: 'taskstatebutton-update/:id/:statetaskid', component: TaskstatebuttonUpdateComponent, canActivate: [AuthGuard] },

    { path: 'user/:id', component: UserComponent, canActivate: [AuthGuard] },
    { path: 'userlist', component: UserListComponent, canActivate: [AuthGuard] },
    { path: 'user-create', component: UserCreateComponent, canActivate: [AuthGuard] },
    { path: 'user-register', component: UserCreateRequestComponent, canActivate: [AuthGuard] },
    { path: 'user-invite', component: UserInviteComponent},
    
    { path: 'tasklist', component: TaskListComponent, canActivate: [AuthGuard] },
    { path: 'createtask', component: CreatetaskComponent, canActivate: [AuthGuard] },
    { path: 'task/:id', component: TaskComponent, canActivate: [AuthGuard] },
    { path: 'taskedit/:id', component: TaskeditComponent, canActivate: [AuthGuard] },

    { path: 'customer/:id/:create', component: CustomerComponent, canActivate: [AuthGuard] },
    { path: 'customer-list', component: CustomerListComponent, canActivate: [AuthGuard] },
    { path: 'customer-create', component: CustomerCreateComponent, canActivate: [AuthGuard] },
    { path: 'customer-edit/:id', component: CustomerEditComponent, canActivate: [AuthGuard] },

    { path: 'timeregistration-list', component: TimeregistrationListComponent, canActivate: [AuthGuard] },
    { path: 'timeregistration-create', component: TimeregistrationCreateComponent, canActivate: [AuthGuard] },
    { path: 'timeregistration-edit/:id', component: TimeregistrationUpdateComponent, canActivate: [AuthGuard] },

    { path: 'supportticket/:id', component: SupportticketComponent, canActivate: [AuthGuard] },
    { path: 'supportticket-create', component: SupportticketCreateComponent, canActivate: [AuthGuard] },
    { path: 'supportticket-list', component: SupportticketListComponent, canActivate: [AuthGuard] },

    { path: 'plan-update', component: PlanUpdateComponent, canActivate: [AuthGuard] },
    { path: 'morespace', component: MoreSpaceComponent, canActivate: [AuthGuard] },
    { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },

    { path: 'data', component: DataComponent, canActivate: [AuthGuard] },

    { path: 'master-dashboard', component: MasterDashboardComponent, canActivate: [AuthGuard] },

    { path: 'login', component: LoginComponent },
    { path: 'forgot', component: ForgotComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'privacy-policy-marketing', component: PrivacyPolicyMarketingComponent },
    { path: 'privacy-policy-customers', component: PrivacyPolicyCustomersComponent },
    { path: 'privacy-policy-employees', component: PrivacyPolicyEmployeesComponent },
    { path: 'privacy-policy-products', component: PrivacyPolicyProductsComponent },
    { path: 'privacy-policy-third-parties', component: PrivacyPolicyThirdPartiesComponent },
    { path: 'cookie-policy', component: CookiePolicyComponent },
    { path: 'conditions', component: ConditionsComponent },
    { path: 'forbidden', component: ForbiddenComponent },
    { path: 'test', component: TestComponent },
    { path: '**', redirectTo: '' }
    //{ path : '**' , component : NotFoundComponent } TODO: Use this instead
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule { }
export const routingComponents = [TaskListComponent, CustomerListComponent, UserListComponent]