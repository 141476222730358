import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Tasktype } from 'src/app/models/tasktype.model';
import { TaskStateService } from 'src/app/_services/taskstate.service';
import { TasktypeService } from 'src/app/_services/tasktype.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TaskState } from 'src/app/models/taskState.model';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-taskstate-update',
  templateUrl: './taskstate-update.component.html',
  styleUrls: ['./taskstate-update.component.css']
})
export class TaskstateUpdateComponent implements OnInit {
  taskStateId: number;
  taskState: TaskState;
  updateTaskStateForm: FormGroup;
  availableTasktypes: Tasktype[] = [];
  selectedTasktypes: Tasktype[] = [];
  submitted: boolean = false;
  loading: boolean = false;

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private tasktypeService: TasktypeService,
    private taskstateService: TaskStateService,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.taskStateId = +this.activatedRouter.snapshot.paramMap.get('id');
    this.initTaskState();

    //this.loadAllTaskstateAvailableTasktypes();
    //this.loadTaskstateTasktypes();
    //this.loadTaskstate();

    this.updateTaskStateForm = this.formBuilder.group({
        name: ['', Validators.required]
    });
  }

  get f() { return this.updateTaskStateForm.controls; }

  async onSubmit() {
    this.submitted = true;
    
    if(this.updateTaskStateForm.valid) {
      
      var taskState = new TaskState();
      taskState.name = this.updateTaskStateForm.value.name;
      taskState.id = this.taskStateId;

      this.selectedTasktypes.forEach(tasktype => {
        taskState.tasktypes.push(tasktype);
      });
      this.loading = true;
      
      (await this.taskstateService.update(taskState)).subscribe(
        data => {
          this.loading = false;

          if(this.taskstateService.success === false){
            this.toastr.error(this.taskstateService.message, "");
          } else{
            this.updateTaskStateForm.reset();
            this.router.navigate(["/taskstate-list/"]);
            this.toastr.success(this.taskstateService.message, "");
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
      });
    }
  }

  async initTaskState(){
    (await this.taskstateService.initTaskState(this.taskStateId)).subscribe(
      data => {
        if(this.taskstateService.success === false){
          this.toastr.error(this.taskstateService.message, "");
        } else{
          this.taskState = data.TaskState;
          this.selectedTasktypes = data.SelectedTaskTypes;
          this.availableTasktypes = data.AvaliableTaskTypes;
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

  // loadTaskstate(){
  //   this.taskstateService.get(this.taskStateId).subscribe(
  //     data => {
  //       if(this.taskstateService.success === false){
  //         this.toastr.error(this.taskstateService.message, "");
  //       } else{
  //         this.taskState = data;
  //       }
  //     },
  //     error => {
  //       this.uiService.loadingCompleteWithError();
  //   });
  // }

  async loadAllTaskstateAvailableTasktypes(){
    (await this.tasktypeService.getAllTaskstateAvailableTasktypes()).subscribe(
      data => {
        if(this.tasktypeService.success === false){
          this.toastr.error(this.tasktypeService.message, "");
        } else{
          this.availableTasktypes = data;
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

  loadTaskstateTasktypes(){
    this.tasktypeService.getTasktypesByTaskstate(this.taskStateId).subscribe(
      data => {
        if(this.tasktypeService.success === false){
          this.toastr.error(this.tasktypeService.message, "");
        } else{
          this.selectedTasktypes = data;
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

  drop(event: CdkDragDrop<Tasktype[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }
}
