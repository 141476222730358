import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { CompanyService, AuthenticationService, AlertService } from '../../_services';
import { RegisterCompany } from 'src/app/models/registerCompany.model';
import { UiService } from 'src/app/_services/ui.service';
import { ToastrService } from 'ngx-toastr';

@Component({ templateUrl: 'register.component.html' })
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    timezones = [];
    defaultTimeZone: string = 'Dateline Standard Time';
    selectedTimezone: string;
    countries = [];
    defaultCountry: string = '1';
    selectedCountry: string;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private companyService: CompanyService,
        private uiService: UiService,
        private alertService: AlertService,
        private toastr: ToastrService
    ) {
      
    }

    ngOnInit() {
      this.selectedTimezone = this.defaultTimeZone;
        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
            companyName: ['', Validators.required],
            timezone: [this.defaultTimeZone, Validators.required],
            country: [this.defaultCountry, Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
        this.timezones = this.getTimezones();
        this.countries = this.getCountries();
    }

    onTimeZoneChanged($event){
      this.selectedTimezone = $event.target.options[$event.target.options.selectedIndex].value;
    }

    onCountryChanged($event){
      this.selectedCountry = $event.target.options[$event.target.options.selectedIndex].value;
    }
    
    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }

        let registerFormValues = this.registerForm.value;
        let registerCompany: RegisterCompany = new RegisterCompany();
        registerCompany.companyName = registerFormValues.companyName;
        registerCompany.firstName = registerFormValues.firstName;
        registerCompany.middleNames = "";
        registerCompany.lastName = registerFormValues.lastName;
        registerCompany.email = registerFormValues.email;
        registerCompany.password = registerFormValues.password;
        registerCompany.timeZone = this.selectedTimezone;
        registerCompany.countryId = this.selectedCountry;

        this.loading = true;
        this.companyService.register(registerCompany)
            .pipe(first())
            .subscribe(
                data => {
                  if(data.success === false)
                  {
                    this.toastr.error(data.message, "");
                  }
                  else{
                    this.toastr.success('Registration Successful', "");
                    this.router.navigate(['/login'], { queryParams: { registered: true }});
                  }
                  this.loading = false;
                },
                error => {
                    this.loading = false;
                });
    }

    getTimezones() {
      return [
        { name: '(UTC-12:00) International Date Line West', value: 'Dateline Standard Time' },
        { name: '(UTC-11:00) Co-ordinated Universal Time-11', value: 'UTC-11' },
        { name: '(UTC-10:00) Aleutian Islands', value: 'Aleutian Standard Time' },
        { name: '(UTC-10:00) Hawaii', value: 'Hawaiian Standard Time' },
        { name: '(UTC-09:30) Marquesas Islands', value: 'Marquesas Standard Time' },
        { name: '(UTC-09:00) Alaska', value: 'Alaskan Standard Time' },
        { name: '(UTC-09:00) Co-ordinated Universal Time-09', value: 'UTC-09' },
        { name: '(UTC-08:00) Baja California', value: 'Pacific Standard Time (Mexico)' },
        { name: '(UTC-08:00) Co-ordinated Universal Time-08', value: 'UTC-08' },
        { name: '(UTC-08:00) Pacific Time (US & Canada)', value: 'Pacific Standard Time' },
        { name: '(UTC-07:00) Arizona', value: 'US Mountain Standard Time' },
        { name: '(UTC-07:00) Chihuahua, La Paz, Mazatlan', value: 'Mountain Standard Time (Mexico)' },
        { name: '(UTC-07:00) Mountain Time (US & Canada)', value: 'Mountain Standard Time' },
        { name: '(UTC-06:00) Central America', value: 'Central America Standard Time' },
        { name: '(UTC-06:00) Central Time (US & Canada)', value: 'Central Standard Time' },
        { name: '(UTC-06:00) Easter Island', value: 'Easter Island Standard Time' },
        { name: '(UTC-06:00) Guadalajara, Mexico City, Monterrey', value: 'Central Standard Time (Mexico)' },
        { name: '(UTC-06:00) Saskatchewan', value: 'Canada Central Standard Time' },
        { name: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco', value: 'SA Pacific Standard Time' },
        { name: '(UTC-05:00) Chetumal', value: 'Eastern Standard Time (Mexico)' },
        { name: '(UTC-05:00) Eastern Time (US & Canada)', value: 'Eastern Standard Time' },
        { name: '(UTC-05:00) Haiti', value: 'Haiti Standard Time' },
        { name: '(UTC-05:00) Havana', value: 'Cuba Standard Time' },
        { name: '(UTC-05:00) Indiana (East)', value: 'US Eastern Standard Time' },
        { name: '(UTC-05:00) Turks and Caicos', value: 'Turks And Caicos Standard Time' },
        { name: '(UTC-04:00) Asuncion', value: 'Paraguay Standard Time' },
        { name: '(UTC-04:00) Atlantic Time (Canada)', value: 'Atlantic Standard Time' },
        { name: '(UTC-04:00) Caracas', value: 'Venezuela Standard Time' },
        { name: '(UTC-04:00) Cuiaba', value: 'Central Brazilian Standard Time' },
        { name: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan', value: 'SA Western Standard Time' },
        { name: '(UTC-04:00) Santiago', value: 'Pacific SA Standard Time' },
        { name: '(UTC-03:30) Newfoundland', value: 'Newfoundland Standard Time' },
        { name: '(UTC-03:00) Araguaina', value: 'Tocantins Standard Time' },
        { name: '(UTC-03:00) Brasilia', value: 'E. South America Standard Time' },
        { name: '(UTC-03:00) Cayenne, Fortaleza', value: 'SA Eastern Standard Time' },
        { name: '(UTC-03:00) City of Buenos Aires', value: 'Argentina Standard Time' },
        { name: '(UTC-03:00) Greenland', value: 'Greenland Standard Time' },
        { name: '(UTC-03:00) Montevideo', value: 'Montevideo Standard Time' },
        { name: '(UTC-03:00) Punta Arenas', value: 'Magallanes Standard Time' },
        { name: '(UTC-03:00) Saint Pierre and Miquelon', value: 'Saint Pierre Standard Time' },
        { name: '(UTC-03:00) Salvador', value: 'Bahia Standard Time' },
        { name: '(UTC-02:00) Co-ordinated Universal Time-02', value: 'UTC-02' },
        { name: '(UTC-02:00) Mid-Atlantic - Old', value: 'Mid-Atlantic Standard Time' },
        { name: '(UTC-01:00) Azores', value: 'Azores Standard Time' },
        { name: '(UTC-01:00) Cabo Verde Is.', value: 'Cape Verde Standard Time' },
        { name: '(UTC) Co-ordinated Universal Time', value: 'UTC' },
        { name: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London', value: 'GMT Standard Time' },
        { name: '(UTC+00:00) Monrovia, Reykjavik', value: 'Greenwich Standard Time' },
        { name: '(UTC+00:00) Sao Tome', value: 'Sao Tome Standard Time' },
        { name: '(UTC+01:00) Casablanca', value: 'Morocco Standard Time' },
        { name: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', value: 'W. Europe Standard Time' },
        { name: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague', value: 'Central Europe Standard Time' },
        { name: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris', value: 'Romance Standard Time' },
        { name: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb', value: 'Central European Standard Time' },
        { name: '(UTC+01:00) West Central Africa', value: 'W. Central Africa Standard Time' },
        { name: '(UTC+02:00) Amman', value: 'Jordan Standard Time' },
        { name: '(UTC+02:00) Athens, Bucharest', value: 'GTB Standard Time' },
        { name: '(UTC+02:00) Beirut', value: 'Middle East Standard Time' },
        { name: '(UTC+02:00) Cairo', value: 'Egypt Standard Time' },
        { name: '(UTC+02:00) Chisinau', value: 'E. Europe Standard Time' },
        { name: '(UTC+02:00) Damascus', value: 'Syria Standard Time' },
        { name: '(UTC+02:00) Gaza, Hebron', value: 'West Bank Standard Time' },
        { name: '(UTC+02:00) Harare, Pretoria', value: 'South Africa Standard Time' },
        { name: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', value: 'FLE Standard Time' },
        { name: '(UTC+02:00) Jerusalem', value: 'Israel Standard Time' },
        { name: '(UTC+02:00) Kaliningrad', value: 'Kaliningrad Standard Time' },
        { name: '(UTC+02:00) Khartoum', value: 'Sudan Standard Time' },
        { name: '(UTC+02:00) Tripoli', value: 'Libya Standard Time' },
        { name: '(UTC+02:00) Windhoek', value: 'Namibia Standard Time' },
        { name: '(UTC+03:00) Baghdad', value: 'Arabic Standard Time' },
        { name: '(UTC+03:00) Istanbul', value: 'Turkey Standard Time' },
        { name: '(UTC+03:00) Kuwait, Riyadh', value: 'Arab Standard Time' },
        { name: '(UTC+03:00) Minsk', value: 'Belarus Standard Time' },
        { name: '(UTC+03:00) Moscow, St Petersburg', value: 'Russian Standard Time' },
        { name: '(UTC+03:00) Nairobi', value: 'E. Africa Standard Time' },
        { name: '(UTC+03:30) Tehran', value: 'Iran Standard Time' },
        { name: '(UTC+04:00) Abu Dhabi, Muscat', value: 'Arabian Standard Time' },
        { name: '(UTC+04:00) Astrakhan, Ulyanovsk', value: 'Astrakhan Standard Time' },
        { name: '(UTC+04:00) Baku', value: 'Azerbaijan Standard Time' },
        { name: '(UTC+04:00) Izhevsk, Samara', value: 'Russia Time Zone 3' },
        { name: '(UTC+04:00) Port Louis', value: 'Mauritius Standard Time' },
        { name: '(UTC+04:00) Saratov', value: 'Saratov Standard Time' },
        { name: '(UTC+04:00) Tbilisi', value: 'Georgian Standard Time' },
        { name: '(UTC+04:00) Volgograd', value: 'Volgograd Standard Time' },
        { name: '(UTC+04:00) Yerevan', value: 'Caucasus Standard Time' },
        { name: '(UTC+04:30) Kabul', value: 'Afghanistan Standard Time' },
        { name: '(UTC+05:00) Ashgabat, Tashkent', value: 'West Asia Standard Time' },
        { name: '(UTC+05:00) Ekaterinburg', value: 'Ekaterinburg Standard Time' },
        { name: '(UTC+05:00) Islamabad, Karachi', value: 'Pakistan Standard Time' },
        { name: '(UTC+05:00) Qyzylorda', value: 'Qyzylorda Standard Time' },
        { name: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: 'India Standard Time' },
        { name: '(UTC+05:30) Sri Jayawardenepura', value: 'Sri Lanka Standard Time' },
        { name: '(UTC+05:45) Kathmandu', value: 'Nepal Standard Time' },
        { name: '(UTC+06:00) Astana', value: 'Central Asia Standard Time' },
        { name: '(UTC+06:00) Dhaka', value: 'Bangladesh Standard Time' },
        { name: '(UTC+06:00) Omsk', value: 'Omsk Standard Time' },
        { name: '(UTC+06:30) Yangon (Rangoon)', value: 'Myanmar Standard Time' },
        { name: '(UTC+07:00) Bangkok, Hanoi, Jakarta', value: 'SE Asia Standard Time' },
        { name: '(UTC+07:00) Barnaul, Gorno-Altaysk', value: 'Altai Standard Time' },
        { name: '(UTC+07:00) Hovd', value: 'W. Mongolia Standard Time' },
        { name: '(UTC+07:00) Krasnoyarsk', value: 'North Asia Standard Time' },
        { name: '(UTC+07:00) Novosibirsk', value: 'N. Central Asia Standard Time' },
        { name: '(UTC+07:00) Tomsk', value: 'Tomsk Standard Time' },
        { name: '(UTC+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi', value: 'China Standard Time' },
        { name: '(UTC+08:00) Irkutsk', value: 'North Asia East Standard Time' },
        { name: '(UTC+08:00) Kuala Lumpur, Singapore', value: 'Singapore Standard Time' },
        { name: '(UTC+08:00) Perth', value: 'W. Australia Standard Time' },
        { name: '(UTC+08:00) Taipei', value: 'Taipei Standard Time' },
        { name: '(UTC+08:00) Ulaanbaatar', value: 'Ulaanbaatar Standard Time' },
        { name: '(UTC+08:45) Eucla', value: 'Aus Central W. Standard Time' },
        { name: '(UTC+09:00) Chita', value: 'Transbaikal Standard Time' },
        { name: '(UTC+09:00) Osaka, Sapporo, Tokyo', value: 'Tokyo Standard Time' },
        { name: '(UTC+09:00) Pyongyang', value: 'North Korea Standard Time' },
        { name: '(UTC+09:00) Seoul', value: 'Korea Standard Time' },
        { name: '(UTC+09:00) Yakutsk', value: 'Yakutsk Standard Time' },
        { name: '(UTC+09:30) Adelaide', value: 'Cen. Australia Standard Time' },
        { name: '(UTC+09:30) Darwin', value: 'AUS Central Standard Time' },
        { name: '(UTC+10:00) Brisbane', value: 'E. Australia Standard Time' },
        { name: '(UTC+10:00) Canberra, Melbourne, Sydney', value: 'AUS Eastern Standard Time' },
        { name: '(UTC+10:00) Guam, Port Moresby', value: 'West Pacific Standard Time' },
        { name: '(UTC+10:00) Hobart', value: 'Tasmania Standard Time' },
        { name: '(UTC+10:00) Vladivostok', value: 'Vladivostok Standard Time' },
        { name: '(UTC+10:30) Lord Howe Island', value: 'Lord Howe Standard Time' },
        { name: '(UTC+11:00) Bougainville Island', value: 'Bougainville Standard Time' },
        { name: '(UTC+11:00) Chokurdakh', value: 'Russia Time Zone 10' },
        { name: '(UTC+11:00) Magadan', value: 'Magadan Standard Time' },
        { name: '(UTC+11:00) Norfolk Island', value: 'Norfolk Standard Time' },
        { name: '(UTC+11:00) Sakhalin', value: 'Sakhalin Standard Time' },
        { name: '(UTC+11:00) Solomon Is., New Caledonia', value: 'Central Pacific Standard Time' },
        { name: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky', value: 'Russia Time Zone 11' },
        { name: '(UTC+12:00) Auckland, Wellington', value: 'New Zealand Standard Time' },
        { name: '(UTC+12:00) Co-ordinated Universal Time+12', value: 'UTC+12' },
        { name: '(UTC+12:00) Fiji', value: 'Fiji Standard Time' },
        { name: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old', value: 'Kamchatka Standard Time' },
        { name: '(UTC+12:45) Chatham Islands', value: 'Chatham Islands Standard Time' },
        { name: '(UTC+13:00) Co-ordinated Universal Time+13', value: 'UTC+13' },
        { name: '(UTC+13:00) Nukualofa', value: 'Tonga Standard Time' },
        { name: '(UTC+13:00) Samoa', value: 'Samoa Standard Time' },
        { name: '(UTC+14:00) Kiritimati Island', value: 'Line Islands Standard Time' }
      ];
    }

    getCountries() {
      return [
        { name: 'Afghanistan', value: '1' },
        { name: 'Aland Islands', value: '2' },
        { name: 'Albania', value: '3' },
        { name: 'Algeria', value: '4' },
        { name: 'American Samoa', value: '5' },
        { name: 'Andorra', value: '6' },
        { name: 'Angola', value: '7' },
        { name: 'Anguilla', value: '8' },
        { name: 'Antarctica', value: '9' },
        { name: 'Antigua and Barbuda', value: '10' },
        { name: 'Argentina', value: '11' },
        { name: 'Armenia', value: '12' },
        { name: 'Aruba', value: '13' },
        { name: 'Australia', value: '14' },
        { name: 'Austria', value: '15' },
        { name: 'Azerbaijan', value: '16' },
        { name: 'Bahamas', value: '17' },
        { name: 'Bahrain', value: '18' },
        { name: 'Bangladesh', value: '19' },
        { name: 'Barbados', value: '20' },
        { name: 'Belarus', value: '21' },
        { name: 'Belgium', value: '22' },
        { name: 'Belize', value: '23' },
        { name: 'Benin', value: '24' },
        { name: 'Bermuda', value: '25' },
        { name: 'Bhutan', value: '26' },
        { name: 'Bolivia', value: '27' },
        { name: 'Bonaire, Sint Eustatius and Saba', value: '28' },
        { name: 'Bosnia and Herzegovina', value: '29' },
        { name: 'Botswana', value: '30' },
        { name: 'Bouvet Island', value: '31' },
        { name: 'Brazil', value: '32' },
        { name: 'British Indian Ocean Territory', value: '33' },
        { name: 'Brunei', value: '34' },
        { name: 'Bulgaria', value: '35' },
        { name: 'Burkina Faso', value: '36' },
        { name: 'Burundi', value: '37' },
        { name: 'Cambodia', value: '38' },
        { name: 'Cameroon', value: '39' },
        { name: 'Canada', value: '40' },
        { name: 'Cape Verde', value: '41' },
        { name: 'Cayman Islands', value: '42' },
        { name: 'Central African Republic', value: '43' },
        { name: 'Chad', value: '44' },
        { name: 'Chile', value: '45' },
        { name: 'China', value: '46' },
        { name: 'Christmas Island', value: '47' },
        { name: 'Cocos(Keeling) Islands', value: '48' },
        { name: 'Colombia', value: '49' },
        { name: 'Comoros', value: '50' },
        { name: 'Congo', value: '51' },
        { name: 'Cook Islands', value: '52' },
        { name: 'Costa Rica', value: '53' },
        { name: 'Ivory Coast', value: '54' },
        { name: 'Croatia', value: '55' },
        { name: 'Cuba', value: '56' },
        { name: 'Curacao', value: '57' },
        { name: 'Cyprus', value: '58' },
        { name: 'Czech Republic', value: '59' },
        { name: 'Democratic Republic of the Congo', value: '60' },
        { name: 'Denmark', value: '61' },
        { name: 'Djibouti', value: '62' },
        { name: 'Dominica', value: '63' },
        { name: 'Dominican Republic', value: '64' },
        { name: 'Ecuador', value: '65' },
        { name: 'Egypt', value: '66' },
        { name: 'El Salvador', value: '67' },
        { name: 'Equatorial Guinea', value: '68' },
        { name: 'Eritrea', value: '69' },
        { name: 'Estonia', value: '70' },
        { name: 'Ethiopia', value: '71' },
        { name: 'Falkland Islands(Malvinas)', value: '72' },
        { name: 'Faroe Islands', value: '73' },
        { name: 'Fiji', value: '74' },
        { name: 'Finland', value: '75' },
        { name: 'France', value: '76' },
        { name: 'French Guiana', value: '77' },
        { name: 'French Polynesia', value: '78' },
        { name: 'French Southern Territories', value: '79' },
        { name: 'Gabon', value: '80' },
        { name: 'Gambia', value: '81' },
        { name: 'Georgia', value: '82' },
        { name: 'Germany', value: '83' },
        { name: 'Ghana', value: '84' },
        { name: 'Gibraltar', value: '85' },
        { name: 'Greece', value: '86' },
        { name: 'Greenland', value: '87' },
        { name: 'Grenada', value: '88' },
        { name: 'Guadaloupe', value: '89' },
        { name: 'Guam', value: '90' },
        { name: 'Guatemala', value: '91' },
        { name: 'Guernsey', value: '92' },
        { name: 'Guinea', value: '93' },
        { name: 'Guinea-Bissau', value: '94' },
        { name: 'Guyana', value: '95' },
        { name: 'Haiti', value: '96' },
        { name: 'Heard Island and McDonald Islands', value: '97' },
        { name: 'Honduras', value: '98' },
        { name: 'Hong Kong', value: '99' },
        { name: 'Hungary', value: '100' },
        { name: 'Iceland', value: '101' },
        { name: 'India', value: '102' },
        { name: 'Indonesia', value: '103' },
        { name: 'Iran', value: '104' },
        { name: 'Iraq', value: '105' },
        { name: 'Ireland', value: '106' },
        { name: 'Isle of Man', value: '107' },
        { name: 'Israel', value: '108' },
        { name: 'Italy', value: '109' },
        { name: 'Jamaica', value: '110' },
        { name: 'Japan', value: '111' },
        { name: 'Jersey', value: '112' },
        { name: 'Jordan', value: '113' },
        { name: 'Kazakhstan', value: '114' },
        { name: 'Kenya', value: '115' },
        { name: 'Kiribati', value: '116' },
        { name: 'Kosovo', value: '117' },
        { name: 'Kuwait', value: '118' },
        { name: 'Kyrgyzstan', value: '119' },
        { name: 'Laos', value: '120' },
        { name: 'Latvia', value: '121' },
        { name: 'Lebanon', value: '122' },
        { name: 'Lesotho', value: '123' },
        { name: 'Liberia', value: '124' },
        { name: 'Libya', value: '125' },
        { name: 'Liechtenstein', value: '126' },
        { name: 'Lithuania', value: '127' },
        { name: 'Luxembourg', value: '128' },
        { name: 'Macao', value: '129' },
        { name: 'Macedonia', value: '130' },
        { name: 'Madagascar', value: '131' },
        { name: 'Malawi', value: '132' },
        { name: 'Malaysia', value: '133' },
        { name: 'Maldives', value: '134' },
        { name: 'Mali', value: '135' },
        { name: 'Malta', value: '136' },
        { name: 'Marshall Islands', value: '137' },
        { name: 'Martinique', value: '138' },
        { name: 'Mauritania', value: '139' },
        { name: 'Mauritius', value: '140' },
        { name: 'Mayotte', value: '141' },
        { name: 'Mexico', value: '142' },
        { name: 'Micronesia', value: '143' },
        { name: 'Moldava', value: '144' },
        { name: 'Monaco', value: '145' },
        { name: 'Mongolia', value: '146' },
        { name: 'Montenegro', value: '147' },
        { name: 'Montserrat', value: '148' },
        { name: 'Morocco', value: '149' },
        { name: 'Mozambique', value: '150' },
        { name: 'Myanmar(Burma)', value: '151' },
        { name: 'Namibia', value: '152' },
        { name: 'Nauru', value: '153' },
        { name: 'Nepal', value: '154' },
        { name: 'Netherlands', value: '155' },
        { name: 'New Caledonia', value: '156' },
        { name: 'New Zealand', value: '157' },
        { name: 'Nicaragua', value: '158' },
        { name: 'Niger', value: '159' },
        { name: 'Nigeria', value: '160' },
        { name: 'Niue', value: '161' },
        { name: 'Norfolk Island', value: '162' },
        { name: 'North Korea', value: '163' },
        { name: 'Northern Mariana Islands', value: '164' },
        { name: 'Norway', value: '165' },
        { name: 'Oman', value: '166' },
        { name: 'Pakistan', value: '167' },
        { name: 'Palau', value: '168' },
        { name: 'Palestine', value: '169' },
        { name: 'Panama', value: '170' },
        { name: 'Papua New Guinea', value: '171' },
        { name: 'Paraguay', value: '172' },
        { name: 'Peru', value: '173' },
        { name: 'Phillipines', value: '174' },
        { name: 'Pitcairn', value: '175' },
        { name: 'Poland', value: '176' },
        { name: 'Portugal', value: '177' },
        { name: 'Puerto Rico', value: '178' },
        { name: 'Qatar', value: '179' },
        { name: 'Reunion', value: '180' },
        { name: 'Romania', value: '181' },
        { name: 'Russia', value: '182' },
        { name: 'Rwanda', value: '183' },
        { name: 'Saint Barthelemy', value: '184' },
        { name: 'Saint Helena', value: '185' },
        { name: 'Saint Kitts and Nevis', value: '186' },
        { name: 'Saint Lucia', value: '187' },
        { name: 'Saint Martin', value: '188' },
        { name: 'Saint Pierre and Miquelon', value: '189' },
        { name: 'Saint Vincent and the Grenadines', value: '190' },
        { name: 'Samoa', value: '191' },
        { name: 'San Marino', value: '192' },
        { name: 'Sao Tome and Principe', value: '193' },
        { name: 'Saudi Arabia', value: '194' },
        { name: 'Senegal', value: '195' },
        { name: 'Serbia', value: '196' },
        { name: 'Seychelles', value: '197' },
        { name: 'Sierra Leone', value: '198' },
        { name: 'Singapore', value: '199' },
        { name: 'Sint Maarten', value: '200' },
        { name: 'Slovakia', value: '201' },
        { name: 'Slovenia', value: '202' },
        { name: 'Solomon Islands', value: '203' },
        { name: 'Somalia', value: '204' },
        { name: 'South Africa', value: '205' },
        { name: 'South Georgia and the South Sandwich Islands', value: '206' },
        { name: 'South Korea', value: '207' },
        { name: 'South Sudan', value: '208' },
        { name: 'Spain', value: '209' },
        { name: 'Sri Lanka', value: '210' },
        { name: 'Sudan', value: '211' },
        { name: 'Suriname', value: '212' },
        { name: 'Svalbard and Jan Mayen', value: '213' },
        { name: 'Swaziland', value: '214' },
        { name: 'Sweden', value: '215' },
        { name: 'Switzerland', value: '216' },
        { name: 'Syria', value: '217' },
        { name: 'Taiwan', value: '218' },
        { name: 'Tajikistan', value: '219' },
        { name: 'Tanzania', value: '220' },
        { name: 'Thailand', value: '221' },
        { name: 'Timor-Leste(East Timor)', value: '222' },
        { name: 'Togo', value: '223' },
        { name: 'Tokelau', value: '224' },
        { name: 'Tonga', value: '225' },
        { name: 'Trinidad and Tobago', value: '226' },
        { name: 'Tunisia', value: '227' },
        { name: 'Turkey', value: '228' },
        { name: 'Turkmenistan', value: '229' },
        { name: 'Turks and Caicos Islands', value: '230' },
        { name: 'Tuvalu', value: '231' },
        { name: 'Uganda', value: '232' },
        { name: 'Ukraine', value: '233' },
        { name: 'United Arab Emirates', value: '234' },
        { name: 'United Kingdom', value: '235' },
        { name: 'United States', value: '236' },
        { name: 'United States Minor Outlying Islands', value: '237' },
        { name: 'Uruguay', value: '238' },
        { name: 'Uzbekistan', value: '239' },
        { name: 'Vanuatu', value: '240' },
        { name: 'Vatican City', value: '241' },
        { name: 'Venezuela', value: '242' },
        { name: 'Vietnam', value: '243' },
        { name: 'Virgin Islands, British', value: '244' },
        { name: 'Virgin Islands, US', value: '245' },
        { name: 'Wallis and Futuna', value: '246' },
        { name: 'Western Sahara', value: '247' },
        { name: 'Yemen', value: '248' },
        { name: 'Zambia', value: '249' },
        { name: 'Zimbabwe', value: '250' }
      ];
    }
}