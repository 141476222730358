import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationStateQuery } from '../state/authentication/authentication.query';

import { AuthenticationService } from '../_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private authenticationStateQuery: AuthenticationStateQuery
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(this.authenticationStateQuery.isLoggedIn()){
            let roles = route.data['permittedRoles'] as Array<string>;
            if(roles){
                if(this.authenticationService.roleMatch(roles)){
                    return true;
                } 
                  
              else{
                this.router.navigate(['/forbidden']);
                return false;
              }
            }
            return true;
        }
        
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;

    }
}