import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ITasktype } from '../interfaces/tasktype.interface';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Tasktype } from '../models/tasktype.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class TimeslotService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    constructor(private http: HttpClient, private authService: AuthenticationService) { }

    success: boolean;
    message: string;
    dateFormatted: string;

    async getTimeSlotsForCompanyByDate(ticks: any) {

        await this.authService.isExpired();

        var model = {
            Ticks: ticks,
            Desktop: true
        };

        return this.http.post<any>(environment.baseUrl +"/Task/GetTimeSlotsByCompany", model)
        .pipe(map(data => {
            this.success = data.success;
            this.message = data.message;
            this.dateFormatted = data.dateFormatted;
            return data.timeSlots;
        }));
    }
}