import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TaskStateService } from 'src/app/_services/taskstate.service';
import { ToastrService } from 'ngx-toastr';
import { TaskStateButton } from 'src/app/models/taskStateButton.model';
import { first } from 'rxjs/operators';
import { UiService } from 'src/app/_services/ui.service';
import { ApplicationStore } from 'src/app/_helpers/applicationStore';
import { TaskstateConfigurationStateQuery } from 'src/app/state/taskstate-configuration/taskstate-configuration.query';
declare var $ : any;

@Component({
  selector: 'app-taskstate',
  templateUrl: './taskstate-configuration.component.html',
  styleUrls: ['./taskstate-configuration.component.css']
})
export class TaskstateConfigurationComponent implements OnInit {
  taskStateId: number;
  taskStateButtonId: number;
  taskStateButtons: TaskStateButton[] = [];
  
  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private toastr: ToastrService,
    private taskStateService: TaskStateService,
    private uiService: UiService,
    private applicationStore: ApplicationStore,
    private taskstateQuery: TaskstateConfigurationStateQuery
  ) { }

  ngOnInit() {
    this.taskStateId = this.taskstateQuery.getSelectedTaskStateId();
    //this.taskStateId = +this.applicationStore.getSelectedTaskStateId();
    //this.taskStateId = +this.activatedRouter.snapshot.paramMap.get('id');
    this.loadAllTaskStateButtons();
  }

  onCreate(){
    this.router.navigate(['/taskstatebutton-create/', this.taskStateId]); 
  }

  onEdit(taskStateButtonId : number){
    this.router.navigate(['/taskstatebutton-update/', taskStateButtonId, this.taskStateId]);//{taskStateButtonId:this.taskStateButtonId,taskStateId:this.taskStateId}]);
  }

  onPromptDelete(taskStateButtonId : number){
    this.taskStateButtonId = taskStateButtonId;
    $('#deleteTaskStateButtonModal').modal('show');
  }

  async onDelete(){
    $('#deleteTaskStateButtonModal').modal('hide');
    // Delete
    (await this.taskStateService.deleteButton(this.taskStateButtonId))
      .pipe(first())
      .subscribe(() => {
        this.loadAllTaskStateButtons();
        this.toastr.success('Deleted successfully', 'Taskstate Button');
      });
  }

  async loadAllTaskStateButtons(){
    
    (await this.taskStateService.getAllTaskStatesButtons(this.taskStateId)).subscribe(
      data => {
        if(this.taskStateService.success === false){
          this.toastr.error(this.taskStateService.message, "");
        } else{
          this.taskStateButtons = data;
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

}