import { Component, OnInit, ViewChild, ElementRef, NgZone, TemplateRef } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { MatDialog, MatDialogConfig, DateAdapter } from "@angular/material";
import { TaskComponent } from '../task/task.component';
import { TasktypeComponent } from '../tasktype/tasktype.component';
import { TasktypeService } from 'src/app/_services/tasktype.service';
import { first } from 'rxjs/operators';
import { ITasktype } from 'src/app/interfaces/tasktype.interface';
import { ITask } from 'src/app/interfaces/task.interface';
import { AlertService, UserService, TaskService } from 'src/app/_services';
import { CustomerService } from 'src/app/_services/customer.service';
import { ILocation } from 'src/app/interfaces/location.interface';
import { Location } from 'src/app/models/location.model';
import { ICustomer } from 'src/app/interfaces/customer.interface';
import { CustomDateAdapter } from 'src/app/_adapters/custom-date-adapter';
import { Task } from 'src/app/models/task.model';
import { TaskListComponent } from '../task-list/task-list.component';
import { ToastPackage, ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user.model';
import { TouchSequence } from 'selenium-webdriver';
import { Router } from '@angular/router';
import { TimeslotListComponent } from '../timeslot-list/timeslot-list.component';
import * as moment from 'moment';
import { AssignedUser } from 'src/app/models/assignedUser.model';
import { UiService } from 'src/app/_services/ui.service';
import { HereService } from 'src/app/_services/here.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageStateQuery } from 'src/app/state/language/language.query';

@Component({
  selector: 'app-createtask',
  templateUrl: './createtask.component.html',
  styleUrls: ['./createtask.component.css']
})
export class CreatetaskComponent implements OnInit {
  @ViewChild('addressNotFoundModal', { static: false }) addressNotFoundModalContent: TemplateRef<any>;
  @ViewChild('confirmAddressModal', { static: false }) confirmAddressModalContent: TemplateRef<any>;
  @ViewChild('multipleAddressesFoundModal', { static: false }) multipleAddressesFoundModalContent: TemplateRef<any>;
  
  public loading: Boolean = false;
  submitted = false;
  manualDateAndTimeChecked: boolean = false;
  createtaskForm: FormGroup;
  tasktypes = [];
  defaultType: string;
  customers = [];
  defaultCustomer: string;
  //countries = [];
  //defaultCountry: string;
  //selectedCountry: string;

  location: Location = new Location();
  selectedCustomerId: number;
  //useCustomersAddressCheck: boolean = this.createtaskForm.value.useCustomerLocation;

  selectedTypeId: number;

  public chosenStartDate: Date;
  public chosenEndDate: Date;

  users = [];
  assignedUserList = [];
  selectedUserIds = [];

  private version: any;

  latitude: number;
  longitude: number;
  zoom: number;

  public address: string;
  
  foundAddresses: any[];
  foundAddress: string;

  selectedAddress: any;

  addressSelectedFromList = false;

  task: Task = new Task();

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private tasktypeService: TasktypeService,
    private alertService: AlertService,
    private customerService: CustomerService,
    private userService: UserService,
    private taskService: TaskService,
    private dateAdapter: DateAdapter<Date>,
    private mapsAPILoader: MapsAPILoader,
    private router: Router,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private here: HereService,
    private modalService: NgbModal,
    private uiService: UiService,
    private languageStateQuery: LanguageStateQuery
    ) 
    {
      this.dateAdapter.setLocale(this.languageStateQuery.getLocaleIdentifier());

    //var defaultCountry: string = localStorage.getItem('defaultCountry');
    //this.defaultCountry = defaultCountry;
    //this.location.countryId = +defaultCountry;

    //var defaultTwoLetterCountryCode: string = localStorage.getItem('defaultTwoLetterCountryCode');
    //this.twoLetterCountryCode = defaultTwoLetterCountryCode;
  }

  ngOnInit() {

    this.createtaskForm = this.formBuilder.group({
      description: [''],
      type: ['', Validators.required],
      customer: [this.defaultCustomer, Validators.required],
      startdate: ['', Validators.required],
      enddate: ['', Validators.required],
      starttime: ['', Validators.required],
      endtime: ['', Validators.required],
      address: [''],
      useCustomerLocation: [false, Validators.required]
    });

    var startDate = moment().toDate();
    this.createtaskForm.controls['startdate'].setValue(startDate);
    this.chosenStartDate = startDate;

    var endDate = moment().toDate();
    this.createtaskForm.controls['enddate'].setValue(endDate);
    this.chosenEndDate = endDate;

    this.loadViewModel();
  }

  ngAfterViewInit() {

  }

  get f() { return this.createtaskForm.controls; }

  get startDateUTC() {
    if (this.chosenStartDate) {
      return new Date(this.chosenStartDate.getUTCFullYear(),
        this.chosenStartDate.getUTCMonth(), this.chosenStartDate.getUTCDate(),
        this.chosenStartDate.getUTCHours(), this.chosenStartDate.getUTCMinutes(),
        this.chosenStartDate.getUTCSeconds());
    }
  }

  get endDateUTC() {
    if (this.chosenEndDate) {
      return new Date(this.chosenEndDate.getUTCFullYear(),
        this.chosenEndDate.getUTCMonth(), this.chosenEndDate.getUTCDate(),
        this.chosenEndDate.getUTCHours(), this.chosenEndDate.getUTCMinutes(),
        this.chosenEndDate.getUTCSeconds());
    }
  }

  get starttime() {
    return this.createtaskForm.get('starttime').value;
  }

  private async loadViewModel() {
    (await this.taskService.initCreateTask()).subscribe(
      data => {

        if (data.success === false) {
          this.alertService.error(data.message);
        } else {

          // Init customers
          this.customers = data.customers;
          if (this.customers.length != 0) {
            this.defaultCustomer = data.customers[0].id + "";
          }

          // Init TaskTypes
          this.tasktypes = data.assignmentTypes;
          if (this.tasktypes.length != 0) {
            this.defaultType = data.assignmentTypes[0].assignmentTypeId + "";
          }

          // Init Users
          this.users = data.users;
          this.users.forEach((user, index, arr) => {
            var aUser = new AssignedUser();
            aUser.id = user.id;
            aUser.assigned = false;
            aUser.firstName = user.firstName;
            aUser.middleNames = user.middlenames;
            aUser.lastName = user.lastname;
            this.assignedUserList.push(aUser);
          });
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }

  private async loadAllTasktypes() {
    (await this.tasktypeService.getAll()).subscribe(
      data => {
        if (this.tasktypeService.success === false) {
          this.alertService.error(this.tasktypeService.message);
        } else {
          this.tasktypes = data;
          this.defaultType = data[0].id + "";
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }

  private async loadAllCustomers() {
    (await this.customerService.getAll()).subscribe(
      data => {
        if (this.customerService.success === false) {
          this.alertService.error(this.customerService.message);
        } else {
          this.customers = data;
          this.defaultCustomer = data[0].id + "";
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }

  private async loadAllUsers() {
    (await this.userService.getAll()).subscribe(
      data => {
        if (this.userService.success === false) {
          this.alertService.error(this.userService.message);
        } else {
          this.users = data;

          this.users.forEach((user, index, arr) => {

            var aUser = new AssignedUser();
            aUser.id = user.id;
            aUser.assigned = false;
            aUser.firstName = user.firstName;
            aUser.middleNames = user.middlenames;
            aUser.lastName = user.lastname;
            this.assignedUserList.push(aUser);
          });

        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }

  OnCreateTaskType($event) {
    $event.preventDefault();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";

    this.dialog.open(TasktypeComponent, dialogConfig).afterClosed().subscribe(result => {
      this.loadAllTasktypes();
    });
  }

  OnPickTimeslot($event) {
    $event.preventDefault();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    const dialogRef = this.dialog.open(TimeslotListComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      //alert(JSON.stringify(dialogRef.componentInstance.selectedTimeslots));
      
      dialogRef.componentInstance.selectedTimeslots.forEach((timeslot, index, arr) => {

        var startHourIndex = +timeslot.fromDate.indexOf("T") + 1;
        this.createtaskForm.controls['starttime'].setValue(timeslot.fromDate.substring(startHourIndex));

        var endHourIndex = +timeslot.toDate.indexOf("T") + 1;
        this.createtaskForm.controls['endtime'].setValue(timeslot.toDate.substring(startHourIndex));

        var startDate = moment(timeslot.fromDate).toDate();
        this.createtaskForm.controls['startdate'].setValue(startDate);
        this.chosenStartDate = startDate;

        var endDate = moment(timeslot.toDate).toDate();
        this.createtaskForm.controls['enddate'].setValue(endDate);
        this.chosenEndDate = endDate;

        this.assignedUserList.forEach((user, index, arr) => {
          if (user.id === timeslot.userId) {
            user.assigned = true;
            this.selectedUserIds.push(user.id);
          }
        });
      });
    });
  }

  onUseCustomersAddressChanged() {
    var useCustomerLocation: boolean = this.createtaskForm.value.useCustomerLocation; 
    this.createtaskForm.controls['useCustomerLocation'].setValue(!useCustomerLocation);
    
    if (!useCustomerLocation) {
      this.setCustomersAddress(this.selectedCustomerId);
      this.f.address.disable();
    } else {
      this.clearCustomersAddress();
      this.f.address.enable();
    }
  }

  onTypeChanged($event) {
    var typeId = $event.target.options[$event.target.options.selectedIndex].value;
    this.selectedTypeId = typeId;
  }

  onCustomerChanged($event) {
    var customerId = $event.target.options[$event.target.options.selectedIndex].value;
    this.selectedCustomerId = customerId;
    this.setCustomersAddress(customerId);
  }

  setCustomersAddress(customerId: number) {
    var customer = this.customers.find(x => x.id == customerId);
    if (this.createtaskForm.value.useCustomerLocation) {
      this.location.streetName = customer.location.streetName;
      this.location.streetNumber = customer.location.streetNumber;
      this.location.regionName = customer.location.regionName;
      this.location.zipCode = customer.location.zipCode;
      this.location.cityName = customer.location.cityName;
      this.location.latitude = customer.location.latitude;
      this.location.longitude = customer.location.longitude;
      this.location.fullAddress = customer.location.fullAddress;
      this.latitude = +this.location.latitude;
      this.longitude = +this.location.longitude;
      this.createtaskForm.controls.address.setValue(customer.location.fullAddress);
    }
  }

  clearCustomersAddress() {
    this.location.streetName = '';
    this.location.streetNumber = '';
    this.location.regionName = '';
    this.location.zipCode = '';
    this.location.cityName = '';
    this.location.latitude = '';
    this.location.longitude = '';
    this.location.country = '';
    //this.location.countryId = +this.defaultCountry;
    this.createtaskForm.value.address = "";
    this.createtaskForm.value.useCustomerLocation = false;
  }

  onAssignedCheckboxChanged(userId) {
    const index = this.selectedUserIds.indexOf(userId, 0);
    if (index > -1) {
      this.selectedUserIds.splice(index, 1);
    } else {
      this.selectedUserIds.push(userId);
    }
  }

  async onSubmit() {

    this.submitted = true;
    if (this.createtaskForm.valid) {

      this.task.location = new Location();
      this.task.customerId = this.createtaskForm.value.customer;
      this.task.typeId = this.createtaskForm.value.type;
      this.task.description = this.createtaskForm.value.description;

      this.task.useCustomerLocation = this.createtaskForm.value.useCustomerLocation;
      // task.location.cityName = this.location.cityName;
      // task.location.sublocality = this.location.sublocality;
      // task.location.administrativeArea = this.location.administrativeArea;
      // task.location.country = this.location.country;
      // task.location.countryId = this.location.countryId;
      // task.location.latitude = this.location.latitude;
      // task.location.longitude = this.location.longitude;
      // task.location.regionName = this.location.regionName;
      // task.location.streetName = this.location.streetName;
      // task.location.streetNumber = this.location.streetNumber;
      // task.location.zipCode = this.location.zipCode;
      this.task.location = this.location;
      this.chosenStartDate.setHours(+this.createtaskForm.value.starttime.split(":")[0]);
      this.chosenStartDate.setMinutes(+this.createtaskForm.value.starttime.split(":")[1]);
      var chosenStartDateOffset = this.chosenStartDate.getTimezoneOffset();
      chosenStartDateOffset = Math.abs(chosenStartDateOffset / 60);
      this.chosenStartDate.setHours(this.chosenStartDate.getHours() + chosenStartDateOffset);
      this.task.startAt = this.chosenStartDate.toJSON();


      this.chosenEndDate.setHours(+this.createtaskForm.value.endtime.split(":")[0]);
      this.chosenEndDate.setMinutes(+this.createtaskForm.value.endtime.split(":")[1]);
      var chosenEndDateOffset = this.chosenEndDate.getTimezoneOffset();
      chosenEndDateOffset = Math.abs(chosenEndDateOffset / 60);
      this.chosenEndDate.setHours(this.chosenEndDate.getHours() + chosenEndDateOffset);
      this.task.completeAt = this.chosenEndDate.toJSON();

      this.task.startYear = this.chosenStartDate.getUTCFullYear();
      this.task.startMonth = this.chosenStartDate.getUTCMonth() + 1;
      this.task.startDay = this.chosenStartDate.getUTCDate();
      this.task.startHour = this.chosenStartDate.getUTCHours();
      this.task.startMinute = this.chosenStartDate.getUTCMinutes();
      this.task.endYear = this.chosenEndDate.getUTCFullYear();
      this.task.endMonth = this.chosenEndDate.getUTCMonth() + 1;
      this.task.endDay = this.chosenEndDate.getUTCDate();
      this.task.endHour = this.chosenEndDate.getUTCHours();
      this.task.endMinute = this.chosenEndDate.getUTCMinutes();

      this.task.assignedUsers = [];

      this.selectedUserIds.forEach(userId => {
        var user = new User();
        user.id = userId;
        this.task.assignedUsers.push(user);
      });

      if(this.createtaskForm.value.useCustomerLocation === true){
        this.Save();
      }
      else if (this.createtaskForm.value.address !== '') {
        // Address has a value

        const items: any = await this.here.getCustomerAddress(this.createtaskForm.value.address);

        if (items.length === 0)
          this.openAddressNotFoundModal();
        else if (items.length === 1)
          this.openConfirmAddressModal(items[0]);
        else
          this.openMultipleAddressesFoundModal(items);

        //Location loc = await this.here.convertAddress(customer.location);
      } else {
        this.Save();
      }
    }
  }

  openAddressNotFoundModal() {
    this.modalService.open(this.addressNotFoundModalContent).result.then((result) => { }, (reason) => { });
  }

  openConfirmAddressModal(item: any) {
    this.selectedAddress = item;
    this.foundAddress = item.address.label;
    this.modalService.open(this.confirmAddressModalContent).result.then((result) => {
      this.setSelectedAddress();
    }, (reason) => { });
  }

  openMultipleAddressesFoundModal(items: any[]) {

    this.foundAddresses = [];
    items.forEach((item) => {
      this.foundAddresses.push(item);
      //map.addObject(new H.map.Marker(item.position));
    });

    this.modalService.open(this.multipleAddressesFoundModalContent).result.then((result) => {
      this.setSelectedAddress();
      this.addressSelectedFromList = false;
    }, (reason) => {
      this.addressSelectedFromList = false;
     });
  }

  saveSeletedAddress(listIndex: number) {
    this.selectedAddress = this.foundAddresses[listIndex];
    this.addressSelectedFromList = true;
  }

  private setSelectedAddress() {

    this.task.location.fullAddress = this.selectedAddress.address.label;
    this.task.location.cityName = this.selectedAddress.address.city;
    this.task.location.country = this.selectedAddress.address.countryName;
    this.task.location.countryId = this.selectedAddress.address.countryCode;
    this.task.location.latitude = this.selectedAddress.position.lat;
    this.task.location.longitude = this.selectedAddress.position.lng;
    this.task.location.regionName = this.selectedAddress.address.state;
    this.task.location.streetName = this.selectedAddress.address.street;
    this.task.location.streetNumber = this.selectedAddress.address.houseNumber;
    this.task.location.zipCode = this.selectedAddress.address.postalCode;
    // console.log("county: " + this.selectedAddress.address.county);
    // console.log("district: " + this.selectedAddress.address.district);
    this.Save();
  }

  private async Save(){
    this.loading = true;
      (await this.taskService.create(this.task)).subscribe(
        data => {
          if (this.taskService.success === false) {
            this.toastr.error(this.taskService.message, "");
          } else {
            this.users = data;
            this.tasktypeService.form.reset();
            this.tasktypeService.initializeFormGroup();
            this.toastr.success(this.taskService.message, "");
            this.router.navigate(['/tasklist']);
          }
          this.loading = false;
        },
        error => {
          this.uiService.loadingCompleteWithError();
          this.loading = false;
        });
  }
}
