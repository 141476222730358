import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Tasktype } from 'src/app/models/tasktype.model';
import { TasktypeService } from 'src/app/_services/tasktype.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-tasktype-edit',
  templateUrl: './tasktype-edit.component.html',
  styles: []
})
export class TasktypeEditComponent implements OnInit {
  editTasktypeForm: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  id: number;
  tasktype: Tasktype;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private tasktypeService: TasktypeService,
    private activatedRouter: ActivatedRoute,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.id = +this.activatedRouter.snapshot.paramMap.get('id');

    this.editTasktypeForm = this.formBuilder.group({
      type: ['', Validators.required]
    });

    this.getTaskType();
  }

  private async getTaskType() {
    (await this.tasktypeService.get(this.id)).subscribe(
      data => {
        if(data.success === false){
          this.toastr.error(data.message, "Network error");
        } else{
          this.tasktype = data.assignmentType;
          this.editTasktypeForm.controls['type'].setValue(this.tasktype.type);
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

  get f() { return this.editTasktypeForm.controls; }

  async onSubmit() {
    
    this.submitted = true;
    if(this.editTasktypeForm.valid) {
      
      this.loading = true;

      (await this.tasktypeService.edit(this.id, this.editTasktypeForm.value.type)).subscribe(
        data => {
          this.loading = false;

          if(data.success === false){
            this.toastr.error(data.message, "");
          } else{
            this.editTasktypeForm.reset();
            this.router.navigate(["/tasktype-list/"]);
            this.toastr.success(data.message, "");
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
      });
    }
  }
}
