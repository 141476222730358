import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, DateAdapter } from "@angular/material";
import { TasktypeService } from 'src/app/_services/tasktype.service';
import { AlertService, UserService, TaskService } from 'src/app/_services';
import { CustomerService } from 'src/app/_services/customer.service';
import { Location } from 'src/app/models/location.model';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Customer } from 'src/app/models/customer.model';
import { CustomerEditRequest } from 'src/app/models/customerEditRequest.model';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.css']
})
export class CustomerEditComponent implements OnInit {

  public loading: Boolean = false;
  submitted = false;
  editCustomerForm: FormGroup;
  id: number;
  customer: Customer = new Customer();

  countries = [];
  defaultCountry: string;
  selectedCountry: string;
  locationId: number;

  private defaultLCID: string = 'en-GB';

  latitude: number;
  longitude: number;
  zoom: number;

  private geoCoder;

  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;

  autocomplete: any;

  twoLetterCountryCode: string;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private tasktypeService: TasktypeService,
    private alertService: AlertService,
    private customerService: CustomerService,
    private userService: UserService,
    private taskService: TaskService,
    private dateAdapter: DateAdapter<Date>,
    private mapsAPILoader: MapsAPILoader,
    private uiService: UiService,
    private router: Router,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private activatedRouter: ActivatedRoute) {
    //var defaultTwoLetterCountryCode: string = localStorage.getItem('defaultTwoLetterCountryCode');
    //this.twoLetterCountryCode = defaultTwoLetterCountryCode;
  }

  ngOnInit() {
    this.id = +this.activatedRouter.snapshot.paramMap.get('id');

    this.editCustomerForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      email: [''],
      phonenumber: [''],
      url: [''],
      country: [''],
      fullAddress: ['']
    });
    this.getCustomer();
  }

  ngAfterViewInit(){
    
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.setupAutocomplete();
    });
  }

  private async getCustomer() {
    (await this.customerService.get(this.id)).subscribe(
      data => {
        if (data.success === false) {
          this.toastr.error(data.message, "Network error");
        } else {

          this.customer.location = new Location();
          this.customer = data.customer;
          this.customer.location = data.customer.location;
          this.latitude = +this.customer.location.latitude;
          this.longitude = +this.customer.location.longitude;
          this.zoom = 12;
          this.editCustomerForm.controls['fullAddress'].setValue(this.customer.location.fullAddress);
          this.editCustomerForm.controls['name'].setValue(this.customer.name);
          this.editCustomerForm.controls['description'].setValue(this.customer.description);
          this.editCustomerForm.controls['email'].setValue(this.customer.email);
          this.editCustomerForm.controls['phonenumber'].setValue(this.customer.phoneNumber);
          this.editCustomerForm.controls['url'].setValue(this.customer.url);
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }

  get f() { return this.editCustomerForm.controls; }

  private getTwoLetterCountryCodeFromCountryId(countryId: number) {
    return this.getCountries()[countryId - 1].twoLetterCountryCode;
  }

  onCountryChanged() {
    this.clearAddress();
    this.twoLetterCountryCode = this.getTwoLetterCountryCodeFromCountryId(this.customer.location.countryId);
    this.setupAutocomplete();
  }

  private clearAddress() {
    this.customer.location.cityName = "";
    this.customer.location.fullAddress = "";
    this.customer.location.latitude = "";
    this.customer.location.longitude = "";
    this.customer.location.regionName = "";
    this.customer.location.streetName = "";
    this.customer.location.streetNumber = "";
    this.customer.location.zipCode = "";
    this.editCustomerForm.value.address = "";
  }

  private setupAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
      componentRestrictions: { country: this.twoLetterCountryCode }
    });

    this.autocomplete.addListener("place_changed", () => {
      this.searchAddress();
    });
  }

  private searchAddress() {
    this.ngZone.run(() => {
      //get the place result
      let place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
      //verify result
      this.getAddressFromGoogleMapsObject(place, true);
      if (place.geometry === undefined || place.geometry === null) {
        return;
      }
      //set latitude, longitude and zoom
      this.customer.location.latitude = place.geometry.location.lat() + "";
      this.customer.location.longitude = place.geometry.location.lng() + "";
      this.zoom = 12;
    });
  }

  private getAddressFromGoogleMapsObject(place: google.maps.places.PlaceResult, useNewCoordinates: Boolean) {
    for (var i = 0; i < place.address_components.length; i++) {
      var typeName = place.address_components[i]["types"][0];

      if (typeName == "street_number") {
        this.customer.location.streetNumber = place.address_components[i].long_name;
      }
      if (typeName == "route") {
        this.customer.location.streetName = place.address_components[i].long_name;
      }
      if (typeName == "postal_code") {
        this.customer.location.zipCode = place.address_components[i].long_name;
      }
      if (typeName == "country") {
        this.customer.location.country = place.address_components[i].long_name;
        //countryShortname = place.address_components[i].short_name;
      }
      if (typeName == "locality") {
        this.customer.location.cityName = place.address_components[i].long_name;
      }
      if (typeName == "political") {
        //alert(place.address_components[i].long_name);
      }
    }

    this.customer.location.fullAddress = place.formatted_address;

    if (useNewCoordinates === true) {
      this.customer.location.latitude = place.geometry.location.lat().toString();
      this.customer.location.longitude = place.geometry.location.lng().toString();
    }
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.customer.location.latitude = $event.coords.lat.toString();
    this.customer.location.longitude = $event.coords.lng.toString();
    this.customer.location.cityName = "";
    this.customer.location.regionName = "";
    this.customer.location.streetName = "";
    this.customer.location.zipCode = "";
    this.customer.location.streetNumber = "";

    this.getAddress(this.customer.location.latitude, this.customer.location.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.customer.location.fullAddress = results[0].formatted_address;
          this.getAddressFromGoogleMapsObject(results[0], false);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  async onSubmit() {
    this.submitted = true;
    if (this.editCustomerForm.valid) {

      var customer = new CustomerEditRequest();
      customer.id = this.id;
      customer.name = this.editCustomerForm.value.name;
      customer.description = this.editCustomerForm.value.description;
      customer.email = this.editCustomerForm.value.email;
      customer.phoneNumber = this.editCustomerForm.value.phonenumber;
      customer.url = this.editCustomerForm.value.url;
      customer.location = this.customer.location;
      this.loading = true;

      (await this.customerService.edit(customer)).subscribe(
        data => {
          if (data.success === false) {
            this.loading = false;
            this.toastr.error(data.message, "Update Customer Failed");
          } else {
            this.router.navigate(["/home/"]);
            this.toastr.success(data.message, "");
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
        });
    }
  }

  getCountries() {
    return [
      { name: 'Afghanistan', value: '1', twoLetterCountryCode: 'AF' },
      { name: 'Aland Islands', value: '2', twoLetterCountryCode: 'AX' },
      { name: 'Albania', value: '3', twoLetterCountryCode: 'AL' },
      { name: 'Algeria', value: '4', twoLetterCountryCode: 'DZ' },
      { name: 'American Samoa', value: '5', twoLetterCountryCode: 'AS' },
      { name: 'Andorra', value: '6', twoLetterCountryCode: 'AD' },
      { name: 'Angola', value: '7', twoLetterCountryCode: 'AO' },
      { name: 'Anguilla', value: '8', twoLetterCountryCode: 'AI' },
      { name: 'Antarctica', value: '9', twoLetterCountryCode: 'AQ' },
      { name: 'Antigua and Barbuda', value: '10', twoLetterCountryCode: 'AG' },
      { name: 'Argentina', value: '11', twoLetterCountryCode: 'AR' },
      { name: 'Armenia', value: '12', twoLetterCountryCode: 'AM' },
      { name: 'Aruba', value: '13', twoLetterCountryCode: 'AW' },
      { name: 'Australia', value: '14', twoLetterCountryCode: 'AU' },
      { name: 'Austria', value: '15', twoLetterCountryCode: 'AT' },
      { name: 'Azerbaijan', value: '16', twoLetterCountryCode: 'AZ' },
      { name: 'Bahamas', value: '17', twoLetterCountryCode: 'BS' },
      { name: 'Bahrain', value: '18', twoLetterCountryCode: 'BH' },
      { name: 'Bangladesh', value: '19', twoLetterCountryCode: 'BD' },
      { name: 'Barbados', value: '20', twoLetterCountryCode: 'BB' },
      { name: 'Belarus', value: '21', twoLetterCountryCode: 'BY' },
      { name: 'Belgium', value: '22', twoLetterCountryCode: 'BE' },
      { name: 'Belize', value: '23', twoLetterCountryCode: 'BZ' },
      { name: 'Benin', value: '24', twoLetterCountryCode: 'BJ' },
      { name: 'Bermuda', value: '25', twoLetterCountryCode: 'BM' },
      { name: 'Bhutan', value: '26', twoLetterCountryCode: 'BT' },
      { name: 'Bolivia', value: '27', twoLetterCountryCode: 'BO' },
      { name: 'Bonaire, Sint Eustatius and Saba', value: '28', twoLetterCountryCode: 'BQ' },
      { name: 'Bosnia and Herzegovina', value: '29', twoLetterCountryCode: 'BA' },
      { name: 'Botswana', value: '30', twoLetterCountryCode: 'BW' },
      { name: 'Bouvet Island', value: '31', twoLetterCountryCode: 'BV' },
      { name: 'Brazil', value: '32', twoLetterCountryCode: 'BR' },
      { name: 'British Indian Ocean Territory', value: '33', twoLetterCountryCode: 'IO' },
      { name: 'Brunei', value: '34', twoLetterCountryCode: 'BN' },
      { name: 'Bulgaria', value: '35', twoLetterCountryCode: 'BG' },
      { name: 'Burkina Faso', value: '36', twoLetterCountryCode: 'BF' },
      { name: 'Burundi', value: '37', twoLetterCountryCode: 'BI' },
      { name: 'Cambodia', value: '38', twoLetterCountryCode: 'KH' },
      { name: 'Cameroon', value: '39', twoLetterCountryCode: 'CM' },
      { name: 'Canada', value: '40', twoLetterCountryCode: 'CA' },
      { name: 'Cape Verde', value: '41', twoLetterCountryCode: 'CV' },
      { name: 'Cayman Islands', value: '42', twoLetterCountryCode: 'KY' },
      { name: 'Central African Republic', value: '43', twoLetterCountryCode: 'CF' },
      { name: 'Chad', value: '44', twoLetterCountryCode: 'TD' },
      { name: 'Chile', value: '45', twoLetterCountryCode: 'CL' },
      { name: 'China', value: '46', twoLetterCountryCode: 'CN' },
      { name: 'Christmas Island', value: '47', twoLetterCountryCode: 'CX' },
      { name: 'Cocos(Keeling) Islands', value: '48', twoLetterCountryCode: 'CC' },
      { name: 'Colombia', value: '49', twoLetterCountryCode: 'CO' },
      { name: 'Comoros', value: '50', twoLetterCountryCode: 'KM' },
      { name: 'Congo', value: '51', twoLetterCountryCode: 'CG' },
      { name: 'Cook Islands', value: '52', twoLetterCountryCode: 'CK' },
      { name: 'Costa Rica', value: '53', twoLetterCountryCode: 'CR' },
      { name: 'Ivory Coast', value: '54', twoLetterCountryCode: 'CI' },
      { name: 'Croatia', value: '55', twoLetterCountryCode: 'HR' },
      { name: 'Cuba', value: '56', twoLetterCountryCode: 'CU' },
      { name: 'Curacao', value: '57', twoLetterCountryCode: 'CW' },
      { name: 'Cyprus', value: '58', twoLetterCountryCode: 'CY' },
      { name: 'Czech Republic', value: '59', twoLetterCountryCode: 'CZ' },
      { name: 'Democratic Republic of the Congo', value: '60', twoLetterCountryCode: 'CD' },
      { name: 'Denmark', value: '61', twoLetterCountryCode: 'DK' },
      { name: 'Djibouti', value: '62', twoLetterCountryCode: 'DJ' },
      { name: 'Dominica', value: '63', twoLetterCountryCode: 'DM' },
      { name: 'Dominican Republic', value: '64', twoLetterCountryCode: 'DO' },
      { name: 'Ecuador', value: '65', twoLetterCountryCode: 'EC' },
      { name: 'Egypt', value: '66', twoLetterCountryCode: 'EG' },
      { name: 'El Salvador', value: '67', twoLetterCountryCode: 'SV' },
      { name: 'Equatorial Guinea', value: '68', twoLetterCountryCode: 'GQ' },
      { name: 'Eritrea', value: '69', twoLetterCountryCode: 'ER' },
      { name: 'Estonia', value: '70', twoLetterCountryCode: 'EE' },
      { name: 'Ethiopia', value: '71', twoLetterCountryCode: 'ET' },
      { name: 'Falkland Islands(Malvinas)', value: '72', twoLetterCountryCode: 'FK' },
      { name: 'Faroe Islands', value: '73', twoLetterCountryCode: 'FO' },
      { name: 'Fiji', value: '74', twoLetterCountryCode: 'FJ' },
      { name: 'Finland', value: '75', twoLetterCountryCode: 'FI' },
      { name: 'France', value: '76', twoLetterCountryCode: 'FR' },
      { name: 'French Guiana', value: '77', twoLetterCountryCode: 'GF' },
      { name: 'French Polynesia', value: '78', twoLetterCountryCode: 'PF' },
      { name: 'French Southern Territories', value: '79', twoLetterCountryCode: 'TF' },
      { name: 'Gabon', value: '80', twoLetterCountryCode: 'GA' },
      { name: 'Gambia', value: '81', twoLetterCountryCode: 'GM' },
      { name: 'Georgia', value: '82', twoLetterCountryCode: 'GE' },
      { name: 'Germany', value: '83', twoLetterCountryCode: 'DE' },
      { name: 'Ghana', value: '84', twoLetterCountryCode: 'GH' },
      { name: 'Gibraltar', value: '85', twoLetterCountryCode: 'GI' },
      { name: 'Greece', value: '86', twoLetterCountryCode: 'GR' },
      { name: 'Greenland', value: '87', twoLetterCountryCode: 'GL' },
      { name: 'Grenada', value: '88', twoLetterCountryCode: 'GD' },
      { name: 'Guadaloupe', value: '89', twoLetterCountryCode: 'GP' },
      { name: 'Guam', value: '90', twoLetterCountryCode: 'GU' },
      { name: 'Guatemala', value: '91', twoLetterCountryCode: 'GT' },
      { name: 'Guernsey', value: '92', twoLetterCountryCode: 'GG' },
      { name: 'Guinea', value: '93', twoLetterCountryCode: 'GN' },
      { name: 'Guinea-Bissau', value: '94', twoLetterCountryCode: 'GW' },
      { name: 'Guyana', value: '95', twoLetterCountryCode: 'GY' },
      { name: 'Haiti', value: '96', twoLetterCountryCode: 'HT' },
      { name: 'Heard Island and McDonald Islands', value: '97', twoLetterCountryCode: 'HM' },
      { name: 'Honduras', value: '98', twoLetterCountryCode: 'HN' },
      { name: 'Hong Kong', value: '99', twoLetterCountryCode: 'HK' },
      { name: 'Hungary', value: '100', twoLetterCountryCode: 'HU' },
      { name: 'Iceland', value: '101', twoLetterCountryCode: 'IS' },
      { name: 'India', value: '102', twoLetterCountryCode: 'IN' },
      { name: 'Indonesia', value: '103', twoLetterCountryCode: 'ID' },
      { name: 'Iran', value: '104', twoLetterCountryCode: 'IR' },
      { name: 'Iraq', value: '105', twoLetterCountryCode: 'IQ' },
      { name: 'Ireland', value: '106', twoLetterCountryCode: 'IE' },
      { name: 'Isle of Man', value: '107', twoLetterCountryCode: 'IM' },
      { name: 'Israel', value: '108', twoLetterCountryCode: 'IL' },
      { name: 'Italy', value: '109', twoLetterCountryCode: 'IT' },
      { name: 'Jamaica', value: '110', twoLetterCountryCode: 'JM' },
      { name: 'Japan', value: '111', twoLetterCountryCode: 'JP' },
      { name: 'Jersey', value: '112', twoLetterCountryCode: 'JE' },
      { name: 'Jordan', value: '113', twoLetterCountryCode: 'JO' },
      { name: 'Kazakhstan', value: '114', twoLetterCountryCode: 'KZ' },
      { name: 'Kenya', value: '115', twoLetterCountryCode: 'KE' },
      { name: 'Kiribati', value: '116', twoLetterCountryCode: 'KI' },
      { name: 'Kosovo', value: '117', twoLetterCountryCode: 'XK' },
      { name: 'Kuwait', value: '118', twoLetterCountryCode: 'KW' },
      { name: 'Kyrgyzstan', value: '119', twoLetterCountryCode: 'KG' },
      { name: 'Laos', value: '120', twoLetterCountryCode: 'LA' },
      { name: 'Latvia', value: '121', twoLetterCountryCode: 'LV' },
      { name: 'Lebanon', value: '122', twoLetterCountryCode: 'LB' },
      { name: 'Lesotho', value: '123', twoLetterCountryCode: 'LS' },
      { name: 'Liberia', value: '124', twoLetterCountryCode: 'LR' },
      { name: 'Libya', value: '125', twoLetterCountryCode: 'LY' },
      { name: 'Liechtenstein', value: '126', twoLetterCountryCode: 'LI' },
      { name: 'Lithuania', value: '127', twoLetterCountryCode: 'LT' },
      { name: 'Luxembourg', value: '128', twoLetterCountryCode: 'LU' },
      { name: 'Macao', value: '129', twoLetterCountryCode: 'MO' },
      { name: 'Macedonia', value: '130', twoLetterCountryCode: 'MK' },
      { name: 'Madagascar', value: '131', twoLetterCountryCode: 'MG' },
      { name: 'Malawi', value: '132', twoLetterCountryCode: 'MW' },
      { name: 'Malaysia', value: '133', twoLetterCountryCode: 'MY' },
      { name: 'Maldives', value: '134', twoLetterCountryCode: 'MV' },
      { name: 'Mali', value: '135', twoLetterCountryCode: 'ML' },
      { name: 'Malta', value: '136', twoLetterCountryCode: 'MT' },
      { name: 'Marshall Islands', value: '137', twoLetterCountryCode: 'MH' },
      { name: 'Martinique', value: '138', twoLetterCountryCode: 'MQ' },
      { name: 'Mauritania', value: '139', twoLetterCountryCode: 'MR' },
      { name: 'Mauritius', value: '140', twoLetterCountryCode: 'MU' },
      { name: 'Mayotte', value: '141', twoLetterCountryCode: 'YT' },
      { name: 'Mexico', value: '142', twoLetterCountryCode: 'MX' },
      { name: 'Micronesia', value: '143', twoLetterCountryCode: 'FM' },
      { name: 'Moldava', value: '144', twoLetterCountryCode: 'MD' },
      { name: 'Monaco', value: '145', twoLetterCountryCode: 'MC' },
      { name: 'Mongolia', value: '146', twoLetterCountryCode: 'MN' },
      { name: 'Montenegro', value: '147', twoLetterCountryCode: 'ME' },
      { name: 'Montserrat', value: '148', twoLetterCountryCode: 'MS' },
      { name: 'Morocco', value: '149', twoLetterCountryCode: 'MA' },
      { name: 'Mozambique', value: '150', twoLetterCountryCode: 'MZ' },
      { name: 'Myanmar(Burma)', value: '151', twoLetterCountryCode: 'MM' },
      { name: 'Namibia', value: '152', twoLetterCountryCode: 'NA' },
      { name: 'Nauru', value: '153', twoLetterCountryCode: 'NR' },
      { name: 'Nepal', value: '154', twoLetterCountryCode: 'NP' },
      { name: 'Netherlands', value: '155', twoLetterCountryCode: 'NL' },
      { name: 'New Caledonia', value: '156', twoLetterCountryCode: 'NC' },
      { name: 'New Zealand', value: '157', twoLetterCountryCode: 'NZ' },
      { name: 'Nicaragua', value: '158', twoLetterCountryCode: 'NI' },
      { name: 'Niger', value: '159', twoLetterCountryCode: 'NE' },
      { name: 'Nigeria', value: '160', twoLetterCountryCode: 'NG' },
      { name: 'Niue', value: '161', twoLetterCountryCode: 'NU' },
      { name: 'Norfolk Island', value: '162', twoLetterCountryCode: 'NF' },
      { name: 'North Korea', value: '163', twoLetterCountryCode: 'KP' },
      { name: 'Northern Mariana Islands', value: '164', twoLetterCountryCode: 'MP' },
      { name: 'Norway', value: '165', twoLetterCountryCode: 'NO' },
      { name: 'Oman', value: '166', twoLetterCountryCode: 'OM' },
      { name: 'Pakistan', value: '167', twoLetterCountryCode: 'PK' },
      { name: 'Palau', value: '168', twoLetterCountryCode: 'PW' },
      { name: 'Palestine', value: '169', twoLetterCountryCode: 'PS' },
      { name: 'Panama', value: '170', twoLetterCountryCode: 'PA' },
      { name: 'Papua New Guinea', value: '171', twoLetterCountryCode: 'PG' },
      { name: 'Paraguay', value: '172', twoLetterCountryCode: 'PY' },
      { name: 'Peru', value: '173', twoLetterCountryCode: 'PE' },
      { name: 'Philippines', value: '174', twoLetterCountryCode: 'PH' },
      { name: 'Pitcairn', value: '175', twoLetterCountryCode: 'PN' },
      { name: 'Poland', value: '176', twoLetterCountryCode: 'PL' },
      { name: 'Portugal', value: '177', twoLetterCountryCode: 'PT' },
      { name: 'Puerto Rico', value: '178', twoLetterCountryCode: 'PR' },
      { name: 'Qatar', value: '179', twoLetterCountryCode: 'QA' },
      { name: 'Reunion', value: '180', twoLetterCountryCode: 'RE' },
      { name: 'Romania', value: '181', twoLetterCountryCode: 'RO' },
      { name: 'Russia', value: '182', twoLetterCountryCode: 'RU' },
      { name: 'Rwanda', value: '183', twoLetterCountryCode: 'RW' },
      { name: 'Saint Barthelemy', value: '184', twoLetterCountryCode: 'BL' },
      { name: 'Saint Helena', value: '185', twoLetterCountryCode: 'SH' },
      { name: 'Saint Kitts and Nevis', value: '186', twoLetterCountryCode: 'KN' },
      { name: 'Saint Lucia', value: '187', twoLetterCountryCode: 'LC' },
      { name: 'Saint Martin', value: '188', twoLetterCountryCode: 'MF' },
      { name: 'Saint Pierre and Miquelon', value: '189', twoLetterCountryCode: 'PM' },
      { name: 'Saint Vincent and the Grenadines', value: '190', twoLetterCountryCode: 'VC' },
      { name: 'Samoa', value: '191', twoLetterCountryCode: 'WS' },
      { name: 'San Marino', value: '192', twoLetterCountryCode: 'SM' },
      { name: 'Sao Tome and Principe', value: '193', twoLetterCountryCode: 'ST' },
      { name: 'Saudi Arabia', value: '194', twoLetterCountryCode: 'SA' },
      { name: 'Senegal', value: '195', twoLetterCountryCode: 'SN' },
      { name: 'Serbia', value: '196', twoLetterCountryCode: 'RS' },
      { name: 'Seychelles', value: '197', twoLetterCountryCode: 'SC' },
      { name: 'Sierra Leone', value: '198', twoLetterCountryCode: 'SL' },
      { name: 'Singapore', value: '199', twoLetterCountryCode: 'SG' },
      { name: 'Sint Maarten', value: '200', twoLetterCountryCode: 'SX' },
      { name: 'Slovakia', value: '201', twoLetterCountryCode: 'SK' },
      { name: 'Slovenia', value: '202', twoLetterCountryCode: 'SI' },
      { name: 'Solomon Islands', value: '203', twoLetterCountryCode: 'SB' },
      { name: 'Somalia', value: '204', twoLetterCountryCode: 'SO' },
      { name: 'South Africa', value: '205', twoLetterCountryCode: 'ZA' },
      { name: 'South Georgia and the South Sandwich Islands', value: '206', twoLetterCountryCode: 'GS' },
      { name: 'South Korea', value: '207', twoLetterCountryCode: 'KR' },
      { name: 'South Sudan', value: '208', twoLetterCountryCode: 'SS' },
      { name: 'Spain', value: '209', twoLetterCountryCode: 'ES' },
      { name: 'Sri Lanka', value: '210', twoLetterCountryCode: 'LK' },
      { name: 'Sudan', value: '211', twoLetterCountryCode: 'SD' },
      { name: 'Suriname', value: '212', twoLetterCountryCode: 'SR' },
      { name: 'Svalbard and Jan Mayen', value: '213', twoLetterCountryCode: 'SJ' },
      { name: 'Swaziland', value: '214', twoLetterCountryCode: 'SZ' },
      { name: 'Sweden', value: '215', twoLetterCountryCode: 'SE' },
      { name: 'Switzerland', value: '216', twoLetterCountryCode: 'CH' },
      { name: 'Syria', value: '217', twoLetterCountryCode: 'SY' },
      { name: 'Taiwan', value: '218', twoLetterCountryCode: 'TW' },
      { name: 'Tajikistan', value: '219', twoLetterCountryCode: 'TJ' },
      { name: 'Tanzania', value: '220', twoLetterCountryCode: 'TZ' },
      { name: 'Thailand', value: '221', twoLetterCountryCode: 'TH' },
      { name: 'Timor-Leste(East Timor)', value: '222', twoLetterCountryCode: 'TL' },
      { name: 'Togo', value: '223', twoLetterCountryCode: 'TG' },
      { name: 'Tokelau', value: '224', twoLetterCountryCode: 'TK' },
      { name: 'Tonga', value: '225', twoLetterCountryCode: 'TO' },
      { name: 'Trinidad and Tobago', value: '226', twoLetterCountryCode: 'TT' },
      { name: 'Tunisia', value: '227', twoLetterCountryCode: 'TN' },
      { name: 'Turkey', value: '228', twoLetterCountryCode: 'TR' },
      { name: 'Turkmenistan', value: '229', twoLetterCountryCode: 'TM' },
      { name: 'Turks and Caicos Islands', value: '230', twoLetterCountryCode: 'TC' },
      { name: 'Tuvalu', value: '231', twoLetterCountryCode: 'TV' },
      { name: 'Uganda', value: '232', twoLetterCountryCode: 'UG' },
      { name: 'Ukraine', value: '233', twoLetterCountryCode: 'UA' },
      { name: 'United Arab Emirates', value: '234', twoLetterCountryCode: 'AE' },
      { name: 'United Kingdom', value: '235', twoLetterCountryCode: 'GB' },
      { name: 'United States', value: '236', twoLetterCountryCode: 'US' },
      { name: 'United States Minor Outlying Islands', value: '237', twoLetterCountryCode: 'UM' },
      { name: 'Uruguay', value: '238', twoLetterCountryCode: 'UY' },
      { name: 'Uzbekistan', value: '239', twoLetterCountryCode: 'UZ' },
      { name: 'Vanuatu', value: '240', twoLetterCountryCode: 'VU' },
      { name: 'Vatican City', value: '241', twoLetterCountryCode: 'VA' },
      { name: 'Venezuela', value: '242', twoLetterCountryCode: 'VE' },
      { name: 'Vietnam', value: '243', twoLetterCountryCode: 'VN' },
      { name: 'Virgin Islands, British', value: '244', twoLetterCountryCode: 'VG' },
      { name: 'Virgin Islands, US', value: '245', twoLetterCountryCode: 'VI' },
      { name: 'Wallis and Futuna', value: '246', twoLetterCountryCode: 'WF' },
      { name: 'Western Sahara', value: '247', twoLetterCountryCode: 'EH' },
      { name: 'Yemen', value: '248', twoLetterCountryCode: 'YE' },
      { name: 'Zambia', value: '249', twoLetterCountryCode: 'ZM' },
      { name: 'Zimbabwe', value: '250', twoLetterCountryCode: 'ZW' }
    ];
  }
}
