import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/_services';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-user-create-request',
  templateUrl: './user-create-request.component.html',
  styles: []
})
export class UserCreateRequestComponent implements OnInit {

  public loading: Boolean = false;
  submitted = false;
  createuserForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private uiService: UiService,
    private router: Router
  ) { }

  ngOnInit() {

    this.createuserForm = this.formBuilder.group({
      email: ['', Validators.required],
      role: [null, Validators.required]
    });
  }

  get f() { return this.createuserForm.controls; }

  async onSubmit() {
    this.submitted = true;

    if(this.createuserForm.valid) {
      
      this.loading = true;

      (await this.userService.createUserInvite({
        email: this.createuserForm.value.email,
        role: this.createuserForm.value.role
      })).subscribe(
        data => {
          this.loading = false;
          
          if(data.success === false){
            this.toastr.error(data.message, "Register User Failed");
          } else{
            this.router.navigate(["/userlist/"]);
            this.toastr.success(data.message, "");
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
        });
      }
    }
}
