import { Injectable } from '@angular/core';
import { Location } from 'src/app/models/location.model';
declare var H: any;

@Injectable({
  providedIn: 'root'
})
export class HereService {

  public platform: any;
  public geocoder: any;
  public service: any;

  public constructor() {
    this.platform = new H.service.Platform({
      'apikey': 'nKXiFAiNYIx3iP5JNDUeSYd48z3eSaHn1NK7zxj2KoU'
    });
    this.service = this.platform.getSearchService();
  }

  async getCustomerAddress(q: string) {
    
    return new Promise((resolve, reject) => {
      this.service.geocode({ q: q }, result => {
        resolve(result.items);
      }, error => {
        reject(error);
      });
    });

    /*
    await this.service.geocode({
      q: location.fullAddress
    }, (result) => {
      // Add a marker for each location found
      return result.items;
      if(result.items.length === 0)
        alert('Address not found, please provide more information.');
      else if(result.items.length === 1)
        alert('confirm address:' + result.items[0].title);
      else {
        alert('More than one address found, please select the right one.');
        result.items.forEach((item) => {
          console.log(item.title);
          //map.addObject(new H.map.Marker(item.position));
        });
      }
    }, alert);
    */
  }

  async convertAddress(q: string) {
    var service = this.platform.getSearchService();
    service.geocode({
      q: q
    }, (result) => {
      // Add a marker for each location found
      if(result.items.length === 0)
        alert('Address not found, please provide more information.');
      else if(result.items.length === 1)
        alert('confirm address:' + result.items[0].title);
      else {
        alert('More than one address found, please select the right one.');
        result.items.forEach((item) => {
          console.log(item.title);
          //map.addObject(new H.map.Marker(item.position));
        });
      }
    }, alert);
  }

  public getAddress(query: string) : any {
    return new Promise((resolve, reject) => {
      this.geocoder.geocode({ searchText: query }, result => {
        if (result.Response.View.length > 0) {
          if (result.Response.View[0].Result.length > 0) {
            resolve(result.Response.View[0].Result.items);
          } else {
            reject({ message: "no results found" });
          }
        } else {
          reject({ message: "no results found" });
        }
      }, error => {
        reject(error);
      });
    });
  }

  public getAddressFromLatLng(query: string) {
    return new Promise((resolve, reject) => {
      this.geocoder.reverseGeocode({ prox: query, mode: "retrieveAddress" }, result => {
        if (result.Response.View.length > 0) {
          if (result.Response.View[0].Result.length > 0) {
            resolve(result.Response.View[0].Result);
          } else {
            reject({ message: "no results found" });
          }
        } else {
          reject({ message: "no results found" });
        }
      }, error => {
        reject(error);
      });
    });
  }
}
