import { ICustomer } from '../interfaces/customer.interface';

export class CustomerEditRequest {
    id: number;
    companyId: number;
    name: string;
    description: string;
    phoneNumber: string;
    email: string;
    url: string;
    location: import("../interfaces/location.interface").ILocation;
}