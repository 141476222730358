import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef  } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommentService } from 'src/app/_services/comment.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-comment-create',
  templateUrl: './comment-create.component.html',
  styleUrls: ['./comment-create.component.css']
})
export class CommentCreateComponent implements OnInit {
  commentCreateForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private commentService: CommentService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<CommentCreateComponent>,
    private uiService: UiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) 
  { }

  ngOnInit() {
    this.commentCreateForm = this.formBuilder.group({
      comment: new FormControl('', Validators.required)
    });
  }

  onCancel($event){
    $event.preventDefault();
    this.commentCreateForm.reset();
    this.dialogRef.close();
  }

  onClose(){
    this.commentCreateForm.reset();
    this.dialogRef.close();
  }

  async onSubmit() {
    if(this.commentCreateForm.valid) {
      (await this.commentService.create(this.commentCreateForm.value.comment,this.data.taskId)).subscribe(
        data => {
          if(this.commentService.success === false){
            this.toastr.error(this.commentService.message, "");
          } else{
            this.commentCreateForm.reset();
            this.toastr.success(this.commentService.message, "");
            this.dialogRef.close();
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
      });
    }
  }
}
