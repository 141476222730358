import { Component, OnInit, ViewChild, ElementRef, NgZone, TemplateRef } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, DateAdapter } from "@angular/material";
import { TasktypeService } from 'src/app/_services/tasktype.service';
import { AlertService, UserService, TaskService } from 'src/app/_services';
import { CustomerService } from 'src/app/_services/customer.service';
import { Location } from 'src/app/models/location.model';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Customer } from 'src/app/models/customer.model';
import { CustomerEditRequest } from 'src/app/models/customerEditRequest.model';
import { UiService } from 'src/app/_services/ui.service';
import { faCheckCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { stringify } from 'querystring';
import { HereService } from "../../_services/here.service";
import { ILocation } from 'src/app/interfaces/location.interface';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})

export class CustomerComponent implements OnInit {
  @ViewChild('addressNotFoundModal', { static: false }) addressNotFoundModalContent: TemplateRef<any>;
  @ViewChild('confirmAddressModal', { static: false }) confirmAddressModalContent: TemplateRef<any>;
  @ViewChild('multipleAddressesFoundModal', { static: false }) multipleAddressesFoundModalContent: TemplateRef<any>;

  id: number;
  customer: Customer;
  isCreating: boolean = false;
  isEditing: boolean = false;
  updateCustomerForm: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  //private subscriptions: Subscription[] = [];
  faCheckCircle = faCheckCircle;
  faPencilAlt = faPencilAlt;

  latitude: number;
  longitude: number;
  zoom: number;

  private geoCoder;

  twoLetterCountryCode: string;

  public locations: Array<any>;

  closeResult: string;

  foundAddresses: any[];
  foundAddress: string;

  selectedAddress: any;

  customerRequest: CustomerEditRequest;

  addressSelectedFromList = false;

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private activatedRouter: ActivatedRoute,
    private uiService: UiService,
    private here: HereService,
    private modalService: NgbModal,
    private toastr: ToastrService) {
  }

  get f() { return this.updateCustomerForm.controls; }

  get address() { return this.returnField('address'); }
  get email() { return this.returnField('email'); }
  get phonenumber() { return this.returnField('phonenumber'); }
  get website() { return this.returnField('url'); }

  returnField(key: string): String {
    let field = this.updateCustomerForm.get(key).value;
    if (field === undefined || field === null || field === '') {
      return "None specified";
    }
    return field;
  }

  saveSeletedAddress(listIndex: number) {
    this.selectedAddress = this.foundAddresses[listIndex];
    this.addressSelectedFromList = true;
  }

  // ngOnDestroy(){
  //   this.subscriptions.forEach(sub => {
  //     sub.unsubscribe();
  //   });
  // }

  ngOnInit() {
    this.id = +this.activatedRouter.snapshot.paramMap.get('id');

    this.updateCustomerForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      email: [''],
      phonenumber: [''],
      url: [''],
      address: [''],
      country: ['']
    });

    this.initCustomer();
  }

  ngAfterViewInit() {
  }

  private async initCustomer() {
    (await this.customerService.get(this.id)).subscribe(
      data => {
        if (data.success === false) {
          this.toastr.error(data.message, "Network error");
        } else {
          this.customer = new Customer();
          this.customer.location = new Location();
          this.customer = data.customer;

          this.updateCustomerForm.controls['name'].setValue(this.customer.name);
          this.updateCustomerForm.controls['description'].setValue(this.customer.description);
          this.updateCustomerForm.controls['email'].setValue(this.customer.email);
          this.updateCustomerForm.controls['phonenumber'].setValue(this.customer.phoneNumber);
          this.updateCustomerForm.controls['url'].setValue(this.customer.url);

          if(data.customer.location !== null){
            this.customer.location = data.customer.location;
            this.latitude = +this.customer.location.latitude;
            this.longitude = +this.customer.location.longitude;
            this.updateCustomerForm.controls['address'].setValue(this.customer.location.fullAddress);
          }
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }

  openAddressNotFoundModal() {
    this.modalService.open(this.addressNotFoundModalContent).result.then((result) => { }, (reason) => { });
  }

  openConfirmAddressModal(item: any) {
    this.selectedAddress = item;
    this.foundAddress = item.address.label;
    this.modalService.open(this.confirmAddressModalContent).result.then((result) => {
      this.setSelectedAddress();
    }, (reason) => { });
  }

  openMultipleAddressesFoundModal(items: any[]) {

    this.foundAddresses = [];
    items.forEach((item) => {
      this.foundAddresses.push(item);
      //map.addObject(new H.map.Marker(item.position));
    });

    this.modalService.open(this.multipleAddressesFoundModalContent).result.then((result) => {
      this.setSelectedAddress();
      this.addressSelectedFromList = false;
    }, (reason) => {
      this.addressSelectedFromList = false;
     });
  }

  private setSelectedAddress() {

    if(this.customerRequest.location === null)
      this.customerRequest.location = new Location();

    this.customerRequest.location.fullAddress = this.selectedAddress.address.label;
    this.customerRequest.location.cityName = this.selectedAddress.address.city;
    this.customerRequest.location.country = this.selectedAddress.address.countryName;
    this.customerRequest.location.countryId = this.selectedAddress.address.countryCode;
    this.customerRequest.location.latitude = this.selectedAddress.position.lat;
    this.customerRequest.location.longitude = this.selectedAddress.position.lng;
    this.customerRequest.location.regionName = this.selectedAddress.address.state;
    this.customerRequest.location.streetName = this.selectedAddress.address.street;
    this.customerRequest.location.streetNumber = this.selectedAddress.address.houseNumber;
    this.customerRequest.location.zipCode = this.selectedAddress.address.postalCode;
    // console.log("county: " + this.selectedAddress.address.county);
    // console.log("district: " + this.selectedAddress.address.district);
    this.Save();
  }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  onEdit() {
    this.isEditing = true;
  }

  /*
  onCountryChanged() {
    this.clearAddress();
    const country = this.getCountryFromCountryId(this.customer.location.countryId);
    this.customer.location.country = country.name;
  }
  

  private getAddressFromGoogleMapsObject(place: google.maps.places.PlaceResult, useNewCoordinates: Boolean) {
    for (var i = 0; i < place.address_components.length; i++) {
      var typeName = place.address_components[i]["types"][0];

      if (typeName == "street_number") {
        this.customer.location.streetNumber = place.address_components[i].long_name;
      }
      if (typeName == "route") {
        this.customer.location.streetName = place.address_components[i].long_name;
      }
      if (typeName == "postal_code") {
        this.customer.location.zipCode = place.address_components[i].long_name;
      }
      if (typeName == "country") {
        this.customer.location.country = place.address_components[i].long_name;
        //countryShortname = place.address_components[i].short_name;
      }
      if (typeName == "locality") {
        this.customer.location.cityName = place.address_components[i].long_name;
      }
      if (typeName == "political") {
        //alert(place.address_components[i].long_name);
      }
    }

    this.customer.location.fullAddress = place.formatted_address;

    if (useNewCoordinates === true) {
      this.customer.location.latitude = place.geometry.location.lat().toString();
      this.customer.location.longitude = place.geometry.location.lng().toString();
    }
  }
  */
  private clearAddress() {

    if(this.customerRequest.location == null)
      this.customerRequest.location = new Location();

    this.customerRequest.location.cityName = "";
    this.customerRequest.location.fullAddress = "";
    this.customerRequest.location.latitude = "0";
    this.customerRequest.location.longitude = "0";
    this.customerRequest.location.regionName = "";
    this.customerRequest.location.streetName = "";
    this.customerRequest.location.streetNumber = "";
    this.customerRequest.location.zipCode = "";
  }

  async onSave() {
    this.submitted = true;
    if (this.updateCustomerForm.valid) {
      var customer = new CustomerEditRequest();
      customer.id = this.id;
      customer.name = this.updateCustomerForm.value.name;
      customer.description = this.updateCustomerForm.value.description;
      customer.email = this.updateCustomerForm.value.email;
      customer.phoneNumber = this.updateCustomerForm.value.phonenumber;
      customer.url = this.updateCustomerForm.value.url;
      customer.location = this.customer.location;
      this.customerRequest = customer;

      if (this.updateCustomerForm.value.address === '') {
        this.clearAddress();
        this.Save();
      }
      else if (this.customer.location == null || this.updateCustomerForm.value.address !== this.customer.location.fullAddress) {
        // Address has changed

        const items: any = await this.here.getCustomerAddress(this.updateCustomerForm.value.address);

        if (items.length === 0)
          this.openAddressNotFoundModal();
        else if (items.length === 1)
          this.openConfirmAddressModal(items[0]);
        else
          this.openMultipleAddressesFoundModal(items);

        //Location loc = await this.here.convertAddress(customer.location);
      } else {
        this.Save();
      }
    }
    this.submitted = false;
  }

  async Save() {
    this.loading = true;

    (await (this.customerService.edit(this.customerRequest))).subscribe(
      data => {
        this.loading = false;
        
        if (data.success === false) {
          this.toastr.error(data.message, "Update Customer Failed");
        } else {
          this.isEditing = false;
          this.toastr.success(data.message, "");
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }
}
