import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Tasktype } from 'src/app/models/tasktype.model';
import { TasktypeService } from 'src/app/_services/tasktype.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-tasktype-create',
  templateUrl: './tasktype-create.component.html',
  styles: []
})
export class TasktypeCreateComponent implements OnInit {

  createTaskTypeForm: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private tasktypeService: TasktypeService,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.createTaskTypeForm = this.formBuilder.group({
      type: ['', Validators.required]
    });
  }

  get f() { return this.createTaskTypeForm.controls; }

  async onSubmit() {
    
    this.submitted = true;
    if(this.createTaskTypeForm.valid) {
      
      this.loading = true;

      (await this.tasktypeService.create({
          Type: this.createTaskTypeForm.value.type
        })).subscribe(
        data => {
          this.loading = false;
          if(data.success === false){
            this.toastr.error(data.message, "");
          } else{
            this.createTaskTypeForm.reset();
            this.router.navigate(["/tasktype-list/"]);
            this.toastr.success(data.message, "");
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
      });
    }
  }
}
