import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  public serverErrorTitle: string = 'Failed to get data';
  public serverErrorMessage: string = 'Could not get data due to network issues.';

  constructor(private toastr: ToastrService) { }

  loadingStart(){
    $("#progress-bar").removeAttr('hidden');
  }

  loadingComplete(){
    $("#progress-bar").attr('hidden', "true");
  }

  loadingCompleteWithError(){
    $("#progress-bar").attr('hidden', "true");
    this.showDefaultErrorMessage();
  }

  showDefaultErrorMessage(){
    this.toastr.error(this.serverErrorMessage, this.serverErrorTitle);
  }
  
}
