import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { RegisterCompany } from '../models/registerCompany.model';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { CompanySettingsRequest } from '../models/companySettingsRequest.model';
import { UiService } from './ui.service';
import { PricingPlan } from '../enums/pricingplan.enum';

@Injectable({ providedIn: 'root' })
export class CompanyService {
    
    constructor(
        private http: HttpClient,
        private authService: AuthenticationService,
        private uiService: UiService
    ) { }

    async editSettings(settings: CompanySettingsRequest) {
        await this.authService.isExpired();
    
        return this.http.post<any>(environment.baseUrl +"/Company/UpdateCompanySettings", settings)
        .pipe(map(data => {
            return data;
        }));
    }

    async editPricingPlan(selectedPricingPlan: PricingPlan, email: string) {

        this.uiService.loadingStart();
        await this.authService.isExpired();

        var model = {
            SelectedPricingPlan: selectedPricingPlan,
            EmailForInvoice: email
        };

        return this.http.post<any>(environment.baseUrl +"/Company/UpdatePricingPlan", model)
        .pipe(map(data => {
            this.uiService.loadingComplete();
            return data;
        }));
      }

    register(registerCompany: RegisterCompany) {
        
        return this.http.post<any>(environment.baseUrl +"/Company/CreateServiceCompany", {
            'defaultToken': environment.defaultToken,
            'databaseExternalKey': '2',
            'productId': environment.productId.toString(),
            'countryId': registerCompany.countryId,
            'companyName': registerCompany.companyName,
            'admin': registerCompany.admin.toString(),
            'email': registerCompany.email,
            'password': registerCompany.password,
            'firstName': registerCompany.firstName,
            'middleNames': registerCompany.middleNames,
            'lastName': registerCompany.lastName,
            'timeZone': registerCompany.timeZone
        })
        .pipe(map(registerCompanyModel => {
            return registerCompanyModel;
        }));
    }

    async getSettings() {

        this.uiService.loadingStart();

        await this.authService.isExpired();
    
        return this.http.get<any>(environment.baseUrl +"/Company/GetCompanySettings")
        .pipe(map(data => {
            this.uiService.loadingComplete();
            return data;
        }));
    }

    async initChangePricingPlan() {

        this.uiService.loadingStart();

        await this.authService.isExpired();
        
        var model = {
            Language: "en"
        };

        return this.http.post<any>(environment.worktasksUrl +"/WTTasks/InitChangePricingPlan", model)
        .pipe(map(data => {
            this.uiService.loadingComplete();
            return data;
        }));
    }

    async deleteCompany() {
        
        this.uiService.loadingStart();
        await this.authService.isExpired();

        return this.http.delete<any>(environment.baseUrl +"/Company/DeleteCompany")
        .pipe(map(data => {
            this.uiService.loadingComplete();
            return data;
        }));
    }
}