import { Component, OnInit } from '@angular/core';
import { TimeslotService } from 'src/app/_services/timeslot.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { MatDialogRef, MatTableModule } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User } from 'src/app/models/user.model';
import { AssignedUser } from 'src/app/models/assignedUser.model';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-timeslot-list',
  templateUrl: './timeslot-list.component.html',
  styleUrls: ['./timeslot-list.component.css']
})
export class TimeslotListComponent implements OnInit {
  timeslotsForm: FormGroup;
  timeslots;
  selectedTimeslotIds = [];
  currentDate: Date;
  closeMessage: string = "";  
  selectedTimeslots = [];

  constructor(
    private formBuilder: FormBuilder,
    public timeslotService: TimeslotService,
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private uiService: UiService,
    public dialogRef: MatDialogRef<TimeslotListComponent>) { }

  ngOnInit() {

    this.timeslotsForm = this.formBuilder.group({
    });
    
    this.currentDate = new Date();
    this.getTimeSlots();
  }

  async getTimeSlots(){
    var ticks = ((this.currentDate.getTime() * 10000) + 621355968000000000);
    this.uiService.loadingStart();
    this.timeslots = null;
    (await this.timeslotService.getTimeSlotsForCompanyByDate(ticks)).subscribe(
      data => {
        this.uiService.loadingComplete();
        if(this.timeslotService.success === false){
          this.toastr.error(this.timeslotService.message, "");
        } else{
          this.timeslots = [];
          this.timeslots = data;
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

  onPrevious(){
    this.currentDate.setDate(this.currentDate.getDate()-1);
    this.getTimeSlots();
  }

  onNext(){
    this.currentDate.setDate(this.currentDate.getDate()+1);
    this.getTimeSlots();
  }
  
  onAssignedCheckboxChanged(timeslotId: string){
    const index = this.selectedTimeslotIds.indexOf(timeslotId, 0);
    if (index > -1) {
      this.selectedTimeslotIds.splice(index, 1);
    } else{
      this.selectedTimeslotIds.push(timeslotId);
    }
  }

  onClose(){
    this.dialogRef.close();
  }

  onSubmit() {
    // Save choosen time and user

    
    this.selectedTimeslotIds.forEach(timeslotId => {
      var timeslot = this.timeslots.find(e => e.id === timeslotId);
      this.selectedTimeslots.push(timeslot);
    });
    this.dialogRef.close();
  }

}
