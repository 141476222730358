import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieConsentStateQuery } from '../cookie-consent/cookie-consent.query';
import { TaskListStateStore } from './task-list.store';

@Injectable({
  providedIn: 'root'
})
export class TaskListStateService {

  constructor(
    private http: HttpClient,
    private taskListStateStore: TaskListStateStore,
    private cookieConsentQuery: CookieConsentStateQuery
  ) { }

  async updateIsAwaitingChecked(isAwaitingChecked: boolean) {
    if (this.cookieConsentQuery.isFunctionalCookiesAllowed()) {
      this.taskListStateStore.update({
        ...this.taskListStateStore.getValue(),
        isAwaitingChecked: isAwaitingChecked
      });
    }
  }

  async updateIsOngoingChecked(isOngoingChecked: boolean) {
    if (this.cookieConsentQuery.isFunctionalCookiesAllowed()) {
      this.taskListStateStore.update({
        ...this.taskListStateStore.getValue(),
        isOngoingChecked: isOngoingChecked
      });
    }
  }

  async updateIsCompletedChecked(isCompletedChecked: boolean) {
    if (this.cookieConsentQuery.isFunctionalCookiesAllowed()) {
      this.taskListStateStore.update({
        ...this.taskListStateStore.getValue(),
        isCompletedChecked: isCompletedChecked
      });
    }
  }

  async updateSelectedTaskGroup(selectedTaskGroup: string) {
    if (this.cookieConsentQuery.isFunctionalCookiesAllowed()) {
      this.taskListStateStore.update({
        ...this.taskListStateStore.getValue(),
        selectedTaskGroup: selectedTaskGroup
      });
    }
  }

  async updateStartDate(startDate: Date){
    if (this.cookieConsentQuery.isFunctionalCookiesAllowed()) {
      this.taskListStateStore.update({
        ...this.taskListStateStore.getValue(),
        startDate: startDate
      });
    }
  }

  async updateEndDate(endDate: Date){
    if (this.cookieConsentQuery.isFunctionalCookiesAllowed()) {
      this.taskListStateStore.update({
        ...this.taskListStateStore.getValue(),
        endDate: endDate
      });
    }
  }

  async reset() {
    this.taskListStateStore.reset();
  }
}
