import { Component, OnInit } from '@angular/core';
import { TaskState } from 'src/app/models/taskState.model';
import { Tasktype } from 'src/app/models/tasktype.model';
import { Router } from '@angular/router';
import { TaskStateService } from 'src/app/_services/taskstate.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { UiService } from 'src/app/_services/ui.service';
import { ApplicationStore } from 'src/app/_helpers/applicationStore';
import { TaskstateConfigurationStateService } from 'src/app/state/taskstate-configuration/taskstate-configuration.service';
declare var $ : any;

@Component({
  selector: 'app-taskstate-list',
  templateUrl: './taskstate-list.component.html',
  styleUrls: ['./taskstate-list.component.css']
})
export class TaskstateListComponent implements OnInit {
  loading = false;
  taskstateId: number;
  taskStates: TaskState[] = [];

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private taskstateService: TaskStateService,
    private uiService: UiService,
    private applicationStore: ApplicationStore,
    private taskstateConfigurationStateService: TaskstateConfigurationStateService
  ) { }

  ngOnInit() {
    this.loadTaskStates();
  }

  onCreate(){
    this.router.navigate(['/taskstate-create/']); 
  }

  async onConfiguration(taskstateId : number){
    this.taskstateConfigurationStateService.updateSelectedTaskStateId(taskstateId);
    //this.applicationStore.setSelectedTaskStateId(taskstateId.toString());
    this.router.navigate(['/taskstate-configuration/']);
  }

  onEdit(taskstateId : number){
    this.router.navigate(['/taskstate-update/', taskstateId]); 
  }

  onPromptDelete(taskstateId : number){
    this.taskstateId = taskstateId;
    $('#deleteTaskStateModal').modal('show');
  }

  async onDelete(){
    $('#deleteTaskStateModal').modal('hide');
    // Delete
    (await this.taskstateService.deleteTaskState(this.taskstateId))
      .pipe(first())
      .subscribe(() => {
        this.loadTaskStates();
        this.toastr.success('Deleted successfully', 'Task State');
      });
  }

  async loadTaskStates(){
    (await this.taskstateService.getAll()).subscribe(
      data => {
        if(this.taskstateService.success === false){
          this.toastr.error(this.taskstateService.message, "");
        } else{
          this.taskStates = data;
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

}
