import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { TaskstateConfigurationState } from './taskstate-configuration.model';

export interface ITaskstateConfigurationState extends TaskstateConfigurationState{}

export function createInitialState(): ITaskstateConfigurationState {
  return {
    selectedTaskStateId: 0
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'taskstateConfigurationState', resettable: true })
export class TaskstateConfigurationStateStore extends Store<ITaskstateConfigurationState> {
  constructor() {
    super(createInitialState());
  }
}