import { ICustomer } from '../interfaces/customer.interface';
import { ILocation } from '../interfaces/location.interface';

export class Customer implements ICustomer{
    id: number = 0;    
    companyId: number = 0;
    name: string = "";
    description: string = "";
    phoneNumber: string = "";
    email: string = "";
    url: string = "";
    createdBy: number = 0;
    createdAt: string = "";
    createdAtUTC: string = "";
    location: ILocation = null;
}