import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-third-parties',
  templateUrl: './privacy-policy-third-parties.component.html',
  styles: []
})
export class PrivacyPolicyThirdPartiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
