import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationStateQuery } from '../state/authentication/authentication.query';
import { AuthenticationService } from './authentication.service';
import { IpaddressService } from './ipaddress.service';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {
  
  constructor(
    private http: HttpClient, 
    private authService: AuthenticationService,
    private authQuery: AuthenticationStateQuery 
  ) { }

  async sendConsent(request: any) {

    if (this.authQuery.isLoggedIn()) {
      return this.http.post<any>(environment.baseUrl + "/Session/CreateCookieConsentAuthorized", request)
      .pipe(map(data => {
        return data;
      }));
    } 
    
    return this.http.post<any>(environment.baseUrl + "/Session/CreateCookieConsent", request)
      .pipe(map(data => {
        return data;
      }));
  }

  async sendConsentAuthenticated(request: any) {
    await this.authService.isExpired();

    return this.http.post<any>(environment.baseUrl + "/Session/CreateCookieConsent", request)
      .pipe(map(data => {
        return data;
      }));
  }

  async getCookieConsent(externalKey: string) {
    
    return this.http.get<any>(environment.baseUrl + "/Session/GetCookieConsent?externalKey=" + externalKey)
      .pipe(map(data => {
        return data;
      }));
  }
}
