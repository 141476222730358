import { IUser } from '../interfaces/user.interface';
import { WorkDay } from './workday.model';

export class UserCreateFromInviteRequest implements IUser {
    externalKey: string = '';
    firstName: string = "";
    middlenames: string = "";
    lastname: string = "";
    mobilenumber: string = "";
    worknumber: string = "";
    password: string = "";

    workdays: WorkDay[];
    
    mondayEnabled: boolean = false;
    startTimeMonday: number = 0;
    startTimeHourMonday: number;
    startTimeMinuteMonday: number;
    startTimeFormattedMonday: string = "";
    endTimeMonday: number = 0;
    endTimeHourMonday: number;
    endTimeMinuteMonday: number;
    endTimeFormattedMonday: string = "";

    startTimeHourFormattedMonday: string;
    startTimeMinuteFormattedMonday: string;
    endTimeHourFormattedMonday: string;
    endTimeMinuteFormattedMonday: string;
    
    tuesdayEnabled: boolean = false;
    startTimeTuesday: number = 0;
    startTimeHourTuesday: number;
    startTimeMinuteTuesday: number;
    startTimeFormattedTuesday: string = "";
    endTimeTuesday: number = 0;
    endTimeHourTuesday: number;
    endTimeMinuteTuesday: number;
    endTimeFormattedTuesday: string = "";

    startTimeHourFormattedTuesday: string;
    startTimeMinuteFormattedTuesday: string;
    endTimeHourFormattedTuesday: string;
    endTimeMinuteFormattedTuesday: string;
    
    wednesdayEnabled: boolean = false;
    startTimeWednesday: number = 0;
    startTimeHourWednesday: number;
    startTimeMinuteWednesday: number;
    startTimeFormattedWednesday: string = "";
    endTimeWednesday: number = 0;
    endTimeHourWednesday: number;
    endTimeMinuteWednesday: number;
    endTimeFormattedWednesday: string = "";
    
    startTimeHourFormattedWednesday: string;
    startTimeMinuteFormattedWednesday: string;
    endTimeHourFormattedWednesday: string;
    endTimeMinuteFormattedWednesday: string;
    
    thursdayEnabled: boolean = false;
    startTimeHourThursday: number = 0;
    startTimeMinuteThursday: number = 0;
    startTimeThursday: number = 0;
    startTimeFormattedThursday: string = "";
    endTimeThursday: number = 0;
    endTimeHourThursday: number = 0;
    endTimeMinuteThursday: number = 0;
    endTimeFormattedThursday: string = "";

    startTimeHourFormattedThursday: string;
    startTimeMinuteFormattedThursday: string;
    endTimeHourFormattedThursday: string;
    endTimeMinuteFormattedThursday: string;
    
    fridayEnabled: boolean = false;
    startTimeFriday: number = 0;
    startTimeHourFriday: number;
    startTimeMinuteFriday: number;
    startTimeFormattedFriday: string = "";
    endTimeFriday: number = 0;
    endTimeHourFriday: number;
    endTimeMinuteFriday: number;
    endTimeFormattedFriday: string = "";

    startTimeHourFormattedFriday: string;
    startTimeMinuteFormattedFriday: string;
    endTimeHourFormattedFriday: string;
    endTimeMinuteFormattedFriday: string;
    
    saturdayEnabled: boolean = false;
    startTimeSaturday: number = 0;
    startTimeHourSaturday: number;
    startTimeMinuteSaturday: number;
    startTimeFormattedSaturday: string = "";
    endTimeSaturday: number = 0;
    endTimeHourSaturday: number;
    endTimeMinuteSaturday: number;
    endTimeFormattedSaturday: string = "";

    startTimeHourFormattedSaturday: string;
    startTimeMinuteFormattedSaturday: string;
    endTimeHourFormattedSaturday: string;
    endTimeMinuteFormattedSaturday: string;
    
    sundayEnabled: boolean = false;
    startTimeSunday: number = 0;
    startTimeHourSunday: number;
    startTimeMinuteSunday: number;
    startTimeFormattedSunday: string = "";
    endTimeSunday: number = 0;
    endTimeHourSunday: number;
    endTimeMinuteSunday: number;
    endTimeFormattedSunday: string = "";

    startTimeHourFormattedSunday: string;
    startTimeMinuteFormattedSunday: string;
    endTimeHourFormattedSunday: string;
    endTimeMinuteFormattedSunday: string;
}