import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TaskStateButton } from 'src/app/models/taskStateButton.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TaskStateService } from 'src/app/_services/taskstate.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-taskstatebutton-update',
  templateUrl: './taskstatebutton-update.component.html',
  styleUrls: ['./taskstatebutton-update.component.css']
})
export class TaskstatebuttonUpdateComponent implements OnInit {

  updateTaskStateButtonForm: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;

  taskStateButtons: TaskStateButton[] = [];
  selectedTaskStateButtons: TaskStateButton[] = [];
  
  taskStateButton: TaskStateButton;
  taskStateButtonId: number;
  taskStateId: number;

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private taskStateService: TaskStateService,
    private uiService: UiService
  ) { }

  ngOnInit() {

    this.updateTaskStateButtonForm = this.formBuilder.group({
      name: ['', Validators.required],
      stateAfterwards: ['', Validators.required],
      statetype: ['', Validators.required],
      finishTask: ['', Validators.required],
      shownAtStart: ['', Validators.required],
      timetracking: ['', Validators.required],
      priority: ['', Validators.required]
    });

    this.taskStateButtonId = +this.activatedRouter.snapshot.paramMap.get('id');
    this.taskStateId = +this.activatedRouter.snapshot.paramMap.get('statetaskid');
    this.loadTaskStateButton();
  }

  get f() { return this.updateTaskStateButtonForm.controls; }

  async loadTaskStateButton(){
    
    (await this.taskStateService.getTaskStateButton(this.taskStateButtonId, this.taskStateId)).subscribe(
      data => {
        if(this.taskStateService.success === false){
          this.toastr.error(this.taskStateService.message, "");
        } else{
          // Init State Button Details
          this.updateTaskStateButtonForm.controls['name'].setValue(data.stateButton.name);
          this.updateTaskStateButtonForm.controls['stateAfterwards'].setValue(data.stateButton.stateAfterwards);
          this.updateTaskStateButtonForm.controls['statetype'].setValue(data.stateButton.statetypeId);
          this.updateTaskStateButtonForm.controls['finishTask'].setValue(data.stateButton.finishTask);
          this.updateTaskStateButtonForm.controls['shownAtStart'].setValue(data.stateButton.shownAtStart);
          this.updateTaskStateButtonForm.controls['timetracking'].setValue(data.stateButton.timetracking);
          this.updateTaskStateButtonForm.controls['priority'].setValue(data.stateButton.priority);
          
          data.stateButton.listenForEventOnList.forEach(button => {
            this.selectedTaskStateButtons.push(button);
          });

          // Init Selected State Buttons
          data.stateButtons.forEach(button => {
            this.taskStateButtons.push(button);
          });
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

  loadAllTaskStateButtons(){
    
    this.taskStateService.getSelectedTaskStatesButtons(this.taskStateId, this.taskStateButtonId).subscribe(
      taskStateButtons => {
        if(this.taskStateService.success === false){
          this.toastr.error(this.taskStateService.message, "");
        } else{
          taskStateButtons.forEach(button => {
            this.taskStateButtons.push(button);
          });
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

  drop(event: CdkDragDrop<TaskStateButton[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  onCancel($event){
    $event.preventDefault();
    this.router.navigate(["/taskstate-configuration/"]);
  }
  
  async onSubmit() {
    this.submitted = true;
    
    if(this.updateTaskStateButtonForm.valid) {
      
      var taskStateButton = new TaskStateButton();
      taskStateButton.name = this.updateTaskStateButtonForm.value.name;
      taskStateButton.stateAfterwards = this.updateTaskStateButtonForm.value.stateAfterwards;
      taskStateButton.statetypeId = this.updateTaskStateButtonForm.value.statetype;
      taskStateButton.finishTask = this.updateTaskStateButtonForm.value.finishTask;
      taskStateButton.shownAtStart = this.updateTaskStateButtonForm.value.shownAtStart;
      taskStateButton.timetracking = this.updateTaskStateButtonForm.value.timetracking;
      taskStateButton.priority = this.updateTaskStateButtonForm.value.priority;
      taskStateButton.taskStateId = this.taskStateId;
      taskStateButton.id = this.taskStateButtonId;
      this.selectedTaskStateButtons.forEach(taskStateBtn => {
        taskStateButton.shownAfterPressedBtns.push(taskStateBtn);
      });

      this.loading = true;

      (await this.taskStateService.updateTaskStateButton(taskStateButton)).subscribe(
        data => {
          this.loading = false;

          if(this.taskStateService.success === false){
            this.toastr.error(this.taskStateService.message, "");
          } else{
            this.updateTaskStateButtonForm.reset();
            this.router.navigate(["/taskstate-configuration/", this.taskStateId]);
            this.toastr.success(this.taskStateService.message, "");
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
      });
    }
  }
}
