import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { UiService } from 'src/app/_services/ui.service';
import { environment } from 'src/environments/environment';
import { CookieConsentStateQuery } from '../cookie-consent/cookie-consent.query';
import { AuthenticationStateStore } from './authentication.store';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationStateService {

  constructor(
    private http: HttpClient,
    private authenticationStateStore: AuthenticationStateStore,
    private cookieConsentQuery: CookieConsentStateQuery,
    private uiService: UiService,
    private authStateService: AuthenticationStateService
  ) { }

  async updateIsLoggedIn(isLoggedIn: boolean) {

    if (this.cookieConsentQuery.isCookieConsentAccepted()) {
      this.authenticationStateStore.update({
        ...this.authenticationStateStore.getValue(),
        isLoggedIn: isLoggedIn
      });
    }
  }

  async updateRefreshToken(refreshToken: string) {

    if (this.cookieConsentQuery.isCookieConsentAccepted()) {
      this.authenticationStateStore.update({
        ...this.authenticationStateStore.getValue(),
        refreshToken: refreshToken
      });
    }
  }

  async updateJwtToken(jwtToken: string) {

    if (this.cookieConsentQuery.isCookieConsentAccepted()) {
      this.authenticationStateStore.update({
        ...this.authenticationStateStore.getValue(),
        jwtToken: jwtToken
      });
    }
  }

  async updateJwtTokenExpiry(jwtTokenExpiry: string) {

    if (this.cookieConsentQuery.isCookieConsentAccepted()) {
      this.authenticationStateStore.update({
        ...this.authenticationStateStore.getValue(),
        jwtTokenExpiry: jwtTokenExpiry
      });
    }
  }

  async updateRoles(roles: string[]) {

    if (this.cookieConsentQuery.isCookieConsentAccepted()) {
      this.authenticationStateStore.update({
        ...this.authenticationStateStore.getValue(),
        roles: roles
      });
    }
  }

  async updateRole(role: string) {

    if (this.cookieConsentQuery.isCookieConsentAccepted()) {
      this.authenticationStateStore.update({
        ...this.authenticationStateStore.getValue(),
        role: role
      });
    }
  }

  // login(email: string, password: string) {

  //   this.uiService.loadingStart();

  //   this.removeUsersData();

  //   return this.http.post<any>(environment.baseUrl + "/Session/Login", 
  //   {
  //     AppIdentifier: 'employeetasksadmin',
  //     Username: email,
  //     Password: password,
  //     Application: "2",
  //     DefaultToken: environment.defaultToken,
  //     Lang: "",
  //     Major: 0,
  //     Minor: 0,
  //     Patch: 0,
  //     Build: 0,
  //     DeviceId: 0,
  //     NotificationId: "0",
  //     DeviceType: "",
  //     Idiom: "",
  //     Manufacturer: this.getBrowser(),
  //     Name: "",
  //     Platform: "Web",
  //     VersionString: this.getBrowserVersion(),
  //     Model: ""
  //   })
  //     .pipe(map(data => {
  //       if (data.success === true) {
  //         this.authStateService.updateRoles(data.roles);
          
  //         this.rolesSubject.next(data.roles);
  //         this.roleSubject.next(data.roles[0]);
          
  //         this.authStateService.updateRoles(data.roles);
  //         this.authStateService.updateRole(data.roles[0]);
  //         this.authStateService.updateJwtToken(data.token);
  //         this.authStateService.updateJwtTokenExpiry(data.expiry);
  //         this.authStateService.updateRefreshToken(data.refreshToken);
  //         this.authStateService.updateIsLoggedIn(true);
  //         this.languageStateService.updateLocaleIdentifier(data.lcid);
          
  //         localStorage.setItem('firstDayOfWeek', data.firstDayOfWeek);
  //       }
  //       return data;
  //     }));
  // }

  // public removeUsersData() {
  //   localStorage.removeItem("gantt_chosenDate");

  //   //resetStores();
  //   this.taskListStateService.reset();
  //   this.languageStateService.reset();
  //   this.authenticationStateService.reset();

  //   localStorage.removeItem('refreshToken');
  //   localStorage.removeItem('firstDayOfWeek');
    
  //   this.rolesSubject.next(null);
  // }

  private getBrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document['documentMode'] == true)) {
      return 'IE';
    } else {
      return 'unknown';
    }
  }

  getBrowserVersion() {
    var userAgent = navigator.userAgent, tem, matchTest = 
    userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }

    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
    }

    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];

    if ((tem = userAgent.match(/version\/(\d+)/i)) != null){
      matchTest.splice(1, 1, tem[1]);
    }

    return matchTest.join(' ');
  }

  async reset() {
    this.authenticationStateStore.reset();
  }
}
