import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { UserRole } from '../enums/userRole.enum';
import { UiService } from './ui.service';
import { TaskListStateService } from '../state/task-list/task-list.service';
import { LanguageStateService } from '../state/language/language.service';
import { resetStores } from '@datorama/akita';
import { AuthenticationStateService } from '../state/authentication/authentication.service';
import { AuthenticationStateQuery } from '../state/authentication/authentication.query';
import { GanttStateService } from '../state/gantt/gantt.service';
import { TaskstateConfigurationStateService } from '../state/taskstate-configuration/taskstate-configuration.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  // private currentUserSubject: BehaviorSubject<any>;
  // public currentUser: Observable<any>;
  private rolesSubject: BehaviorSubject<any>;
  public roles: Observable<String[]>;
  private roleSubject: BehaviorSubject<any>;
  public role: Observable<String>;
  public isAdmin: Boolean;

  success: boolean;
  message: string;

  constructor(
    private http: HttpClient,
    private uiService: UiService,
    private taskListStateService: TaskListStateService,
    private languageStateService: LanguageStateService,
    private authenticationStateService: AuthenticationStateService,
    private ganttStateService: GanttStateService,
    private authQuery: AuthenticationStateQuery,
    private taskstateConfigurationStateService: TaskstateConfigurationStateService
  ) {
    //var test = localStorage.getItem('currentUser');
    //alert(JSON.stringify(test));

    //this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('currentUser'));
    //this.currentUser = this.currentUserSubject.asObservable();

    this.rolesSubject = new BehaviorSubject<any>(this.authQuery.getUsersRoles());
    this.roles = this.rolesSubject.asObservable();
    this.roleSubject = new BehaviorSubject<any>(this.authQuery.getUsersRole());
    this.role = this.roleSubject.asObservable();
  }
  /*
    public isAuthenticated(): boolean {
      const token = localStorage.getItem('token');
      // Check whether the token is expired and return
      // true or false
      return !this.jwtHelper.isTokenExpired(token);
    }
  */
  // public get currentUserValue() {
  //   console.log('get currentUserValue called: ' + this.currentUserSubject.value);
  //   return this.currentUserSubject.value;
  // }

  public getRole() {
    var role = this.authQuery.getUsersRole().toLowerCase();
    if (role == "admin")
      return UserRole.Admin;
    else
      return UserRole.User;
  }

  login(email: string, password: string) {

    this.uiService.loadingStart();

    var body = {
      AppIdentifier: 'employeetasksadmin',
      Username: email,
      Password: password,
      Application: "2",
      DefaultToken: environment.defaultToken,
      Lang: "",
      Major: 0,
      Minor: 0,
      Patch: 0,
      Build: 0,
      DeviceId: 0,
      NotificationId: "0",
      DeviceType: "",
      Idiom: "",
      Manufacturer: this.getBrowser(),
      Name: "",
      Platform: "Web",
      VersionString: this.getBrowserVersion(),
      Model: ""
    };

    this.removeUsersData();

    return this.http.post<any>(environment.baseUrl + "/Session/Login", body)
      .pipe(map(data => {
        if (data.success === true) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          //localStorage.setItem('qdid', data.qdid);

          this.authenticationStateService.updateRoles(data.roles);
          //localStorage.setItem('roles', data.roles);
          //localStorage.setItem('role', data.roles[0]);
          this.rolesSubject.next(data.roles);
          this.roleSubject.next(data.roles[0]);
          //localStorage.setItem('currentUser', data);
          //localStorage.setItem('refreshToken', data.refreshToken);
          //localStorage.setItem('sessionTokenDateTime', data.expiry);
          //localStorage.setItem('sessionExpiryTicks', data.expiryTicks);
          //localStorage.setItem('deviceId', data.deviceId);
          //localStorage.setItem('userId', data.userId);
          //localStorage.setItem('isAdmin', data.isAdmin);
          //this.isAdmin = data.isAdmin;

          this.authenticationStateService.updateRoles(data.roles);
          this.authenticationStateService.updateRole(data.roles[0]);
          this.authenticationStateService.updateJwtToken(data.token);
          this.authenticationStateService.updateJwtTokenExpiry(data.expiry);
          this.authenticationStateService.updateRefreshToken(data.refreshToken);
          this.authenticationStateService.updateIsLoggedIn(true);
          this.languageStateService.updateLocaleIdentifier(data.lcid);
          
          //this.languageStateService.updateFirstDayOfWeek(data.firstDayOfWeek);

          localStorage.setItem('firstDayOfWeek', data.firstDayOfWeek);
          //localStorage.setItem('defaultCountry', data.defaultCountryId);
          //localStorage.setItem('defaultTwoLetterCountryCode', data.defaultTwoLetterCountryCode);

          //this.currentUserSubject.next(data);
        }
        return data;
      }));
  }

  logout() {

    this.uiService.loadingStart();

    var body = {
      Token: this.authQuery.getJwtToken(),
      RefreshToken: this.authQuery.getRefreshToken()
    };

    // localStorage.removeItem('role');
    // localStorage.removeItem('roles');

    return this.http.post<any>(environment.baseUrl + "/Session/Logout", body)
      .pipe(map(data => {
        this.uiService.loadingComplete();
        this.removeUsersData();
        return data;
      }));
  }

  forgotPassword(email: string) {
    
    return this.http.post<any>(environment.baseUrl + "/Session/CreateResetTicket", 
    {
      Email: email
    })
      .pipe(map(data => {
        this.uiService.loadingComplete();
        return data;
      }));
  }

  validateResetTicket(token: string) {
    return this.http.post<any>(environment.baseUrl + "/Session/ValidateResetTicket", 
    {
      ResetTicket: token
    })
      .pipe(map(data => {
        this.uiService.loadingComplete();
        return data;
      }));
  }

  getUserInvite(externalKey: string) {
    return this.http.post<any>(environment.baseUrl + "/Session/GetUserInvite", 
    {
      ExternalKey: externalKey
    })
      .pipe(map(data => {
        this.uiService.loadingComplete();
        return data;
      }));
  }

  changePassword(password: string, resetTicket: string) {
    return this.http.post<any>(environment.baseUrl + "/Session/ChangePassword", 
    {
      ResetTicket: resetTicket,
      Password: password
    })
      .pipe(map(data => {
        this.uiService.loadingComplete();
        return data;
      }));
  }

  public removeUsersData() {
    //localStorage.removeItem("gantt_chosenDate");
    //localStorage.removeItem("assignedState");

    //resetStores();
    this.taskListStateService.reset();
    this.taskstateConfigurationStateService.reset();
    this.ganttStateService.reset();
    this.languageStateService.reset();
    this.authenticationStateService.reset();

    //localStorage.removeItem("searchKey");
    //localStorage.removeItem('currentUser');
    //localStorage.removeItem('token');
    //localStorage.removeItem('role');
    //localStorage.removeItem('roles');
    //localStorage.removeItem('refreshToken');
    //localStorage.removeItem('userId');
    //localStorage.removeItem('isAdmin');
    localStorage.removeItem('firstDayOfWeek');
    //localStorage.removeItem('defaultCountry');
    //localStorage.removeItem('defaultTwoLetterCountryCode');
    //this.currentUserSubject.next(null);
    this.rolesSubject.next(null);
  }

  async RefreshSessionTokenExpiryAsync() {
    // Refresh
    var body = {
      Token: this.authQuery.getJwtToken(),
      RefreshToken: this.authQuery.getRefreshToken()
    };

    return this.http.post<any>(environment.baseUrl + "/Session/RefreshToken", body)
      .pipe(map(data => {
        // remove user from local storage and set current user to null
        this.success = data.success;
        this.message = data.message;

        this.authenticationStateService.updateJwtToken(data.token);
        this.authenticationStateService.updateJwtTokenExpiry(data.expiry);
        //localStorage.setItem('sessionTokenDateTime', data.expiry);
        return this.success;
      }));
  }

  RefreshSessionTokenExpiry() {
    // Refresh

    var body = {
      Token: this.authQuery.getJwtToken(),
      RefreshToken: this.authQuery.getRefreshToken()
    };

    return this.http.post<any>(environment.baseUrl + "/Session/RefreshToken", body)
      .pipe(map(data => {
        // remove user from local storage and set current user to null
        this.success = data.success;
        this.message = data.message;

        this.authenticationStateService.updateJwtToken(data.token);
        this.authenticationStateService.updateJwtTokenExpiry(data.expiry);
        //localStorage.setItem('sessionTokenDateTime', data.expiry);
        return this.success;
      }));
  }

  roleMatch(allowedRoles): boolean {
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(this.authQuery.getJwtToken().split('.')[1]));
    var userRole = payLoad.role;
    allowedRoles.forEach(element => {
      if (userRole == element) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }

  public async isExpired() {

    return new Promise<Boolean>((resolve, reject) => {

      var isValid: Boolean = this.authQuery.isSessionExpired();

      if (isValid != true) {
        this.RefreshSessionTokenExpiry().subscribe(
          data => {
            resolve(true);
          },
          error => {
            resolve(true);
          });
      } else{
        resolve(true);
      }
    });
  }

  getBrowser() {

    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {

      return 'Opera';

    } else if (navigator.userAgent.indexOf("Chrome") != -1) {

      return 'Chrome';

    } else if (navigator.userAgent.indexOf("Safari") != -1) {

      return 'Safari';

    } else if (navigator.userAgent.indexOf("Firefox") != -1) {

      return 'Firefox';

    } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document['documentMode'] == true)) {

      return 'IE';

    } else {

      return 'unknown';

    }

  }

  getBrowserVersion() {

    var userAgent = navigator.userAgent, tem,

      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {

      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];

      return 'IE ' + (tem[1] || '');

    }

    if (matchTest[1] === 'Chrome') {

      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);

      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');

    }

    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];

    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);

    return matchTest.join(' ');

  }

}
