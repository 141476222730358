import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { GanttState } from './gantt.model';
import * as moment from 'moment';

export interface IGanttState extends GanttState{}

export function createInitialState(): IGanttState {
  return {
    selectedDate: moment().toDate()
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ganttState', resettable: true })
export class GanttStateStore extends Store<IGanttState> {
  constructor() {
    super(createInitialState());
  }
}