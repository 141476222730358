export enum AssignmentFilter {
    All = 0,
    AllNotDeleted = 1,
    AllNotClosed = 2,
    Created = 3,
    Started = 4,
    Closed = 5,
    Deleted = 6,
    AllNotStarted = 7,
    AllOngoing = 8,
    Unassigned = 9
}