import { ILocation } from '../interfaces/location.interface';
import { IUser } from '../interfaces/user.interface';
import { User } from './user.model';

export class TaskEditRequest {
    taskId: number;
    customerId: number;
    typeId: number;
    name: string;
    description: string;
    assignedTo: User[];
    startYear: number;
    startMonth: number;
    startDay: number;
    startHour: number;
    startMinute: number;
    endYear: number;
    endMonth: number;
    endDay: number;
    endHour: number;
    endMinute: number;
    useCustomerLocation: boolean;
    location: ILocation;
}