import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef  } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AttachmentService } from 'src/app/_services/attachment.service';
import { MaterialFileInputModule, FileValidator, FileInput } from 'ngx-material-file-input';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-attachment-create',
  templateUrl: './attachment-create.component.html',
  styleUrls: ['./attachment-create.component.css']
})
export class AttachmentCreateComponent implements OnInit {
  attachmentCreateForm: FormGroup;
  @ViewChild('fileInput', {static: false}) fileInput:any;
  readonly maxSize = 104857600;
  fileinput: FileInput;

  constructor(
    private formBuilder: FormBuilder,
    private attachmentService: AttachmentService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<AttachmentCreateComponent>,
    private uiService: UiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) 
  { }

  ngOnInit() {
    this.attachmentCreateForm = this.formBuilder.group({
      basicfile: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      description: new FormControl('')
    });
  }

  onCancel($event){
    $event.preventDefault();
    this.attachmentCreateForm.reset();
    this.dialogRef.close();
  }

  onClose(){
    this.attachmentCreateForm.reset();
    this.dialogRef.close();
  }

  async onSubmit() {
    if(this.attachmentCreateForm.valid) {
      this.fileinput = this.attachmentCreateForm.value.basicfile;
      // let nativeElement: HTMLInputElement = this.fileInput.nativeElement;
      // this.attachmentService.upload(nativeElement.files[0], this.data.taskId, this.attachmentCreateForm.value.title, this.attachmentCreateForm.value.description).subscribe(

      (await this.attachmentService.upload(this.fileinput.files[0], this.data.taskId, this.attachmentCreateForm.value.title, 
        this.attachmentCreateForm.value.description)).subscribe(
          data => {
            if(this.attachmentService.success === false){
              this.toastr.error(this.attachmentService.message, "");
            } else{
              this.attachmentCreateForm.reset();
              this.toastr.success(this.attachmentService.message, "");
              this.dialogRef.close();
            }
          },
          error => {
            this.uiService.loadingCompleteWithError();
      });
    }
  }
}
