import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ChildActivationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter, first, take } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services';
import { UiService } from 'src/app/_services/ui.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styles: []
})
export class ResetPasswordComponent implements OnInit {
  public validation = false;
  public validationText: string;
  loading = false;
  submitted = false;
  changePasswordForm: FormGroup;
  token: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private uiService: UiService) { }

  async ngOnInit() {

    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    this.token = await this.activatedRoute.snapshot.queryParamMap.get("resetTicket");
    
    this.uiService.loadingStart();
      this.authenticationService.validateResetTicket(this.token)
        .pipe(first())
        .subscribe(
          data => {
            this.uiService.loadingComplete();
            if (data.success === false) {
              this.validation = false;
              this.validationText = data.message;
            }
            else {
              this.validation = true;
            }
          },
          error => {
            this.uiService.loadingCompleteWithError();
          });

  }

  get f() { return this.changePasswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.uiService.loadingStart();
    this.loading = true;
    this.authenticationService.changePassword(this.f.password.value, this.token)
      .pipe(first())
      .subscribe(
        data => {
          this.uiService.loadingComplete();
          if (data.success === false) {
            this.toastr.error(data.message, "");
          }
          else {
            this.router.navigate(['/login']);
          }
          this.loading = false;
        },
        error => {
          this.uiService.loadingCompleteWithError();
          this.loading = false;
        });
  }
}
