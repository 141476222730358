import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import { AuthenticationStateQuery } from '../state/authentication/authentication.query';

@Injectable()
export class AuthInteceptor implements HttpInterceptor{

    constructor(
        private router: Router,
        private service: AuthenticationService,
        private authQuery: AuthenticationStateQuery
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{

        // if(req.url === 'http://api.ipify.org/?format=json'){
        //     return next.handle(req.clone());
        // }

        if(this.authQuery.isLoggedIn()){
            console.info(req.url);
            const clonedReq = req.clone({
                headers: req.headers.set('Authorization','Bearer ' + this.authQuery.getJwtToken())
            });
            return next.handle(clonedReq).pipe(
                tap(
                    succ => {
                        
                    },
                    err => {
                        if(err.status == 401){
                            this.service.removeUsersData();
                            this.router.navigateByUrl('/login');
                        }
                        else if(err.status == 403){
                            this.service.removeUsersData();
                            this.router.navigateByUrl('/forbidden');
                        }
                    }
                )
            )
        }

        return next.handle(req.clone());

        /*
        if(req.url.endsWith("RefreshToken") || req.url.endsWith("Logout")){
            return this.routeToDestination(req,next);
        }

        var isExpired = this.service.CheckSessionTokenExpiry();
        if(isExpired != true)
        {
            alert("The sessiontoken is expired");
            this.service.RefreshSessionTokenExpiry().subscribe(
                data => {
                    if(this.service.success === true){
                      alert("Just refreshed the sessiontoken");
                      return this.routeToDestination(req,next);
                    } else{
                        alert("RefresheToken is expired, please login again.");
                        localStorage.removeItem('token');
                        this.router.navigateByUrl('/login');
                    }
                  },
                  error => {
                      alert("error back to login");
                    this.router.navigateByUrl('/login');
            });
        }
        alert("after refresh");
        // send to login if isExpired
        */
/*
        if(localStorage.getItem('token') != null){
            const clonedReq = req.clone({
                headers: req.headers.set('Authorization','Bearer '+localStorage.getItem('token'))
            });
            return next.handle(clonedReq).pipe(
                tap(
                    succ => {},
                    err => {
                        if(err.status == 401){
                            localStorage.removeItem('token');
                            this.router.navigateByUrl('/login');
                        }
                        else if(err.status == 403)
                        {
                            this.router.navigateByUrl('/forbidden');
                        }
                    }
                )
            )
        }
        else
            return next.handle(req.clone());
            */
    }

    routeToDestination(req: HttpRequest<any>, next: HttpHandler){
        if(this.authQuery.isLoggedIn()){
            const clonedReq = req.clone({
                headers: req.headers.set('Authorization','Bearer '+this.authQuery.getJwtToken())
            });
            return next.handle(clonedReq).pipe(
                tap(
                    succ => {
                        
                    },
                    err => {
                        if(err.status == 401){
                            this.service.removeUsersData();
                            this.router.navigateByUrl('/login');
                        }
                        else if(err.status == 403)
                        {
                            this.service.removeUsersData();
                            this.router.navigateByUrl('/forbidden');
                        }
                    }
                )
            )
        }
        else
            return next.handle(req.clone());
    }

}