import { Component, OnInit, ViewChild, ElementRef, NgZone, ɵCompiler_compileModuleSync__POST_R3__, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskService, AlertService, UserService } from 'src/app/_services';
import { ToastrService } from 'ngx-toastr';
import { Task } from 'src/app/models/task.model';
import { Location } from 'src/app/models/location.model';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { TasktypeService } from 'src/app/_services/tasktype.service';
import { CustomerService } from 'src/app/_services/customer.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { CountryService } from 'src/app/_services/country.service';
/*
import { $ } from 'protractor';
*/
import * as moment from 'moment';
import { DateAdapter, MatDialogConfig, MatDialog } from '@angular/material';
import { User } from 'src/app/models/user.model';
import { AssignedUser } from 'src/app/models/assignedUser.model';
import { TasktypeComponent } from '../tasktype/tasktype.component';
import { TimeslotListComponent } from '../timeslot-list/timeslot-list.component';
import { UiService } from 'src/app/_services/ui.service';
import { HereService } from 'src/app/_services/here.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskEditRequest } from 'src/app/models/taskEditRequest.model';
import { LanguageStateQuery } from 'src/app/state/language/language.query';

@Component({
  selector: 'app-taskedit',
  templateUrl: './taskedit.component.html',
  styleUrls: ['./taskedit.component.css']
})
export class TaskeditComponent implements OnInit {
  @ViewChild('addressNotFoundModal', { static: false }) addressNotFoundModalContent: TemplateRef<any>;
  @ViewChild('confirmAddressModal', { static: false }) confirmAddressModalContent: TemplateRef<any>;
  @ViewChild('multipleAddressesFoundModal', { static: false }) multipleAddressesFoundModalContent: TemplateRef<any>;
  
  public loading: Boolean = false;
  edittaskForm: FormGroup;
  submitted = false;
  id: number;
  task: Task = new Task();
  taskRequest: TaskEditRequest;
  latitude: number;
  longitude: number;
  zoom:number;
  private geoCoder;
  tasktypes = [];
  customers = [];
  locationId: number;

  allUsers: User[] = [];
  assignedUsers: AssignedUser[] = [];
  users: AssignedUser[] = [];

  selectedUserIds = [];

  public chosenStartDate: Date;
  public chosenEndDate: Date;

  @ViewChild('searchedit', {static: false})
  public searchElementRef: ElementRef;

  autocomplete: any;

  public address: string;
  
  foundAddresses: any[];
  foundAddress: string;

  selectedAddress: any;

  addressSelectedFromList = false;

  private defaultLCID: string = 'en-GB';
  
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private alertService: AlertService,
    private activatedRouter: ActivatedRoute,
    private toastr: ToastrService,
    private taskService: TaskService,
    private tasktypeService: TasktypeService,
    private customerService: CustomerService,
    private userService: UserService,
    public countryService: CountryService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private here: HereService,
    private dateAdapter: DateAdapter<Date>,
    private router: Router,
    private modalService: NgbModal,
    private uiService: UiService,
    private languageStateQuery: LanguageStateQuery
  ) {
    this.dateAdapter.setLocale(this.languageStateQuery.getLocaleIdentifier());
   }

  ngOnInit() {
    this.id = +this.activatedRouter.snapshot.paramMap.get('id');

    this.edittaskForm = this.formBuilder.group({
      description: [''],
      type: ['', Validators.required],
      customer: ['', Validators.required],
      address: [''],
      startdate: [ '', Validators.required],
      enddate: [ '', Validators.required],
      starttime: [ '', Validators.required],
      endtime: [ '', Validators.required],
      useCustomerLocation: [false, Validators.required]
    });

    this.loadViewModel();
  }

  private async loadViewModel() {
    
    (await this.taskService.initEditTask(this.id)).subscribe(
      data => {
        if (data.success === false) {
          this.alertService.error(data.message);
        } else {
          // Init customers
          this.customers = data.customers;

          // Init TaskTypes
          this.tasktypes = data.assignmentTypes;

          // Init Task
          this.task = data.assignment;

          this.edittaskForm.controls['useCustomerLocation'].setValue(this.task.useCustomerLocation);

          if(this.task.useCustomerLocation){
            this.f.address.disable();
          }

          if(this.task.location != null){
            this.latitude = +this.task.location.latitude;
            this.longitude = +this.task.location.longitude;
            this.zoom = 12;
            this.edittaskForm.controls['address'].setValue(this.task.location.fullAddress);
            this.locationId = this.task.location.id;
          } else{
            this.locationId = 0;
          }
          
          var startHourIndex = +this.task.startAt.indexOf("T")+1;
          this.edittaskForm.controls['starttime'].setValue(this.task.startAt.substring(startHourIndex));

          var endHourIndex = +this.task.completeAt.indexOf("T")+1;
          this.edittaskForm.controls['endtime'].setValue(this.task.completeAt.substring(startHourIndex));

          var startDate = moment(this.task.startAt).toDate();
          this.edittaskForm.controls['startdate'].setValue(startDate);
          this.chosenStartDate = startDate;

          var endDate = moment(this.task.completeAt).toDate();
          this.edittaskForm.controls['enddate'].setValue(endDate);
          this.chosenEndDate = endDate;

          

          // Init Users
          this.allUsers = data.users;
          this.assignedUsers = this.task.assignedUserList;

          this.allUsers.forEach((user: User, index, arr) => {

            var assignedUser = new AssignedUser();
            assignedUser.id = user.id;
            assignedUser.firstName = user.firstName;
            //assignedUser.middleNames = user.middlenames;
            //assignedUser.lastName = user.lastname;
            assignedUser.assigned = false;

            if (this.assignedUsers.find(e => e.id === user.id && e.assigned === true)) {
              assignedUser.assigned = true;
              this.selectedUserIds.push(user.id);
            }

            this.users.push(assignedUser);
          });

        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
      });
  }

  get f() { return this.edittaskForm.controls; }

  private async loadAllTasktypes() {
    (await this.tasktypeService.getAll()).subscribe(
    data => {
        if(this.tasktypeService.success === false){
          this.alertService.error(this.tasktypeService.message);
        } else{
          this.tasktypes = data;
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

  private async loadAllCustomers() {
    (await this.customerService.getAll()).subscribe(
      data => {
        if(this.customerService.success === false){
          this.alertService.error(this.customerService.message);
        } else{
          this.customers = data;
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

  private async loadAllUsers() {
    (await this.userService.getAll()).subscribe(
      data => {
        if(this.userService.success === false){
          this.alertService.error(this.userService.message);
        } else{
          this.allUsers = data;
          this.assignedUsers = this.task.assignedUserList;

          this.allUsers.forEach((user: User, index, arr) => {

            var assignedUser = new AssignedUser();
            assignedUser.id = user.id;
            assignedUser.firstName = user.firstName;
            //assignedUser.middleNames = user.middlenames;
            //assignedUser.lastName = user.lastname;
            assignedUser.assigned = false;

            if (this.assignedUsers.find(e => e.id === user.id && e.assigned === true)) {
              assignedUser.assigned = true;
              this.selectedUserIds.push(user.id);
            }

            this.users.push(assignedUser);
          });
          
        }
      },
      error => {
        this.uiService.loadingCompleteWithError();
    });
  }

  setCustomersAddress(customerId: number) {
    var customer = this.customers.find(x => x.id == customerId);
    if (this.edittaskForm.value.useCustomerLocation) {
      this.task.location.streetName = customer.location.streetName;
      this.task.location.streetNumber = customer.location.streetNumber;
      this.task.location.regionName = customer.location.regionName;
      this.task.location.zipCode = customer.location.zipCode;
      this.task.location.cityName = customer.location.cityName;
      this.task.location.latitude = customer.location.latitude;
      this.task.location.longitude = customer.location.longitude;
      this.latitude = +this.task.location.latitude;
      this.longitude = +this.task.location.longitude;
      this.edittaskForm.controls.address.setValue(customer.location.fullAddress);
    }
  }

  clearCustomersAddress() {
    this.task.location.streetName = '';
    this.task.location.streetNumber = '';
    this.task.location.regionName = '';
    this.task.location.zipCode = '';
    this.task.location.cityName = '';
    this.task.location.latitude = '';
    this.task.location.longitude = '';
    this.task.location.country = '';
    //this.location.countryId = +this.defaultCountry;
    this.edittaskForm.value.address = "";
    this.edittaskForm.value.useCustomerLocation = false;
  }

  onCustomerChanged($event) {
    var customerId = $event.target.options[$event.target.options.selectedIndex].value;
    this.setCustomersAddress(customerId);
  }

  onUseCustomersAddressChanged() {
    var useCustomerLocation: boolean = this.edittaskForm.value.useCustomerLocation;
    
    this.edittaskForm.controls['useCustomerLocation'].setValue(!useCustomerLocation);
    this.task.useCustomerLocation = !useCustomerLocation;

    if (!useCustomerLocation) {
      this.setCustomersAddress(this.task.customerId);
      this.f.address.disable();
    } else {
      this.clearCustomersAddress();
      this.f.address.enable();
    }
  }

  onAssignedCheckboxChanged(userId){
    const index = this.selectedUserIds.indexOf(userId, 0);
    if (index > -1) {
      this.selectedUserIds.splice(index, 1);
    } else{
      this.selectedUserIds.push(userId);
    }
  }

  OnCreateTaskType($event){
    $event.preventDefault();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    this.dialog.open(TasktypeComponent, dialogConfig).afterClosed().subscribe(result => {
      this.loadAllTasktypes();
    });
  }

  OnPickTimeslot($event){
    $event.preventDefault();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    const dialogRef = this.dialog.open(TimeslotListComponent, dialogConfig);
    

    dialogRef.afterClosed().subscribe(result => {
      //alert(JSON.stringify(dialogRef.componentInstance.selectedTimeslots));
      
      dialogRef.componentInstance.selectedTimeslots.forEach((timeslot, index, arr) => {

        var startHourIndex = +timeslot.fromDate.indexOf("T")+1;
        this.edittaskForm.controls['startTimeHour'].setValue(timeslot.fromDate.substring(startHourIndex, startHourIndex+2));
        this.edittaskForm.controls['startTimeMinute'].setValue(timeslot.fromDate.substring(startHourIndex+3, startHourIndex+5));

        var endHourIndex = +timeslot.toDate.indexOf("T")+1;
        this.edittaskForm.controls['endTimeHour'].setValue(timeslot.toDate.substring(endHourIndex, endHourIndex+2));
        this.edittaskForm.controls['endTimeMinute'].setValue(timeslot.toDate.substring(endHourIndex+3, endHourIndex+5));

        var startDate = moment(timeslot.fromDate).toDate();
        this.edittaskForm.controls['startdate'].setValue(startDate);
        this.chosenStartDate = startDate;

        var endDate = moment(timeslot.toDate).toDate();
        this.edittaskForm.controls['enddate'].setValue(endDate);
        this.chosenEndDate = endDate;

        this.users.forEach((user, index, arr) => {
          if(user.id === timeslot.userId)
          {
              user.assigned = true;
              this.selectedUserIds.push(user.id);
          }
        });

      });
      
    });
  }

  async onSubmit() {
    
    this.submitted = true;
    if(this.edittaskForm.valid) {
      var taskreq = new TaskEditRequest();
      
      this.chosenStartDate.setHours(+this.edittaskForm.value.starttime.split(":")[0]);
      this.chosenStartDate.setMinutes(+this.edittaskForm.value.starttime.split(":")[1]);
      var chosenStartDateOffset = this.chosenStartDate.getTimezoneOffset();
      chosenStartDateOffset = Math.abs(chosenStartDateOffset / 60);
      this.chosenStartDate.setHours(this.chosenStartDate.getHours() + chosenStartDateOffset);
      //task.startAt = this.chosenStartDate.toJSON();

      this.chosenEndDate.setHours(+this.edittaskForm.value.endtime.split(":")[0]);
      this.chosenEndDate.setMinutes(+this.edittaskForm.value.endtime.split(":")[1]);
      var chosenEndDateOffset = this.chosenEndDate.getTimezoneOffset();
      chosenEndDateOffset = Math.abs(chosenEndDateOffset / 60);
      this.chosenEndDate.setHours(this.chosenEndDate.getHours() + chosenEndDateOffset);
      //task.completeAt = this.chosenEndDate.toJSON();

      taskreq.startYear = this.chosenStartDate.getUTCFullYear();
      taskreq.startMonth = this.chosenStartDate.getUTCMonth()+1;
      taskreq.startDay = this.chosenStartDate.getUTCDate();
      taskreq.startHour = this.chosenStartDate.getUTCHours();
      taskreq.startMinute = this.chosenStartDate.getUTCMinutes();
      taskreq.endYear = this.chosenEndDate.getUTCFullYear();
      taskreq.endMonth = this.chosenEndDate.getUTCMonth()+1;
      taskreq.endDay = this.chosenEndDate.getUTCDate();
      taskreq.endHour = this.chosenEndDate.getUTCHours();
      taskreq.endMinute = this.chosenEndDate.getUTCMinutes();

      taskreq.assignedTo = [];

      this.selectedUserIds.forEach(userId => {
        var user = new User();
        user.id = userId;
        taskreq.assignedTo.push(user);
      });

      if(this.address == "" || taskreq.location == null){
        taskreq.location = new Location();
      }

      if(this.task.location !== undefined){
        taskreq.location = this.task.location;
      }

      taskreq.customerId = this.task.customerId;
      taskreq.typeId = this.task.typeId;
      taskreq.useCustomerLocation = this.task.useCustomerLocation;
      taskreq.description = this.task.description;
      taskreq.name = "";
      taskreq.taskId = this.task.id;

      this.taskRequest = taskreq;

      if (this.edittaskForm.value.address === '') // No location 
      {
        this.clearCustomersAddress();
        this.save();
      }
      else if (this.task.location == null || this.edittaskForm.get('address').value !== this.task.location.fullAddress) // Location changed
      {
        // Address has changed

        const items: any = await this.here.getCustomerAddress(this.edittaskForm.value.address);

        if (items.length === 0)
          this.openAddressNotFoundModal();
        else if (items.length === 1)
          this.openConfirmAddressModal(items[0]);
        else
          this.openMultipleAddressesFoundModal(items);
      } else {
        this.save();
      }

    }
  }

  openAddressNotFoundModal() {
    this.modalService.open(this.addressNotFoundModalContent).result.then((result) => { }, (reason) => { });
  }

  openConfirmAddressModal(item: any) {
    this.selectedAddress = item;
    this.foundAddress = item.address.label;
    this.modalService.open(this.confirmAddressModalContent).result.then((result) => {
      this.setSelectedAddress();
    }, (reason) => { });
  }

  openMultipleAddressesFoundModal(items: any[]) {

    this.foundAddresses = [];
    items.forEach((item) => {
      this.foundAddresses.push(item);
      //map.addObject(new H.map.Marker(item.position));
    });

    this.modalService.open(this.multipleAddressesFoundModalContent).result.then((result) => {
      this.setSelectedAddress();
      this.addressSelectedFromList = false;
    }, (reason) => {
      this.addressSelectedFromList = false;
     });
  }

  private setSelectedAddress() {

    if(this.taskRequest.location === null)
      this.taskRequest.location = new Location();

    this.taskRequest.location.fullAddress = this.selectedAddress.address.label;
    this.taskRequest.location.cityName = this.selectedAddress.address.city;
    this.taskRequest.location.country = this.selectedAddress.address.countryName;
    this.taskRequest.location.countryId = this.selectedAddress.address.countryCode;
    this.taskRequest.location.latitude = this.selectedAddress.position.lat;
    this.taskRequest.location.longitude = this.selectedAddress.position.lng;
    this.taskRequest.location.regionName = this.selectedAddress.address.state;
    this.taskRequest.location.streetName = this.selectedAddress.address.street;
    this.taskRequest.location.streetNumber = this.selectedAddress.address.houseNumber;
    this.taskRequest.location.zipCode = this.selectedAddress.address.postalCode;
    // console.log("county: " + this.selectedAddress.address.county);
    // console.log("district: " + this.selectedAddress.address.district);
    this.save();
  }

  saveSeletedAddress(listIndex: number) {
    this.selectedAddress = this.foundAddresses[listIndex];
    this.addressSelectedFromList = true;
  }

  private async save(){
    this.loading = true;
      (await this.taskService.edit(this.taskRequest)).subscribe(
        data => {
          if(this.taskService.success === false){
            this.toastr.error(this.taskService.message, "");
          } else{
            this.router.navigate(["/tasklist/"]);
            this.toastr.success(this.taskService.message, "");
          }
          this.loading = false;
        },
        error => {
          this.uiService.loadingCompleteWithError();
          this.loading = false;
      });
  }
}
