import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { IUser } from '../interfaces/user.interface';
import { AuthenticationService } from './authentication.service';
import { UserLoginRequest } from '../models/userloginRequest.model';
import { User } from '../models/user.model';
import { UiService } from './ui.service';
import { Profile } from '../models/profile.model';
import { CreateUserInviteRequest } from '../models/createUserInviteRequest.model';
import { UserCreateFromInviteRequest } from '../models/userCreateFromInviteRequest.model';

@Injectable({ providedIn: 'root' })
export class UserService {

    private headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    constructor(
        private http: HttpClient,
        private authService: AuthenticationService,
        private uiService: UiService) { }

    success: boolean;
    message: string;

    async initUser(id: number) {

        this.uiService.loadingStart();
        await this.authService.isExpired();

        var body = {
            UserId: id
        };

        return this.http.post<any>(environment.baseUrl + "/User/InitUser", body)
            .pipe(map(data => {
                this.uiService.loadingComplete();
                return data;
            }));
    }

    async initProfile() {

        this.uiService.loadingStart();
        await this.authService.isExpired();

        return this.http.get<any>(environment.baseUrl + "/User/InitProfile")
            .pipe(map(data => {
                this.uiService.loadingComplete();
                return data;
            }));
    }

    async get(id: number) {

        this.uiService.loadingStart();
        await this.authService.isExpired();

        var body = {
            UserId: id
        };

        return this.http.post<any>(environment.baseUrl + "/User/GetUser", body)
            .pipe(map(data => {
                this.uiService.loadingComplete();
                return data;
            }));
    }

    async getAll() {

        this.uiService.loadingStart();
        await this.authService.isExpired();

        return this.http.get<any>(environment.baseUrl + "/User/GetUsersByCompany")
            .pipe(map(model => {
                this.uiService.loadingComplete();
                this.success = model.success;
                this.message = model.message;
                return model.users;
            }));
    }

    async createUserInvite(userInvite: CreateUserInviteRequest) {

        this.uiService.loadingStart();
        await this.authService.isExpired();

        return this.http.post<any>(environment.baseUrl + "/User/CreateUserInvite", userInvite)
            .pipe(map(data => {
                this.uiService.loadingComplete();
                return data;
            }));
    }

    async create(user: UserLoginRequest) {

        this.uiService.loadingStart();
        await this.authService.isExpired();

        return this.http.post<any>(environment.baseUrl + "/User/CreateUser", user)
            .pipe(map(data => {
                this.uiService.loadingComplete();
                return data;
            }));
    }

    async createFromInvite(user: UserCreateFromInviteRequest) {
        this.uiService.loadingStart();
        await this.authService.isExpired();

        return this.http.post<any>(environment.baseUrl + "/User/CreateUserFromInvite", user)
            .pipe(map(data => {
                this.uiService.loadingComplete();
                return data;
            }));
    }

    async edit(user: User) {

        this.uiService.loadingStart();
        await this.authService.isExpired();

        return this.http.post<any>(environment.baseUrl + "/User/EditUser", user)
            .pipe(map(data => {
                this.uiService.loadingComplete();
                return data;
            }));
    }

    async editProfile(profile: Profile) {

        this.uiService.loadingStart();
        await this.authService.isExpired();

        return this.http.post<any>(environment.baseUrl + "/User/EditProfile", profile)
            .pipe(map(data => {
                this.uiService.loadingComplete();
                return data;
            }));
    }

    async delete(id: number) {

        this.uiService.loadingStart();
        await this.authService.isExpired();

        var body = {
            UserId: id
        };

        return this.http.post<any>(environment.baseUrl + "/User/DeleteUser", body)
            .pipe(map(data => {
                this.uiService.loadingComplete();
                return data;
            }));
    }

    async deleteCurrentUser() {

        this.uiService.loadingStart();
        await this.authService.isExpired();

        return this.http.delete<any>(environment.baseUrl + "/User/DeleteCurrentUser")
            .pipe(map(data => {
                this.uiService.loadingComplete();
                return data;
            }));
    }
}