import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UiService } from 'src/app/_services/ui.service';
import { SupportService } from 'src/app/_services';
import { SupportTicket } from 'src/app/models/supportTicket.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-supportticket',
  templateUrl: './supportticket.component.html',
  styleUrls: ['./supportticket.component.css']
})
export class SupportticketComponent implements OnInit {
  id: number;
  submitted: Boolean = false;
  supportTicket: SupportTicket;
  createSupportticketUpdateTaskForm: FormGroup;
  
  constructor(
    private activatedRouter: ActivatedRoute,
    private supportService: SupportService,
    private formBuilder: FormBuilder,
    private uiService: UiService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.id = +this.activatedRouter.snapshot.paramMap.get('id');

    this.createSupportticketUpdateTaskForm = this.formBuilder.group({
      description: ['']
    });

    this.initViewModel();
  }

  private async initViewModel(){
    (await this.supportService.getSupportTicket(this.id)).subscribe(
      data => {
        if(data.success === false){
          this.toastr.error(data.message, "");
        } 
        else{
            this.supportTicket = data.supportTicket;
          }
        },
        error => {
          this.uiService.loadingCompleteWithError();
      });
  }

  async onUpdate(){
    await this.update(false);
  }

  async onClose(){
    await this.update(true);
  }

  async update(close: boolean){
    if (this.createSupportticketUpdateTaskForm.valid) {
      this.submitted = true;
      var description: string = this.createSupportticketUpdateTaskForm.controls.description.value;
      (await this.supportService.supportTicketUpdate(this.id, description, close)).subscribe(
        data => {
          if (data.success === false) {
            this.toastr.error(data.message, "");
          } else {
            //this.toastr.success(data.message, "");
            this.initViewModel();
            this.createSupportticketUpdateTaskForm.reset();
          }
          this.submitted = false;
        },
        error => {
          this.uiService.loadingCompleteWithError();
          this.submitted = false;
        });
    }
  }

  async onSubmit() {

    /*
    if (this.createSupportticketUpdateTaskForm.valid) {
      this.submitted = true;
      var description: string = this.createSupportticketUpdateTaskForm.controls.description.value;
      (await this.supportService.supportTicketUpdate(this.id, description)).subscribe(
        data => {
          if (data.success === false) {
            this.toastr.error(data.message, "");
          } else {
            this.initViewModel();
            this.toastr.success(data.message, "");
            this.createSupportticketUpdateTaskForm.reset();
          }
          this.submitted = false;
        },
        error => {
          this.uiService.loadingCompleteWithError();
          this.submitted = false;
        });
    }
    */
  }
}
